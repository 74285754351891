import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  DateField,
  NumberField,
  ReferenceField,
  FunctionField,
  DateTimeInput,
  BooleanField,
} from 'react-admin'
import { ServiceList } from './components/ServiceList'
import { CustomPagination } from '../../components/CustomPagination/CustomPagination'
import { CarwashDatePostFilter } from '../../components/CarwashDatePostFilter/CarwashDatePostFilter'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../casl/Can'

const PaymentFilters = [
  <DateTimeInput label="начиная с" source="last_seen_gte" alwaysOn />,
  <DateTimeInput label="заканчивая по" source="last_seen_lte" alwaysOn />,
]

export const PaymentList = (props: any) => {
  const ability = useAbility(AbilityContext)
  return (
    <List
      {...props}
      title="Платежи"
      sort={{ field: 'dateTime', order: 'DESC' }}
      bulkActionButtons={false}
      aside={<CarwashDatePostFilter />}
      pagination={<CustomPagination />}
      filters={PaymentFilters}
      empty={false}
    >
      <Datagrid
        rowClick={ability.can('update', 'specialPayment') ? 'edit' : undefined}
      >
        <ReferenceField
          label="Мойка"
          source="carwashId"
          reference="places"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="postNumber" label="Номер поста" />
        <ServiceList source="" label="Услуга" />
        <NumberField source="paymentCard" label="Сумма платежа картой" />
        <NumberField source="paymentCash" label="Сумма платежа наличными" />
        <FunctionField
          label="Общая стоимость"
          source="price"
          textAlign="right"
          render={(record: any) => `${Math.round(record.price)}`}
        />
        <DateField source="dateTime" label="Дата" showTime={true} />
        {ability.can('read', 'specialPayment') && (
          <BooleanField source="special" label="Особый" />
        )}
      </Datagrid>
    </List>
  )
}
