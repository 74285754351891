import React from 'react'
import {
  Create,
  CreateProps,
  NumberInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
} from 'react-admin'

export const EquipmentServiceConfigCreate = (props: CreateProps) => (
  <Create {...props} title="Создать настройку моточасов">
    <SimpleForm>
      <ReferenceInput
        source="carwashId"
        reference="places"
        label="Мойка"
        validate={required()}
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <SelectInput
        source="postTitle"
        label="Пост"
        choices={[
          { id: 'Пост 0', name: 'Пост 0' },
          { id: 'Пост 1', name: 'Пост 1' },
          { id: 'Пост 2', name: 'Пост 2' },
          { id: 'Пост 3', name: 'Пост 3' },
          { id: 'Пост 4', name: 'Пост 4' },
          { id: 'Пост 5', name: 'Пост 5' },
          { id: 'Пост 6', name: 'Пост 6' },
          { id: 'Пост 7', name: 'Пост 7' },
          { id: 'Пост 8', name: 'Пост 8' },
        ]}
        validate={required()}
      />
      <SelectArrayInput
        source="deviceNames"
        label="Устройства"
        choices={[
          { id: 'АВД1', name: 'АВД1' },
          { id: 'АВД2', name: 'АВД2' },
        ]}
        validate={required()}
      />

      <NumberInput
        source="serviceInterval"
        label="Сервисный интервал (часы)"
        validate={required()}
      />
    </SimpleForm>
  </Create>
)
