import React from 'react'
import classes from './ReportFilter.module.sass'
import { DateTimeRange } from '../../../../corporateOffice/corpReport/components/DateTimeRange'
import { PlacesInterface } from '../../interfaces'
import { FormControl, InputLabel } from '@mui/material'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'

type Props = {
  currentCarwashList: string[]
  setCurrentCarwashList: (arg: string[]) => void
  places: PlacesInterface[]
  lastSeenGte: string
  setLastSeenGte: (arg: string) => void
  lastSeenLte: string
  setLastSeenLte: (arg: string) => void
  dateTimeError?: boolean
  onClickHandler: () => void
}

export const ReportFilter = ({
  currentCarwashList,
  setCurrentCarwashList,
  places,
  lastSeenGte,
  setLastSeenLte,
  lastSeenLte,
  setLastSeenGte,
  onClickHandler,
  dateTimeError = false,
}: Props) => {
  return (
    <div className={classes.CardContentWrapper}>
      <FormControl
        sx={{
          minWidth: '120px',
          marginRight: '10px',
          marginBottom: '20px',
        }}
        size="small"
      >
        <InputLabel id="outlined-select-organization">Мойка</InputLabel>
        <Select
          id="outlined-select-organization"
          label="Мойка"
          sx={{ width: '215px' }}
          value={currentCarwashList}
          multiple={true}
          onChange={(e) =>
            setCurrentCarwashList(
              typeof e.target.value === 'string'
                ? [e.target.value]
                : e.target.value
            )
          }
        >
          {places.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="outlined"
        color="error"
        sx={{ marginRight: '10px', marginBottom: '20px' }}
        onClick={() => setCurrentCarwashList([])}
      >
        x
      </Button>
      <DateTimeRange
        startDate={lastSeenGte}
        setStartDate={setLastSeenGte}
        endDate={lastSeenLte}
        setEndDate={setLastSeenLte}
        dateTimeError={dateTimeError}
      />
      <FormControl
        sx={{
          marginRight: '10px',
          marginBottom: '20px',
        }}
      >
        <Button
          variant="contained"
          onClick={onClickHandler}
          disabled={dateTimeError || currentCarwashList.length === 0}
        >
          Сформировать
        </Button>
      </FormControl>
    </div>
  )
}
