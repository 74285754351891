import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  ListProps,
} from 'react-admin'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../casl/Can'

import { UserRoleFilter } from './components/UserRoleFilter'
import { CustomPagination } from '../../components/CustomPagination/CustomPagination'

export const UserList = (props: ListProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <List
      {...props}
      title="Список пользователей"
      filters={<UserRoleFilter source="" />}
      bulkActionButtons={ability.can('delete', 'users') ? undefined : false}
      pagination={<CustomPagination />}
    >
      <Datagrid rowClick="show">
        <TextField source="phoneNumber" label="Номер телефона" />
        <TextField source="name" label="Имя" />
        <TextField source="lastName" label="Фамилия" />
        <TextField source="thirdName" label="Отчество" />
        <TextField source="birthDate" label="Дата рождения" />
        <DateField source="date" label="Дата регистрации" />
        {ability.can('update', 'users') && <EditButton />}
      </Datagrid>
    </List>
  )
}
