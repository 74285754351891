import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  Filter,
  TextInput,
  NumberField,
  InputProps,
  ListProps,
} from 'react-admin'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../casl/Can'

const PromoHistoryFilter = (props: InputProps) => (
  <Filter {...props}>
    <TextInput label="Название" source="name" alwaysOn />
  </Filter>
)

export const PromoHistoryList = (props: ListProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <List
      {...props}
      filters={<PromoHistoryFilter source="" />}
      bulkActionButtons={ability.can('delete', 'promoCode') ? undefined : false}
    >
      <Datagrid>
        <TextField source="name" label="Название" />
        <ReferenceField
          reference="promoCode"
          source="promoCodeId"
          label="Номинал"
          link={false}
        >
          <NumberField source="value" />
        </ReferenceField>
        <ReferenceField
          source="userId"
          reference="users"
          label="Пользователь"
          linkType="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField
          source="timestamp"
          showTime={true}
          label="Дата использования"
        />
      </Datagrid>
    </List>
  )
}
