import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import {
  getDeviceSum,
  getPostSum,
} from '../../../../Encashment/components/functions/getSum'
import React from 'react'
import { Encashments } from '../../interfaces'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Box from '@mui/material/Box'
import { Collapse } from '@mui/material'
import { EncashmentDetailsItem } from '../../../../Encashment/components/EncashmentDetailsItem'

type Props = {
  encashmentItem: Encashments
}

export const ReportTableRow = ({ encashmentItem }: Props) => {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {encashmentItem.postTitle} -{' '}
          {new Date(encashmentItem.dateTime).toLocaleTimeString()}
        </TableCell>
        {encashmentItem.devices.map((device, index) => (
          <TableCell key={(index * 175).toString() + device.deviceName}>
            {getDeviceSum(device.encashmentAmounts)}
          </TableCell>
        ))}
        <TableCell>{getPostSum(encashmentItem.devices)}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, border: 'unset' }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <TableRow>
                <TableCell style={{ border: 'unset' }}>
                  <EncashmentDetailsItem
                    postTitle={encashmentItem.postTitle}
                    devices={encashmentItem.devices}
                  />
                </TableCell>
              </TableRow>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
