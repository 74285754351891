import {
  List,
  Datagrid,
  TextField,
  ListProps,
  ReferenceArrayField,
  ChipField,
  SingleFieldList,
  NumberField,
  BooleanField,
  EditButton,
} from 'react-admin'
import React from 'react'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../casl/Can'

export const BotUserList = (props: ListProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <List
      {...props}
      title="Пользователи"
      bulkActionButtons={ability.can('delete', 'botusers') ? undefined : false}
    >
      <Datagrid rowClick="show">
        <TextField source="name" label="Имя" />
        <ReferenceArrayField label="Мойки" reference="places" source="carwash">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="description" label="Примечания" />
        <TextField source="key" label="Ключ" />
        <BooleanField source="notifications" label="Уведомления" />
        <BooleanField source="encashmentNotify" label="Инкассации" />
        <BooleanField source="equipmentNotify" label="Замена маста" />
        <BooleanField source="carwashApiNotify" label="Доступность api" />
        <BooleanField source="carwashDataNotify" label="Выгрузка данных" />
        <NumberField source="paymentLimit" label="Лимит платежей (день)" />
        {ability.can('update', 'botusers') && <EditButton />}
      </Datagrid>
    </List>
  )
}
