import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  ReferenceField,
  ListProps,
} from 'react-admin'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../casl/Can'

const promoRowStyle = (record: any) => {
  if (!record)
    return {
      borderLeft: '5px solid rgb(244, 67, 54)',
    }
  if (record.startDate > new Date().toISOString())
    return {
      borderLeft: '5px solid rgb(255, 152, 0)',
    }
  else if (
    record.startDate < new Date().toISOString() &&
    record.endDate > new Date().toISOString()
  )
    return {
      borderLeft: '5px solid rgb(76, 175, 80)',
    }
  else {
    return {
      borderLeft: '5px solid rgb(244, 67, 54)',
    }
  }
}

export const PromoList = (props: ListProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <List
      {...props}
      title="Список промокодов"
      sort={{ field: 'timestamp', order: 'DESC' }}
      bulkActionButtons={ability.can('delete', 'promoCode') ? undefined : false}
    >
      <Datagrid rowClick="edit" rowStyle={promoRowStyle}>
        <TextField source="name" label="Промокод" />
        <NumberField source="value" label="Номинал" />
        <DateField
          source="startDate"
          label="Дата начала действия"
          showTime={true}
        />
        <DateField
          source="endDate"
          label="Дата окончания действия"
          showTime={true}
        />
        <TextField source="description" label="Описание" />
        <ReferenceField
          source="adminId"
          reference="users"
          link="show"
          label="Создал"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField source="timestamp" label="Дата создания" showTime={true} />
      </Datagrid>
    </List>
  )
}
