import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import { SessionItem } from '../interfaces'
import HdrAutoIcon from '@mui/icons-material/HdrAuto'
import TrafficIcon from '@mui/icons-material/Traffic'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import { Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import SettingsIcon from '@mui/icons-material/Settings'
import Collapse from '@mui/material/Collapse'
import CachedIcon from '@mui/icons-material/Cached'
import classes from './DeviceListItem.module.sass'
import useDeviceListItemHook from './hooks/useDeviceListItemHook'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Chip from '@mui/material/Chip'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../../../casl/Can'

type Props = {
  deviceItem: SessionItem
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export const DeviceListItem = ({ deviceItem }: Props) => {
  const ability = useAbility(AbilityContext)

  const {
    sessionId,
    softwareVersion,
    currentDistance,
    threshold,
    distTimerDelay,
    trafficlightsDelay,
    deviceId,
    defaultSendStateDelay,
    isAuto,
    currentPinValue,
    defaultPinValue,
    postNumber,
    deviceName,
  } = deviceItem

  const {
    expanded,
    setExpanded,
    loading,
    switchMode,
    switchPin,
    reboot,
    formConfigData,
    setFormData,
    setConfig,
    resetToDefault,
    openSnackbar,
    handleCloseSnackbar,
    validateErrors,
    uptime,
  } = useDeviceListItemHook({
    deviceItem,
  })

  if (!sessionId) return null

  return (
    <Card
      style={{
        height: '100%',
        width: 325,
        marginBottom: '10px',
        marginRight: '10px',
        boxSizing: 'border-box',
      }}
    >
      <CardHeader
        action={
          loading ? (
            <CachedIcon color="warning" className={classes.Rotate} />
          ) : null
        }
        title={
          deviceName ? (
            <Typography variant="h6">{deviceName}</Typography>
          ) : postNumber ? (
            <Typography variant="h6">Пост - {postNumber}</Typography>
          ) : (
            <Typography variant="h6">Пост не назначен</Typography>
          )
        }
        subheader={
          <Chip label={`v ${softwareVersion}`} color="primary" size="small" />
        }
      />
      <CardContent>
        <Typography sx={{ mb: 0.5 }} color="text.secondary" variant="subtitle2">
          текущее расстояние: &nbsp;
          <Chip
            label={currentDistance}
            color={
              currentDistance && currentDistance < threshold
                ? 'error'
                : 'success'
            }
            size="small"
          />
        </Typography>
        <Typography sx={{ mb: 0.5 }} color="text.secondary" variant="subtitle2">
          расстояние срабатывания: &nbsp;
          <Chip label={threshold} color="success" size="small" />
        </Typography>
        <Typography sx={{ mb: 0.5 }} color="text.secondary" variant="subtitle2">
          задержка обнаружения: &nbsp;
          <Chip label={distTimerDelay} color="success" size="small" />
        </Typography>
        <Typography sx={{ mb: 0.5 }} color="text.secondary" variant="subtitle2">
          задержка переключения выхода: &nbsp;
          <Chip label={trafficlightsDelay} color="success" size="small" />
        </Typography>
        <Typography sx={{ mb: 0.5 }} color="text.secondary" variant="subtitle2">
          id устройства: &nbsp;
          <Chip label={deviceId} color="success" size="small" />
        </Typography>
        <Typography sx={{ mb: 0.5 }} color="text.secondary" variant="subtitle2">
          частота опроса: &nbsp;
          <Chip label={defaultSendStateDelay} color="success" size="small" />
        </Typography>
        <Typography sx={{ mb: 0.5 }} color="text.secondary" variant="subtitle2">
          uptime: {uptime}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Tooltip
          title={
            isAuto
              ? 'переключить в ручной режим'
              : 'переключить в автоматический режим'
          }
        >
          <IconButton
            aria-label="mode"
            onClick={switchMode}
            disabled={!ability.can('update', 'carCounter')}
          >
            <HdrAutoIcon color={isAuto ? 'success' : 'error'} />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={
            currentPinValue === defaultPinValue
              ? 'включить красный свет'
              : 'включить зеленый свет'
          }
        >
          <IconButton
            aria-label="trafficlights"
            onClick={switchPin}
            disabled={!ability.can('update', 'carCounter')}
          >
            <TrafficIcon
              color={currentPinValue === defaultPinValue ? 'success' : 'error'}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="перезагрузить">
          <IconButton
            aria-label="reboot"
            onClick={reboot}
            disabled={!ability.can('update', 'carCounter')}
          >
            <RestartAltIcon color="primary" />
          </IconButton>
        </Tooltip>
        <ExpandMore
          expand={expanded}
          onClick={() => setExpanded(!expanded)}
          aria-expanded={expanded}
          aria-label="show more"
          disabled={!ability.can('update', 'carCounter')}
        >
          <SettingsIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          component="form"
          sx={{
            '& > :not(style)': {
              m: 1,
              width: '95%',
              // boxSizing: 'border-box',
            },
          }}
          noValidate
          autoComplete="off"
        >
          <hr />
          <h3 style={{ marginTop: 20, marginBottom: 20 }}>
            Настройки устройства
          </h3>
          <TextField
            label="Адрес сервера"
            variant="outlined"
            name="apiUrl"
            value={formConfigData.apiUrl}
            onChange={(e) => setFormData(e)}
            error={!!validateErrors.apiUrl}
            helperText={validateErrors.apiUrl}
            required
          />
          <TextField
            label="Расстояние срабатывания (мм)"
            variant="outlined"
            type="number"
            name="threshold"
            value={formConfigData.threshold}
            onChange={(e) => setFormData(e)}
            error={!!validateErrors.threshold}
            helperText={validateErrors.threshold}
            required
          />
          <TextField
            label="Задержка обнаружения машины (мс)"
            variant="outlined"
            type="number"
            name="distTimerDelay"
            value={formConfigData.distTimerDelay}
            onChange={(e) => setFormData(e)}
            error={!!validateErrors.distTimerDelay}
            helperText={validateErrors.distTimerDelay}
            required
          />
          <TextField
            label="Нормально открытое сост. выхода"
            variant="outlined"
            type="number"
            name="defaultPinValue"
            value={formConfigData.defaultPinValue}
            onChange={(e) => setFormData(e)}
            error={!!validateErrors.defaultPinValue}
            helperText={validateErrors.defaultPinValue}
            required
          />
          <TextField
            label="Частота опроса (мс)"
            variant="outlined"
            type="number"
            name="defaultSendStateDelay"
            value={formConfigData.defaultSendStateDelay}
            onChange={(e) => setFormData(e)}
            error={!!validateErrors.defaultSendStateDelay}
            helperText={validateErrors.defaultSendStateDelay}
            required
          />
          <TextField
            label="Задержка переключения выхода (мс)"
            variant="outlined"
            type="number"
            name="trafficlightsDelay"
            value={formConfigData.trafficlightsDelay}
            onChange={(e) => setFormData(e)}
            error={!!validateErrors.trafficlightsDelay}
            helperText={validateErrors.trafficlightsDelay}
            required
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={setConfig}
          >
            Сохранить
          </Button>
          <Button
            variant="outlined"
            color="error"
            size="large"
            onClick={resetToDefault}
          >
            Сбросить до заводских
          </Button>
        </Box>
      </Collapse>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: '100%' }}
        >
          Запрос успешно отправлен!
        </Alert>
      </Snackbar>
    </Card>
  )
}
