import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  EditProps,
  Toolbar,
  SaveButton,
  DeleteButton,
  ToolbarProps,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../casl/Can'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const CustomToolbar = (props: ToolbarProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      {ability.can('delete', 'users') && <DeleteButton />}
    </Toolbar>
  )
}

export const UserEdit = (props: EditProps) => (
  <Edit {...props} title="Редактирование пользователя">
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput source="id" disabled={true} />
      <TextInput source="name" label="Имя" />
      <TextInput source="lastName" label="Фамилия" />
      <TextInput source="thirdName" label="Отчество" />
      <DateInput source="birthDate" label="Дата рождения" />
      <TextInput source="phoneNumber" label="Номер телефона" />
      <DateInput source="date" disabled={true} label="Дата регистрации" />
    </SimpleForm>
  </Edit>
)
