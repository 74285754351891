import React from 'react'
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  BooleanField,
} from 'react-admin'

import { PostField } from './components/PostField'
import { PlaceShowTitle } from './components/PlaceShowTitle'
import { AvailablePosts } from './AvailablePosts'
import CustomNumberField from './components/CustomNumberField'

export const PlaceShow = (props: any) => (
  <Show {...props} title={<PlaceShowTitle source="" />}>
    <TabbedShowLayout>
      <Tab label="Общая информация">
        <TextField source="name" label="Название" />
        <TextField source="address" label="Адрес" />
        <TextField source="phoneNumber" label="Номер телефона" />
        <TextField source="latitude" label="Координаты - широта" />
        <TextField source="longitude" label="Координаты - долгота" />
        <TextField source="operatorPhoneNumber" label="Номер оператора" />
        <CustomNumberField source="maxSpentTime" label="Макс. время на посту" />
        <BooleanField source="showInApp" label="Отображать в приложении" />
      </Tab>
      <Tab label="Api">
        <TextField source="id" />
        <TextField source="baseUrl" label="Api url" />
      </Tab>
      <Tab label="Посты">
        <PostField source="" />
      </Tab>
      <Tab label="Статус постов">
        <AvailablePosts source="" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)
