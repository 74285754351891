import React from 'react'
import {
  ListProps,
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
} from 'react-admin'
import { CarwashDateFilterSidebar } from '../../components/CarwashDateFilter/CarwashDateFilterSidebar'
import { EncashmentDetails } from './components/EncashmentDetails'
import { CarwashSumTextField } from './components/CarwashSumTextField'
import { CustomPagination } from '../../components/CustomPagination/CustomPagination'

export const EncashmentList = (props: ListProps) => (
  <List
    {...props}
    title="Инкассации"
    sort={{ field: 'item.datetime', order: 'DESC' }}
    bulkActionButtons={false}
    aside={<CarwashDateFilterSidebar />}
    pagination={<CustomPagination />}
  >
    <Datagrid rowClick="show" expand={<EncashmentDetails source="" />}>
      <ReferenceField
        source="carwashId"
        reference="places"
        link="show"
        label="Мойка"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="item.post_title" label="Пост" />
      <CarwashSumTextField source="" label="Сумма" />
      <DateField source="item.datetime" showTime={true} label="Дата" />
    </Datagrid>
  </List>
)
