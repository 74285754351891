import React from 'react'
import {
  Datagrid,
  TextField,
  ArrayField,
  NumberField,
  Show,
  SimpleShowLayout,
  ShowProps,
} from 'react-admin'

export const DiscountlevelShow = (props: ShowProps) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ArrayField source="levels" label="Уровни">
        <Datagrid>
          <TextField source="name" label="Название" />
          <NumberField source="visits" label="Кол-во посещений" />
          <NumberField source="discount" label="Значение скидки" />
          <TextField source="description" label="Описание" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
)
