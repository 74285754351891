import { makeStyles } from '@material-ui/core/styles'
import {
  BooleanInput,
  DeleteButton,
  Edit,
  NumberInput,
  SaveButton,
  TextInput,
  Toolbar,
  ToolbarProps,
  SimpleForm,
} from 'react-admin'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../../casl/Can'
import React from 'react'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const CustomToolbar = (props: ToolbarProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      {ability.can('delete', 'corpOrganizations') && <DeleteButton />}
    </Toolbar>
  )
}

export const CorpOrganizationsEdit = (props: any) => (
  <Edit {...props} title="Редактирование организации">
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput
        source="name"
        label="Название"
        format={(value: string) => (value ? value.trim() : '')}
      />
      <BooleanInput source="isEnabled" label="Активна" />
      <NumberInput source="creditLimit" label="Кредитный лимит" />
      <NumberInput source="balance" label="Баланс" disabled={true} />
    </SimpleForm>
  </Edit>
)
