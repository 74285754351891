import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import { CarwashCheckboxFormGroupProps } from '../../interfaces'

export const CarwashCheckboxFormGroup = ({
  places,
  checkedPlaces,
  onChangeHandler,
}: CarwashCheckboxFormGroupProps) => {
  return (
    <FormGroup row style={{ marginTop: 30 }}>
      {/*<div*/}
      {/*  style={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}*/}
      {/*>*/}
      {/*  выбрать/снять все*/}
      {/*</div>*/}
      {places.map((item, index) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={!!checkedPlaces.find((place) => place === item.id)}
              onChange={onChangeHandler}
              name={item.id}
              color="primary"
            />
          }
          label={item.name}
          key={item.name + index}
        />
      ))}
    </FormGroup>
  )
}
