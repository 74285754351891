import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}))

export const SaveButton = ({ onPressHandler }: any) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        onClick={onPressHandler ? onPressHandler : () => {}}
      >
        Сохранить
      </Button>
    </div>
  )
}
