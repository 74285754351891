import React from 'react'
import { Filter, InputProps, TextInput } from 'react-admin'

export const UserRoleFilter = (props: InputProps) => (
  <Filter {...props}>
    <TextInput
      label="Найти по номеру телефона"
      source="phoneNumber"
      alwaysOn
      resettable
    />
  </Filter>
)
