import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ArrayField,
  SingleFieldList,
  ChipField,
  EditButton,
  ListProps,
} from 'react-admin'

export const DiscountlevelList = (props: ListProps) => (
  <List {...props} title="Программа лояльности" bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ArrayField source="levels" label="Название уровней">
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <EditButton />
    </Datagrid>
  </List>
)
