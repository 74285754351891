import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ListProps,
  DateField,
  SelectField,
} from 'react-admin'
import { CarwashDateFilterSidebar } from '../../../components/CarwashDateFilter/CarwashDateFilterSidebar'
import { CustomPagination } from '../../../components/CustomPagination/CustomPagination'
import { TopDateTimeFilter } from '../../../components/TopDateTimeFilter/TopDateTimeFilter'

export const WhEventsAdminList = (props: ListProps) => (
  <List
    {...props}
    title="Моточасы - список событий"
    sort={{ field: 'item.dateTime', order: 'DESC' }}
    bulkActionButtons={false}
    aside={<CarwashDateFilterSidebar />}
    pagination={<CustomPagination />}
    filters={TopDateTimeFilter}
  >
    <Datagrid>
      <ReferenceField source="carwashId" reference="places" label="Мойка">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="item.postTitle" label="Пост" />
      <TextField source="item.deviceName" label="Название устройства" />
      <SelectField
        source="item.eventType.description"
        label="Тип события"
        choices={[
          { id: 1, name: 'включено' },
          { id: 2, name: 'выключено' },
          { id: 3, name: 'открыто' },
          { id: 4, name: 'закрыто' },
          { id: 5, name: 'авария' },
          { id: 6, name: 'событие' },
          { id: 7, name: 'сброс' },
        ]}
      />
      <TextField source="item.eventData" label="Дополнительно" />
      <DateField source="item.dateTime" showTime={true} label="Дата" />
    </Datagrid>
  </List>
)
