import { FinanceReportInterface, ReportData } from '../interfaces'

export const getTotalAmount = (
  data: ReportData[],
  paymentType: string,
  returnType = 'string'
): any => {
  switch (paymentType) {
    case 'cash':
      const totalCash = data.reduce((prev, item) => prev + item.amounts.cash, 0)
      return returnType === 'string'
        ? new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'RUB',
          }).format(totalCash)
        : totalCash
    case 'card':
      const totalCard = data.reduce((prev, item) => prev + item.amounts.card, 0)
      return returnType === 'string'
        ? new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'RUB',
          }).format(totalCard)
        : totalCard
    case 'app':
      const totalApp = data.reduce((prev, item) => prev + item.amounts.app, 0)
      return returnType === 'string'
        ? new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'RUB',
          }).format(totalApp)
        : totalApp
    default:
      return returnType === 'string' ? '0' : 0
  }
}

export const getSum = (
  report: FinanceReportInterface[],
  paymentType: string
): number => {
  if (paymentType === 'total') {
    return report.reduce((total, item) => total + item.carwashTotal, 0)
  }
  return report.reduce(
    (total, item) => total + getTotalAmount(item.data, paymentType, 'number'),
    0
  )
}
