import {
  SimpleForm,
  TextInput,
  CreateProps,
  Create,
  required,
  NumberInput,
  SelectInput,
  ReferenceInput,
  ArrayInput,
  SelectArrayInput,
  SimpleFormIterator,
  BooleanInput,
} from 'react-admin'
import React from 'react'

export const SpecialPaymentsConfigCreate = (props: CreateProps) => (
  <Create {...props} title="Создать настройку особого платежа">
    <SimpleForm>
      <ReferenceInput
        source="carwashId"
        reference="places"
        label="Мойка"
        validate={required()}
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <SelectArrayInput
        source="daysOfWeek"
        label="Дни недели"
        choices={[
          { id: 1, name: 'Понедельник' },
          { id: 2, name: 'Вторник' },
          { id: 3, name: 'Среда' },
          { id: 4, name: 'Четверг' },
          { id: 5, name: 'Пятница' },
          { id: 6, name: 'Суббота' },
          { id: 0, name: 'Воскресенье' },
        ]}
        validate={required()}
      />
      <SelectArrayInput
        source="paymentType"
        label="Тип платежа"
        choices={[
          { id: 1, name: 'Наличные' },
          { id: 2, name: 'Карта' },
          { id: 3, name: 'Приложение' },
        ]}
        validate={required()}
      />
      <ArrayInput
        source="timeInterval"
        validate={required()}
        label="Интревал времени"
      >
        <SimpleFormIterator>
          <TextInput
            source="start"
            id="time"
            label="Начиная с"
            type="time"
            defaultValue="00:00"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            sx={{ width: 150 }}
          />
          <TextInput
            source="end"
            id="time"
            label="Заканчивая по"
            type="time"
            defaultValue="23:59"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            sx={{ width: 150 }}
          />
        </SimpleFormIterator>
      </ArrayInput>
      <NumberInput
        source="threshold"
        label="Порог срабатывания"
        validate={required()}
      />
      <BooleanInput
        source="isEnabled"
        label="Правило активно"
        defaultValue={true}
      />
    </SimpleForm>
  </Create>
)
