import React from 'react'
import { Loading, Title, useAuthState } from 'react-admin'
import { Redirect } from 'react-router'
import { Card, CardContent, FormControl, InputLabel } from '@mui/material'
import Select from '@mui/material/Select'
import useCorpReportHook from './hooks/useCorpReportHook'
import { DaySummary } from './components/DaySummary'
import { DateTimeRange } from './components/DateTimeRange'
import MenuItem from '@mui/material/MenuItem'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

export const CorpReport = () => {
  const {
    corpPayments,
    lastSeenGte,
    setLastSeenLte,
    lastSeenLte,
    setLastSeenGte,
    corpUsers,
    setCurrentCorpUser,
    currentCorpUser,
    paymentType,
    setPaymentType,
    places,
    isShowOrganizationField,
    corpOrganizationList,
    setCurrentOrganization,
    currentOrganization,
  } = useCorpReportHook()
  /**
   * This hook is secure access to the page
   */
  const { loading, authenticated } = useAuthState()

  if (loading) {
    return <Loading />
  }
  if (authenticated) {
    return (
      <Card style={{ marginTop: '20px' }}>
        <Title title="Отчет по расходам" />
        <CardContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: '30px',
            }}
          >
            <DateTimeRange
              startDate={lastSeenGte}
              setStartDate={setLastSeenGte}
              endDate={lastSeenLte}
              setEndDate={setLastSeenLte}
            />
            <FormControl
              sx={{ minWidth: 120, marginRight: '10px', marginBottom: '20px' }}
              size="small"
            >
              <InputLabel id="outlined-select-currency">
                Пользователь
              </InputLabel>
              <Select
                id="outlined-select-currency"
                label="Пользователь"
                sx={{ width: '215px' }}
                value={currentCorpUser}
                multiple={true}
                onChange={(e) =>
                  typeof e.target.value === 'string'
                    ? setCurrentCorpUser([e.target.value])
                    : setCurrentCorpUser(e.target.value)
                }
              >
                {corpUsers.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              color="error"
              sx={{ marginRight: '10px', marginBottom: '20px' }}
              onClick={() => setCurrentCorpUser([])}
            >
              x
            </Button>
            {isShowOrganizationField() && (
              <FormControl
                sx={{
                  minWidth: 120,
                  marginRight: '10px',
                  marginBottom: '20px',
                }}
                size="small"
              >
                <InputLabel id="outlined-select-organization">
                  Организация
                </InputLabel>
                <Select
                  id="outlined-select-organization"
                  label="Организация"
                  sx={{ width: '215px' }}
                  value={currentOrganization}
                  onChange={(e) => setCurrentOrganization(e.target.value)}
                >
                  {corpOrganizationList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <ToggleButtonGroup
              color="primary"
              value={paymentType}
              exclusive
              sx={{ marginBottom: '20px' }}
              onChange={(e, value) => {
                setCurrentCorpUser([])
                setPaymentType(value)
              }}
            >
              <ToggleButton value="income" size="small">
                пополнения
              </ToggleButton>
              <ToggleButton value="debt" size="small">
                расходы
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          {Object.keys(corpPayments).length > 0 ? (
            <>
              <Typography
                variant="body1"
                gutterBottom
                component="div"
                style={{ fontWeight: 500 }}
              >
                Итого за выбранный период:{' '}
                {Object.entries(corpPayments).reduce(
                  (prev, [date, corpPaymentItem]) => {
                    return (
                      prev +
                      corpPaymentItem.reduce(
                        (prevCorp, item) => prevCorp + item.amount,
                        0
                      )
                    )
                  },
                  0
                )}
              </Typography>
              {Object.entries(corpPayments).map(([date, corpPaymentItem]) => (
                <DaySummary
                  date={date}
                  corpPayments={corpPaymentItem}
                  corpUsers={corpUsers}
                  places={places}
                  paymentType={paymentType}
                />
              ))}
            </>
          ) : (
            <Typography variant="body1" gutterBottom component="div">
              список пуст
            </Typography>
          )}
        </CardContent>
      </Card>
    )
  }

  return <Redirect to="/login" />
}
