import React from 'react'
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  ToolbarProps,
  Toolbar,
  SaveButton,
  DeleteButton,
  BooleanInput,
  required,
} from 'react-admin'

import { PlaceShowTitle } from './components/PlaceShowTitle'
import { makeStyles } from '@material-ui/core/styles'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../casl/Can'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const CustomToolbar = (props: ToolbarProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      {ability.can('delete', 'places') && <DeleteButton />}
    </Toolbar>
  )
}

export const PlaceEdit = (props: any) => (
  <Edit {...props} title={<PlaceShowTitle source="" />}>
    <TabbedForm toolbar={<CustomToolbar />}>
      <FormTab label="Общая информация">
        <TextInput source="name" label="Название" />
        <TextInput source="address" label="Адрес" />
        <NumberInput
          source="phoneNumber"
          label="Номер телефона"
          parse={(value: string) => (value ? value.trim() : '')}
        />
        <NumberInput source="latitude" label="Координаты - широта" />
        <NumberInput source="longitude" label="Координаты - долгота" />
        <TextInput
          source="operatorPhoneNumber"
          label="Номер оператора"
          parse={(value: string) => (value ? value.trim() : '')}
        />
        <NumberInput
          source="maxSpentTime"
          label="Макс. время на посту (мин)"
          format={(value: any) => (value ? value / 1000 / 60 : 0)}
          parse={(value: number) => value * 1000 * 60}
          validate={required()}
        />
        <BooleanInput source="showInApp" label="Отображать в приложении" />
      </FormTab>
      <FormTab label="Api">
        <TextInput source="id" />
        <TextInput
          source="baseUrl"
          label="Api url"
          parse={(value: string) => (value ? value.trim() : '')}
        />
      </FormTab>
      <FormTab label="Посты">
        <ArrayInput source="posts" label="Посты">
          <SimpleFormIterator>
            <TextInput
              source=""
              label="Id поста"
              parse={(value: string) => (value ? value.trim() : '')}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
)
