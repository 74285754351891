import React, { useState, useEffect, useCallback } from 'react'
import classes from './TechInfo.module.sass'
import { Title, useAuthState, Loading } from 'react-admin'
import { Redirect } from 'react-router'
import useInterval from '@use-it/interval'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import RefreshIcon from '@material-ui/icons/Refresh'

import { LiquidGauge } from '../../components/LiquidGauge/LiquidGauge'
import { apiUrl } from '../../helpers/config'
import { CustomCardSubHeader } from '../../components/CustomCardSubHeader/CustomCardSubHeader'
import { PressureGauge } from '../../components/PressureGauge/PressureGauge'
import { ThermometerGauge } from '../../components/ThermometerGauge/ThermometerGauge'
import axios from 'axios'

export const TechInfo = () => {
  const [loading, setLoading] = useState(true)
  const [owenSavedDevice, setOwenSavedDevice] = useState()
  const [deviceList, setDeviceList] = useState()
  const [currentValue, setCurrentValue] = useState([])
  const [carwashNames, setCarwashNames] = useState([])
  const [refresh, setRefresh] = useState(new Date().toLocaleTimeString())

  const getCurrentValue = useCallback(
    async (ids, accountId) => {
      try {
        const result = await axios.post(
          `${apiUrl}/admin/owen/getData`,
          {
            ids,
            accountId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('auth')}`,
            },
          }
        )
        const values = result.data.map((item: any) => ({
          id: item.id,
          value: parseInt(item.values[0].v),
          accountId,
        }))
        const state = currentValue
        values.forEach((item: any) => {
          // @ts-ignore
          const index = state.findIndex((i) => i.id === item.id)
          // @ts-ignore
          if (index >= 0) return state.splice(index, 1, item)
          // @ts-ignore
          state.push(item)
        })
        setCurrentValue(state)
      } catch (error) {
        console.log(error)
      }
    },
    [currentValue]
  )

  useEffect(() => {
    ;(async () => {
      const token = localStorage.getItem('auth')

      const result = await axios.get(`${apiUrl}/admin/owen/savedDevices`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      setOwenSavedDevice(result.data)
      await Promise.all(
        result.data.map(async (item: any) => {
          await getCurrentValue(item.params, item.owenAccountId)
        })
      )
      // @ts-ignore
      const owenAccounts = []
      const carwashNamesList = await Promise.all(
        result.data.map(async (item: any) => {
          // @ts-ignore
          if (!owenAccounts.includes(item.owenAccountId)) {
            const result = await axios.get(
              `${apiUrl}/admin/owen/accounts/${item.owenAccountId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            owenAccounts.push(item.owenAccountId)
            return {
              owenAccountId: item.owenAccountId,
              name: result.data.name,
            }
          }
        })
      )
      // @ts-ignore
      setCarwashNames(carwashNamesList)
      const list = await Promise.all(
        // @ts-ignore
        owenAccounts.map(async (item) => {
          const result = await axios.get(
            `${apiUrl}/admin/owen/deviceList/${item}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          return result.data
        })
      )
      // @ts-ignore
      setDeviceList(list)
      setLoading(false)
    })()
  }, [getCurrentValue])

  useInterval(async () => {
    // @ts-ignore
    if (owenSavedDevice.length > 0) {
      await Promise.all(
        // @ts-ignore
        owenSavedDevice.map(async (item: any) => {
          await getCurrentValue(item.params, item.owenAccountId)
        })
      )
      setRefresh(new Date().toLocaleTimeString())
    }
  }, 30000)

  const getCarwashName = (owenAccountId: any) => {
    const result = carwashNames.find(
      // @ts-ignore
      (item) => item.owenAccountId === owenAccountId
    )
    // @ts-ignore
    if (result) return result.name
    return ''
  }
  const isShow = () => {
    let show = false
    // @ts-ignore
    owenSavedDevice.forEach((item: any) => {
      if (item.params.length > 0) show = true
    })
    return show
  }
  const getParamValue = (id: any) => {
    // @ts-ignore
    const value = currentValue.filter((item) => item.id === id)
    // @ts-ignore
    return value.length > 0 ? value[0].value : 0
  }

  const { authenticated } = useAuthState()

  if (loading) return <Loading />
  if (authenticated)
    return (
      <>
        <Title title="Технический мониторинг" />
        {isShow() ? (
          // @ts-ignore
          owenSavedDevice.map((item: any) => (
            <div className={classes.TechInfo}>
              <Card className={classes.Card} key={item.deviceId}>
                <CardHeader
                  title={`Мойка - ${getCarwashName(item.owenAccountId)}`}
                  subheader={
                    <CustomCardSubHeader
                      title={`Обновлено - ${refresh}`}
                      Icon={RefreshIcon}
                    />
                  }
                />
                <CardContent className={classes.CardContent}>
                  {item.params.map((param: any) => {
                    // @ts-ignore
                    const device = deviceList.map((i) => {
                      return i.find(
                        // @ts-ignore
                        (currentDevice) =>
                          currentDevice.deviceId.toString() === item.deviceId
                      )
                    })
                    const currentType = device[0].params.find(
                      // @ts-ignore
                      (deviceParam) => deviceParam.id === param
                    )
                    if (currentType && currentType.type === 'Датчик уровня')
                      return (
                        <div className={classes.Cell}>
                          <LiquidGauge
                            title={currentType.name}
                            value={getParamValue(currentType.id)}
                            key={currentType.id}
                          />
                        </div>
                      )
                    else if (
                      currentType &&
                      currentType.type === 'Датчик давления'
                    )
                      return (
                        <div className={classes.Cell}>
                          <PressureGauge
                            currentValue={getParamValue(currentType.id)}
                            label={currentType.name}
                            // units="бар"
                            key={currentType.id}
                          />
                        </div>
                      )
                    else if (
                      currentType &&
                      currentType.type === 'Датчик температуры'
                    )
                      return (
                        <div className={classes.Cell}>
                          <ThermometerGauge
                            value={getParamValue(currentType.id)}
                            title={currentType.name}
                            key={currentType.id}
                          />
                        </div>
                      )
                    else return null
                  })}
                </CardContent>
              </Card>
            </div>
          ))
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
            }}
          >
            здесь пока ничего нет
          </div>
        )}
      </>
    )
  return null
  // return <Redirect to="/login" />
}
