import React from 'react'
import { SortedEncashmentAmount } from './interfaces'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { getDeviceSum, getPostSum } from './functions/getSum'
import { Loading, useAuthState } from 'react-admin'
import { Redirect } from 'react-router'

export const EncashmentDetailsItem = (props: SortedEncashmentAmount) => {
  /**
   * This hook is secure access to the page
   */
  const { loading, authenticated } = useAuthState()

  if (loading) {
    return <Loading />
  }

  if (!props) return null
  if (authenticated) {
    return (
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>{props.postTitle}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.devices.map((device, index) => (
              <div key={device.deviceName + index * 1234}>
                <TableRow>
                  <TableCell>{device.deviceName}</TableCell>
                </TableRow>
                <TableRow key={device.deviceName + Math.random()}>
                  <TableCell>
                    <Table
                      size="small"
                      aria-label="a dense table"
                      style={{ marginBottom: 20 }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="right">Номинал</TableCell>
                          <TableCell align="right">Количество</TableCell>
                          <TableCell align="right">Сумма</TableCell>
                        </TableRow>
                      </TableHead>
                      {device.encashmentAmounts.map((encashment, index) => (
                        <TableRow
                          key={device.deviceName + index * 341}
                          style={{
                            backgroundColor:
                              index % 2 ? 'transparent' : '#eeeeee',
                          }}
                        >
                          <TableCell align="right">
                            {encashment.denomination / 100}
                          </TableCell>
                          <TableCell align="right">
                            {encashment.amount}
                          </TableCell>
                          <TableCell align="right">
                            {(encashment.denomination / 100) *
                              encashment.amount}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow key={device.deviceName + Math.random()}>
                        <TableCell key={device.deviceName + Math.random()} />
                        <TableCell
                          align="right"
                          key={device.deviceName + Math.random()}
                          style={{ fontWeight: 'bold' }}
                        >
                          Итого:
                        </TableCell>
                        <TableCell
                          align="right"
                          key={device.deviceName + Math.random()}
                          style={{ fontWeight: 'bold' }}
                        >
                          {getDeviceSum(device.encashmentAmounts)}
                        </TableCell>
                      </TableRow>
                    </Table>
                  </TableCell>
                </TableRow>
              </div>
            ))}
            <TableRow key={Math.random()}>
              <TableCell
                align="right"
                key={Math.random()}
                style={{ fontWeight: 'bold' }}
              >
                Сумма: {getPostSum(props.devices)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
  return <Redirect to="/login" />
}
