import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import {
  CorpPaymentsInterface,
  CorpUsersInterfaces,
  PlacesInterface,
} from '../interfaces'

type Props = {
  date: string
  corpPayments: CorpPaymentsInterface[]
  corpUsers: CorpUsersInterfaces[]
  places: PlacesInterface[]
  paymentType: 'income' | 'debt'
}

export const DaySummary = ({
  date,
  corpPayments,
  corpUsers,
  places,
  paymentType,
}: Props) => {
  const getCorpUserName = (userId: string): string => {
    const user = corpUsers.find((item) => item.id === userId)
    return user ? user.name : ''
  }
  const getCarwashName = (carwashId: string): string => {
    const currentCarwash = places.find((item) => item.id === carwashId)
    return currentCarwash ? currentCarwash.name : ''
  }

  return corpPayments.length > 0 ? (
    <TableContainer component={Paper} style={{ marginBottom: '20px' }}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>{date}</TableCell>
            {paymentType === 'income' && (
              <TableCell align="right">Описание</TableCell>
            )}
            {paymentType !== 'income' && (
              <TableCell align="right">Мойка</TableCell>
            )}
            {paymentType !== 'income' && (
              <TableCell align="right">Пост</TableCell>
            )}
            <TableCell align="right">Время</TableCell>
            <TableCell align="right">Сумма</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {corpPayments.map((item) => (
            <TableRow
              key={item.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {paymentType !== 'income'
                  ? getCorpUserName(item.workerId)
                  : 'пополнение'}
              </TableCell>
              {paymentType === 'income' && (
                <TableCell align="right">{item.description}</TableCell>
              )}
              {paymentType !== 'income' && (
                <TableCell align="right">
                  {getCarwashName(item.carwashId)}
                </TableCell>
              )}
              {paymentType !== 'income' && (
                <TableCell align="right">{item.postTitle}</TableCell>
              )}

              <TableCell align="right">
                {new Date(item.timestamp).toLocaleTimeString('ru-RU')}
              </TableCell>
              <TableCell align="right">{item.amount}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell
              colSpan={paymentType === 'income' ? 3 : 4}
              align="right"
              style={{ fontWeight: 'bold' }}
            >
              Итого:
            </TableCell>
            <TableCell align="right" style={{ fontWeight: 'bold' }}>
              {corpPayments.reduce((prev, item) => prev + item.amount, 0)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  ) : null
}
