import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { apiUrl } from '../../../../helpers/config'
import inMemoryJWT from '../../../../Auth/inMemoryJWT'
import { InputProps } from 'react-admin'
import {
  EncashmentAmount,
  EncashmentInputProps,
  SortedEncashmentAmount,
} from '../interfaces'

const useDetailsHook = (record: InputProps<EncashmentInputProps>) => {
  const [rawEncashmentAmount, setRawEncashmentAmount] = useState<
    EncashmentAmount[] | null
  >(null)
  const [encashmentAmount, setEncashmentAmount] = useState<
    SortedEncashmentAmount[] | null
  >(null)

  const getEncashmentAmounts = useCallback(async () => {
    const result = await axios.get<EncashmentAmount[]>(
      `${apiUrl}/admin/encashment/getEncashmentAmounts?encashmentId=${record.item.id}`,
      {
        headers: {
          Authorization: `Bearer ${inMemoryJWT.getToken()}`,
        },
        timeout: 500,
      }
    )
    setRawEncashmentAmount(result.data)
    const postRecordNames = [
      ...new Set(result.data.map((item) => item.item.post_title)),
    ]
    const sortedPostRecords = postRecordNames.map((postTitle) => {
      const sortedList = result.data
        .filter((item) => item.item.post_title === postTitle)
        .map((postRecord) => postRecord.item)

      const deviceNames = [
        ...new Set(sortedList.map((item) => item.device_name)),
      ]
      const deviceData = deviceNames.map((deviceName) => {
        const encashmentAmounts = sortedList
          .filter((sortedItem) => sortedItem.device_name === deviceName)
          .map((deviceData) => {
            const { denomination, amount } = deviceData
            return { denomination, amount }
          })

        return { deviceName, encashmentAmounts }
      })
      return {
        postTitle,
        devices: deviceData,
      }
    })
    setEncashmentAmount(sortedPostRecords)
  }, [record.item.id])

  useEffect(() => {
    if (!rawEncashmentAmount && record) {
      ;(async () => {
        await getEncashmentAmounts()
      })()
    }
  }, [getEncashmentAmounts, rawEncashmentAmount, record])

  return { encashmentAmount }
}

export default useDetailsHook
