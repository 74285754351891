import React from 'react'
import { FilterList, FilterListItem } from 'react-admin'
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion'

const typeList = [
  { id: 'message', name: 'сообщение' },
  { id: 'onMyWay', name: 'уже иду' },
  { id: 'payment', name: 'платеж' },
  { id: 'noAnswer', name: 'без ответа' },
  { id: 'subscribe', name: 'подписка' },
  { id: 'unsubscribe', name: 'отписка' },
  { id: 'reboot', name: 'перезагрузка' },
]

export const BotHistoryTypeFilter = () => {
  return (
    <FilterList label="Тип записи" icon={<AutoAwesomeMotionIcon />}>
      {typeList.map((item, index) => (
        <FilterListItem
          label={item.name}
          value={{
            type: item.id,
          }}
          key={index}
        />
      ))}
    </FilterList>
  )
}
