import React from 'react'
import {
  ListProps,
  List,
  Datagrid,
  TextField,
  ReferenceField,
  NumberField,
  DateField,
} from 'react-admin'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../../casl/Can'

export const CorpPaymentsList = (props: ListProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <List
      {...props}
      title="Корп. пользователи"
      bulkActionButtons={false}
      empty={false}
      sort={{ field: 'timestamp', order: 'DESC' }}
    >
      <Datagrid>
        {ability.can('read', 'corpOrganizations') && (
          <ReferenceField
            reference="corpOrganizations"
            source="organizationId"
            label="Организация"
            linkType="show"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        <ReferenceField
          reference="corpUsers"
          source="workerId"
          label="Сотрудник"
          linkType="show"
        >
          <TextField source="firstName" />
        </ReferenceField>
        <TextField source="description" label="Описание" />
        <ReferenceField
          reference="places"
          source="carwashId"
          label="Мойка"
          linkType={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="amount" label="Сумма" />
        <TextField source="postTitle" label="Пост" />
        <DateField source="timestamp" label="Дата" showTime={true} />
      </Datagrid>
    </List>
  )
}
