import React from 'react'
import classes from './CarwashStatus.module.sass'
import { Loading, Title, useAuthState } from 'react-admin'
import { Redirect } from 'react-router'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import useCarwashStatusHook from './hooks/useCarwashStatusHook'
import { CarwashStatusItem } from './components/CarwashStatusItem'

export const CarwashStatus = () => {
  const { places, carwashStatus } = useCarwashStatusHook()

  const { loading, authenticated } = useAuthState()

  if (loading || !places) {
    return <Loading />
  }
  if (authenticated) {
    return (
      <Card>
        <Title title="Статус постов" />
        <CardContent>
          <Typography variant="h6">Статус постов</Typography>
          <div className={classes.Wrapper}>
            {carwashStatus ? (
              carwashStatus.map((item, index) => (
                <CarwashStatusItem postStatusList={item} key={index} />
              ))
            ) : (
              <div className={classes.LoaderWrapper}>
                <Loading />
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    )
  }

  return <Redirect to="/login" />
}
