import React from 'react'
import {
  ListProps,
  List,
  Datagrid,
  ReferenceField,
  TextField,
  ImageField,
  EditButton,
} from 'react-admin'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../../casl/Can'
import classes from './ESCommonConfigList.module.sass'

export const ESCommonConfigList = (props: ListProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <List
      {...props}
      bulkActionButtons={ability.can('delete', 'whEvents') ? undefined : false}
      title="Общие настройки моточасов"
      empty={false}
    >
      <Datagrid
        rowClick={ability.can('update', 'whEvents') ? 'edit' : undefined}
      >
        <ReferenceField source="carwashId" reference="places" label="Мойка">
          <TextField source="name" />
        </ReferenceField>
        <ImageField
          source="photo"
          className={classes.Img}
          label="Пример фото"
        />
        {ability.can('update', 'whEvents') && <EditButton />}
      </Datagrid>
    </List>
  )
}
