import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  DateTimeInput,
  NumberInput,
  required,
  CreateProps,
} from 'react-admin'
import { promoNameValidator } from './components/promoValidator'

const dateParser = (dateValue: any) => {
  return new Date(dateValue).toISOString()
}

export const PromoCreate = (props: CreateProps) => (
  <Create {...props} title="Создать новый промокод">
    <SimpleForm>
      <TextInput
        source="name"
        label="Промо код"
        validate={promoNameValidator}
      />
      <NumberInput
        source="value"
        label="Бонусы к зачислению"
        validate={required()}
      />
      <DateTimeInput
        source="startDate"
        label="Дата начала действия"
        parse={dateParser}
        validate={required()}
      />
      <DateTimeInput
        source="endDate"
        label="Дата окончания действия"
        parse={dateParser}
        validate={required()}
      />
      <TextInput source="description" label="Описание" />
    </SimpleForm>
  </Create>
)
