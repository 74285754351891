import React from 'react'
import { Layout, LayoutProps } from 'react-admin'
import MyMenu from './MyMenu'
import MyAppBar from './AppBar/MyAppBar'

const MyLayout = (props: LayoutProps) => (
  <Layout {...props} menu={MyMenu} appBar={MyAppBar} />
)

export default MyLayout
