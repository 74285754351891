import React from 'react'
import {
  ListProps,
  List,
  Datagrid,
  TextField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
} from 'react-admin'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../../casl/Can'

export const CorpUsersList = (props: ListProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <List
      {...props}
      title="Корп. пользователи"
      bulkActionButtons={
        ability.can('delete', 'adminUsers') ? undefined : false
      }
      empty={false}
    >
      <Datagrid rowClick="show">
        <TextField source="firstName" label="Имя" />
        <TextField source="lastName" label="Фамилия" />
        <ReferenceArrayField label="Мойки" reference="places" source="carwash">
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <NumberField source="dayLimit" label="Лимит (день)" />
        <NumberField source="monthLimit" label="Лимит (месяц)" />
        {ability.can('read', 'corpOrganizations') && (
          <ReferenceField
            reference="corpOrganizations"
            source="organizationId"
            label="Организация"
            linkType="show"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        <BooleanField source="isPaymentEnabled" label="Платежи включены" />
        <TextField source="tgBotKey" label="Ключ для бота" />
        {ability.can('update', 'corpUsers') && <EditButton />}
      </Datagrid>
    </List>
  )
}
