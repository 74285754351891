import { useQueryWithStore } from 'react-admin'
import { useEffect, useState } from 'react'
import { Place } from '../../../reports/EncashmentReport/interfaces'
import { CarCounterDevice, DeviceList, SessionItem } from '../interfaces'
import useInterval from '@use-it/interval'
import axios from 'axios'
import inMemoryJWT from '../../../../Auth/inMemoryJWT'
import { apiUrl } from '../../../../helpers/config'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../../../casl/Can'

const useCarCounterDashboardHook = () => {
  const ability = useAbility(AbilityContext)

  const [places, setPlaces] = useState<Place[]>()
  const [deviceList, setDeviceList] = useState<DeviceList[] | null>(null)

  const placesList = useQueryWithStore({
    type: 'getList',
    resource: 'places',
    payload: {
      pagination: {
        page: 1,
        perPage: 1000,
      },
      sort: {
        field: 'id',
        order: 'ASC',
      },
      filter: {},
    },
  })

  useEffect(() => {
    if (places || !placesList || !placesList.data) return
    const filteredPlacesList = placesList.data.sort((a: any, b: any) =>
      a.name > b.name ? 1 : -1
    )
    setPlaces(filteredPlacesList)
  }, [places, placesList])

  const getDevicesList = async () => {
    if (!places || places.length === 0) return

    try {
      let token = inMemoryJWT.getToken()
      if (!token) {
        inMemoryJWT.setRefreshTokenEndpoint(`${apiUrl}/refresh/cookie`)
        const gotRefreshedToken = await inMemoryJWT.getRefreshedToken()
        if (!gotRefreshedToken) return
        token = inMemoryJWT.getToken()
      }

      const rawDeviceList = await axios.get<SessionItem[]>(
        `${apiUrl}/admin/carCounterWsGateway`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
          timeout: 5000,
        }
      )

      const savedDevices = await axios.get<CarCounterDevice[]>(
        `${apiUrl}/admin/carCounterDevice?_start=0&_end=1000000&_sort=id&_order=DESC`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
          timeout: 5000,
        }
      )

      const rawDeviceListWithCarwashId = rawDeviceList.data.map((item) => {
        const currentSavedDevice = savedDevices.data.find(
          (device) => device.deviceId === item.deviceId
        )
        return {
          carwashId: currentSavedDevice ? currentSavedDevice.carwashId : null,
          postNumber: currentSavedDevice ? currentSavedDevice.postNumber : null,
          deviceName: currentSavedDevice ? currentSavedDevice.deviceName : null,
          ...item,
        }
      })

      const filteredDeviceList = places
        .map((item) => {
          const carwashId = item.id
          const deviceList = rawDeviceListWithCarwashId.filter(
            (device) => device.carwashId === item.id
          )
          return {
            carwashId,
            carwashName: item.name,
            deviceList,
          }
        })
        .filter((item) => item.deviceList.length > 0)

      const unsavedDevices = rawDeviceListWithCarwashId.filter(
        (item) => item.carwashId === null
      )
      if (!ability.can('create', 'carCounter') || unsavedDevices.length === 0) {
        setDeviceList(filteredDeviceList)
        return
      }

      const result = [
        ...filteredDeviceList,
        { carwashId: null, carwashName: null, deviceList: unsavedDevices },
      ]

      setDeviceList(result)
    } catch (error) {
      console.log(`get device list error: ${error}`)
      setDeviceList([])
    }
  }

  useInterval(getDevicesList, 1000)

  return {
    places,
    deviceList,
  }
}

export default useCarCounterDashboardHook
