import { useAbility } from '@casl/react'
import { AbilityContext } from '../../../casl/Can'
import {
  BooleanField,
  Datagrid,
  EditButton,
  List,
  NumberField,
  TextField,
} from 'react-admin'
import React from 'react'

export const CorpOrganizationsList = (props: any) => {
  const ability = useAbility(AbilityContext)
  return (
    <List
      {...props}
      title="Список организаций"
      bulkActionButtons={
        ability.can('delete', 'corpOrganizations') ? undefined : false
      }
    >
      <Datagrid rowClick="show">
        <TextField source="name" label="Название" />
        <BooleanField source="isEnabled" label="Активна" />
        <NumberField source="creditLimit" label="Кредитный лимит" />
        <NumberField source="balance" label="Баланс" />
        {ability.can('update', 'corpOrganizations') && <EditButton />}
      </Datagrid>
    </List>
  )
}
