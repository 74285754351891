import React from 'react'
import {
  ListProps,
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../casl/Can'

export const AdminUsersList = (props: ListProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <List
      {...props}
      title="Администраторы"
      bulkActionButtons={
        ability.can('delete', 'adminUsers') ? undefined : false
      }
    >
      <Datagrid
        rowClick={ability.can('update', 'adminUsers') ? 'edit' : undefined}
      >
        <TextField source="username" label="Имя пользователя" />
        <ReferenceField
          reference="roles"
          source="role"
          linkType="show"
          label="Роль"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" label="Имя" />
        <ReferenceArrayField reference="places" source="carwash" label="Мойки">
          <SingleFieldList linkType="show">
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceField
          reference="corpOrganizations"
          source="organization"
          label="Организация"
          linkType="show"
        >
          <TextField source="name" />
        </ReferenceField>
        {/*<TextField source="organization" label="Организация" />*/}
      </Datagrid>
    </List>
  )
}
