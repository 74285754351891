import React from 'react'

import Drawer from '@material-ui/core/Drawer'
import { DetailsField } from '../../transaction/components/DetailsField'

export const TemporaryDrawer = ({ isOpen, setIsOpen, record }: any) => {
  // @ts-ignore
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setIsOpen(open)
  }
  return (
    <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
      {record && <DetailsField source="" record={record} />}
    </Drawer>
  )
}
