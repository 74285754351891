import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Loading, Title, useAuthState } from 'react-admin'
import { Redirect } from 'react-router'
import useDashboardHook from './hooks/useDashboardHook'
import Typography from '@mui/material/Typography'
import { CarwashCheckboxFormGroup } from '../reports/EncashmentReport/components/StepContent/CarwashCheckboxFormGroup'
import classes from './Dashboard.module.sass'
import { CarwashListItem } from './components/CarwashListItem'

const Dashboard = () => {
  const {
    places,
    checkedPlaces,
    checkedPlaceHandler,
    getEncashmentAmountsByCarwashId,
    getCarwashSum,
    getSumByDeviceName,
  } = useDashboardHook()
  /**
   * This hook is secure access to the page
   */
  const { loading, authenticated } = useAuthState()

  if (loading || !places) {
    return <Loading />
  }
  if (authenticated) {
    return (
      <Card>
        <Title title="Главная" />
        <CardContent>
          <Typography variant="h6">Текущий баланс</Typography>
          <CarwashCheckboxFormGroup
            places={places}
            checkedPlaces={checkedPlaces}
            onChangeHandler={checkedPlaceHandler}
          />
          <div className={classes.Wrapper}>
            {checkedPlaces.map((item) => {
              const currentPlace = places.find((place) => place.id === item)
              return (
                currentPlace && (
                  <CarwashListItem
                    currentPlace={currentPlace}
                    getCarwashSum={getCarwashSum}
                    getEncashmentAmountsByCarwashId={
                      getEncashmentAmountsByCarwashId
                    }
                    getSumByDeviceName={getSumByDeviceName}
                  />
                )
              )
            })}
          </div>
        </CardContent>
      </Card>
    )
  }

  return <Redirect to="/login" />
}

export default Dashboard
