import React from 'react'

import Container from '@material-ui/core/Container'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import { InputProps, Loading, useAuthState } from 'react-admin'
import useDetailsHook from './hooks/useDetailsHook'
import { EncashmentDetailsItem } from './EncashmentDetailsItem'
import { Redirect } from 'react-router'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
})

export const EncashmentDetails = ({ record }: InputProps) => {
  const classes = useStyles()
  const { encashmentAmount } = useDetailsHook(record)

  /**
   * This hook is secure access to the page
   */
  const { loading, authenticated } = useAuthState()

  if (loading) {
    return <Loading />
  }

  if (authenticated) {
    return (
      <Container>
        <Card className={classes.root}>
          <CardContent>
            {encashmentAmount &&
              encashmentAmount.map((encashment, index) => (
                <EncashmentDetailsItem
                  {...encashment}
                  key={encashment.postTitle + index * 12}
                />
              ))}
          </CardContent>
        </Card>
      </Container>
    )
  }
  return <Redirect to="/login" />
}
