import React, { cloneElement } from 'react'
import {
  List,
  Datagrid,
  ReferenceField,
  TextField,
  ArrayField,
  SingleFieldList,
  ChipField,
  NumberField,
  ListProps,
  SelectField,
  BooleanField,
} from 'react-admin'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../casl/Can'

const StringToLabelObject = ({ record, children, ...rest }: any) =>
  cloneElement(children, {
    record: { label: record },
    ...rest,
  })

export const SpecialPaymentsConfigList = (props: ListProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <List
      {...props}
      bulkActionButtons={
        ability.can('delete', 'specialPayment') ? undefined : false
      }
      title="Настройка особых платежей"
    >
      <Datagrid
        rowClick={ability.can('update', 'specialPayment') ? 'edit' : undefined}
      >
        <ReferenceField source="carwashId" reference="places" label="Мойка">
          <TextField source="name" />
        </ReferenceField>
        <ArrayField source="daysOfWeek" label="Дни недели">
          <SingleFieldList
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '10px 0',
            }}
            linkType={false}
          >
            <StringToLabelObject>
              <SelectField
                source="label"
                choices={[
                  { id: 1, name: 'Понедельник' },
                  { id: 2, name: 'Вторник' },
                  { id: 3, name: 'Среда' },
                  { id: 4, name: 'Четверг' },
                  { id: 5, name: 'Пятница' },
                  { id: 6, name: 'Суббота' },
                  { id: 0, name: 'Воскресенье' },
                ]}
              />
            </StringToLabelObject>
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="paymentType" label="Тип платежа">
          <SingleFieldList
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '10px 0',
            }}
            linkType={false}
          >
            <StringToLabelObject>
              <SelectField
                source="label"
                choices={[
                  { id: 1, name: 'Наличные' },
                  { id: 2, name: 'Карта' },
                  { id: 3, name: 'Приложение' },
                ]}
              />
            </StringToLabelObject>
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="timeInterval" label="Интревал времени">
          <Datagrid>
            <ChipField source="start" label="Начиная с" />
            <ChipField source="end" label="Заканчивая по" />
          </Datagrid>
        </ArrayField>
        <NumberField source="threshold" label="Порог срабатывания" />
        <BooleanField source="isEnabled" label="Правило активно" />
      </Datagrid>
    </List>
  )
}
