import React from 'react'
import useReportHook from './hooks/useReportHook'
import { Loading } from 'react-admin'
import Container from '@material-ui/core/Container'
import { getReportCarwashSum } from '../../../../Encashment/components/functions/getSum'
import { Place } from '../../interfaces'
import Typography from '@mui/material/Typography'
import { ReportTable } from './ReportTable'

type Props = {
  checkedPlaces: string[]
  startDate: string
  endDate: string
  places: Place[]
}

export const EncashmentReportResult = ({
  checkedPlaces,
  startDate,
  endDate,
  places,
}: Props) => {
  const { reportEncashmentAmount } = useReportHook({
    checkedPlaces,
    startDate,
    endDate,
  })

  const getPlaceName = (placeId: string) => {
    if (!places) return ''
    const currentName = places.find((place) => place.id === placeId)
    return currentName ? currentName.name : ''
  }

  if (!reportEncashmentAmount) return <Loading />
  return (
    <Container style={{ marginTop: 30 }}>
      {reportEncashmentAmount.length === 0 && (
        <Typography variant="subtitle1">записи не найдены</Typography>
      )}
      {reportEncashmentAmount.map((reportRecord, index) => (
        <div key={reportRecord.carwashId + index * 5543}>
          <Typography
            variant="h6"
            style={{
              marginBottom: 20,
              color: '#3873cb',
            }}
          >
            {getPlaceName(reportRecord.carwashId)} -{' '}
            {getReportCarwashSum(reportRecord)} ₽
          </Typography>
          {reportRecord.data.map((recordItem, index) => (
            <ReportTable
              recordItem={recordItem}
              key={recordItem.dateTime.toString() + (index + 77)}
            />
          ))}
        </div>
      ))}
    </Container>
  )
}
