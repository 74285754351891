import { PromoList } from './PromoList'
import { PromoCreate } from './PromoCreate'
import { PromoEdit } from './PromoEdit'

export default {
  list: PromoList,
  // show: UserShow,
  edit: PromoEdit,
  create: PromoCreate,
}
