import React from 'react'
import {
  DateTimeInput,
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  required,
  EditProps,
  Toolbar,
  SaveButton,
  DeleteButton,
  ToolbarProps,
} from 'react-admin'
import { promoNameValidator } from './components/promoValidator'
import { makeStyles } from '@material-ui/core/styles'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../casl/Can'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const CustomToolbar = (props: ToolbarProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      {ability.can('delete', 'promoCode') && <DeleteButton />}
    </Toolbar>
  )
}

const dateParser = (dateValue: any) => new Date(dateValue).toISOString()
const dateFormatter = (dateValue: any) =>
  new Date(dateValue).toLocaleDateString()

export const PromoEdit = (props: EditProps) => (
  <Edit {...props} title="Редактирование промокода">
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput
        source="name"
        label="Промо код"
        validate={promoNameValidator}
      />
      <NumberInput
        source="value"
        label="Бонусы к зачислению"
        validate={required()}
      />
      <DateTimeInput
        source="startDate"
        label="Дата начала действия"
        parse={dateParser}
        // format={dateFormatter}
        validate={required()}
      />
      <DateTimeInput
        source="endDate"
        label="Дата окончания действия"
        parse={dateParser}
        // format={dateFormatter}
        validate={required()}
      />
      <TextInput source="description" label="Описание" />
    </SimpleForm>
  </Edit>
)
