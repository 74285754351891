import React from 'react'
import { PlacesInterface } from '../../../../corporateOffice/corpReport/interfaces'
import Typography from '@mui/material/Typography'
import { ReportCardItem } from './ReportCardItem'
import { ReportListItem } from '../../interfaces'

type Props = {
  places: PlacesInterface[]
  reportListItem: ReportListItem
}

export const ReportCardGrid = ({ places, reportListItem }: Props) => {
  const getCarwashName = (carwashId: string): string => {
    const currentCarwash = places.find((item) => item.id === carwashId)
    return currentCarwash ? currentCarwash.name : ''
  }
  const {
    carsPerDay,
    carsPerDayFormula,
    totalCarsFormula,
    totalCars,
    totalAmount,
    carwashId,
    exceedingLimit,
    maxTime,
    averageTime,
    averageBill,
  } = reportListItem

  return (
    <>
      <Typography
        variant="body1"
        gutterBottom
        component="div"
        style={{ fontWeight: 500 }}
      >
        Мойка: {getCarwashName(carwashId)}
      </Typography>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginBottom: '20px',
        }}
      >
        <ReportCardItem
          title="За сутки"
          value={carsPerDay}
          description="кол-во машин"
        />
        <ReportCardItem
          title="За выбранный период"
          value={totalCars}
          description="кол-во машин"
        />
        <ReportCardItem
          title="За сутки"
          value={carsPerDayFormula}
          description="кол-во машин (по формуле)"
        />
        <ReportCardItem
          title="За выбранный период"
          value={totalCarsFormula}
          description="кол-во машин (по формуле)"
        />
        <ReportCardItem
          title="Среднее время (мин)"
          value={Math.trunc(averageTime / 60)}
          description="проведенное на посту"
        />
        <ReportCardItem
          title="Макс. время (мин)"
          value={Math.trunc(maxTime / 60)}
          description="проведенное на посту"
        />
        <ReportCardItem
          title="С превышением времени"
          value={exceedingLimit}
          description="кол-во машин"
        />
        <ReportCardItem
          title="Средний чек"
          value={averageBill}
          description="руб"
        />
        <ReportCardItem
          title="Итого за выбранный период"
          value={totalAmount}
          description="руб"
        />
      </div>
    </>
  )
}
