import React from 'react'
import {
  DeleteButton,
  Edit,
  EditProps,
  ImageField,
  ImageInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  ToolbarProps,
} from 'react-admin'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../../casl/Can'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const CustomToolbar = (props: ToolbarProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      {ability.can('delete', 'whEvents') && <DeleteButton />}
    </Toolbar>
  )
}

const PreviewImage = ({ record, source }: any) => {
  if (typeof record == 'string') {
    record = {
      [source]: record,
    }
  }
  return <ImageField record={record} source={source} />
}

export const ESCommonConfigEdit = (props: EditProps) => (
  <Edit {...props} title="Изменить общие настройки моточасов">
    <SimpleForm toolbar={<CustomToolbar />}>
      <ReferenceInput
        source="carwashId"
        reference="places"
        label="Мойка"
        validate={required()}
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <ImageInput
        source="photo"
        label="Пример фото"
        accept="image/*"
        validate={required()}
      >
        <PreviewImage source="src" />
      </ImageInput>
    </SimpleForm>
  </Edit>
)
