import React from 'react'
import {
  List,
  Datagrid,
  ReferenceField,
  TextField,
  SelectField,
} from 'react-admin'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../../casl/Can'

export const CarCounterDeviceList = (props: any) => {
  const ability = useAbility(AbilityContext)
  return (
    <List
      {...props}
      title="Список устройств"
      bulkActionButtons={
        ability.can('delete', 'carCounter') ? undefined : false
      }
    >
      <Datagrid
        rowClick={ability.can('update', 'carCounter') ? 'edit' : undefined}
      >
        <TextField source="deviceId" label="Id устройства" />
        <ReferenceField source="carwashId" reference="places" label="Мойка">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="postNumber" label="Номер поста" />
        <SelectField
          source="type"
          label="Тип устройства"
          choices={[
            { id: 'carCounter', name: 'Счетчик машин' },
            { id: 'waterDispenser', name: 'Рукомойник' },
          ]}
        />
        <TextField source="deviceName" label="Название" />
      </Datagrid>
    </List>
  )
}
