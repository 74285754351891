import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ListProps,
} from 'react-admin'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../casl/Can'

export const BotActiveUserList = (props: ListProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <List
      {...props}
      title="Активные подписки"
      bulkActionButtons={ability.can('delete', 'botusers') ? undefined : false}
    >
      <Datagrid rowClick="edit">
        <ReferenceField
          reference="botUsers"
          source="botUserId"
          link="show"
          label="Пользователь"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="chatFirstName" label="Имя" />
        <TextField source="chatLastName" label="Фамилия" />
        <TextField source="chatUserId" label="telegram user Id" />
      </Datagrid>
    </List>
  )
}
