import {
  CarCounterInterface,
  CarwashOfflinePayment,
  CarwashOnlinePayment,
} from '../interfaces'

type Props = {
  carCounterList: CarCounterInterface[]
  offlinePayments: CarwashOfflinePayment | undefined
  onlinePayments: CarwashOnlinePayment[]
}

export const getAverageBill = ({
  carCounterList,
  offlinePayments,
  onlinePayments,
}: Props): number => {
  if (carCounterList.length === 0) return 0
  const totalOnlineAmount = onlinePayments.reduce((prev, item) => {
    try {
      const paymentDetails = JSON.parse(item.details)
      return prev + Number(paymentDetails.amount.value)
    } catch (error) {
      return prev
    }
  }, 0)
  const totalAmount = offlinePayments
    ? offlinePayments.totalAmount + totalOnlineAmount
    : totalOnlineAmount
  return Math.round(totalAmount / carCounterList.length)
}
