import React from 'react'
import classes from './CarCounterDashboard.module.sass'
import useCarCounterDashboardHook from './hooks/useCarCounterDashboardHook'
import { useAuthState, Loading, Title } from 'react-admin'
import { Redirect } from 'react-router'
import Paper from '@mui/material/Paper'
import { DeviceListItem } from './deviceListItem/DeviceListItem'
import { Typography } from '@mui/material'

export const CarCounterDashboard = () => {
  const { deviceList } = useCarCounterDashboardHook()
  /**
   * This hook is secure access to the page
   */
  const { loading, authenticated } = useAuthState()

  if (loading) return <Loading />

  if (authenticated) {
    if (!deviceList) return <Loading />
    return (
      <div className={classes.Container}>
        <Title title="Счетчик машин - dashboard" />
        <Paper elevation={2} className={classes.Paper}>
          {deviceList.length > 0 ? (
            deviceList.map((item, deviceListIndex) => {
              if (item.deviceList.length > 0)
                return (
                  <div style={{ marginBottom: '20px' }}>
                    <Typography
                      variant="h6"
                      style={{
                        marginBottom: '20px',
                      }}
                    >
                      {item.carwashName
                        ? `Мойка - ${item.carwashName}`
                        : 'Не сохраненные устройства'}
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        overflow: 'hidden',
                      }}
                    >
                      {item.deviceList.map((device, index) => (
                        <DeviceListItem deviceItem={device} key={index} />
                      ))}
                    </div>
                  </div>
                )
              return null
            })
          ) : (
            <div>список пуст</div>
          )}
        </Paper>
      </div>
    )
  }

  return <Redirect to="/login" />
}
