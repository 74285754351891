import React, { useEffect, useState } from 'react'
import { Loading, Title, useAuthState, useQueryWithStore } from 'react-admin'
import classes from '../reports/EncashmentReport/EncashmentReport.module.sass'
import Paper from '@mui/material/Paper'
import { Redirect } from 'react-router'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Link } from '@mui/material'

const getRowColor = (serviceInterval: number, machineHours: number): string => {
  if (!serviceInterval) {
    return 'transparent'
  }
  const value = (machineHours * 100) / (serviceInterval * 60 * 60)
  if (value < 80) return '#53CA41'
  if (value >= 80 && value <= 100) return '#FBF154'
  return '#E02121'
}

export const MachineHoursSummary = () => {
  const [data, setData] = useState<any>([])
  const machineHoursList = useQueryWithStore({
    type: 'getList',
    resource: 'machineHoursSummary',
    payload: {
      pagination: {
        page: 1,
        perPage: 1000,
      },
      sort: {
        field: 'id',
        order: 'ASC',
      },
      filter: {},
    },
  })

  useEffect(() => {
    if (data.length > 0 || !machineHoursList || !machineHoursList.data) return
    setData(machineHoursList.data)
  }, [data, machineHoursList])

  /**
   * This hook is secure access to the page
   */
  const { loading, authenticated } = useAuthState()

  if (loading) {
    return <Loading />
  }
  if (authenticated) {
    return (
      <div className={classes.Container}>
        <Title title="Моточасы" />
        <>
          {data.map((item: any) => (
            <TableContainer component={Paper} style={{ marginBottom: '20px' }}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={4}>
                      Мойка - {item.carwashName}
                    </TableCell>
                    <TableCell colSpan={4}>Последняя замена масла</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">Пост</TableCell>
                    <TableCell align="right">АВД</TableCell>
                    <TableCell align="right">Текущая наработка</TableCell>
                    <TableCell align="right">Норма</TableCell>
                    <TableCell align="right">Дата</TableCell>
                    <TableCell align="right">Сотрудник</TableCell>
                    <TableCell align="right">Ссылка на запись</TableCell>
                    <TableCell align="right">Подтверждена</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.posts.map((post: any) => (
                    <TableRow
                      key={Math.round(Math.random() * (10000000 - 1) + 1)}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                      style={{
                        backgroundColor: getRowColor(
                          post.equipmentConfigServiceInterval,
                          post.machineHours
                        ),
                      }}
                    >
                      <TableCell align="right">{post.postTitle}</TableCell>
                      <TableCell align="right">{post.deviceName}</TableCell>
                      <TableCell align="right">
                        {Math.round(post.machineHours / 60 / 60)}
                      </TableCell>
                      <TableCell align="right">
                        {post.equipmentConfigServiceInterval
                          ? post.equipmentConfigServiceInterval
                          : '-'}
                      </TableCell>
                      <TableCell align="right">
                        {post.equipmentServiceTimestamp
                          ? new Date(
                              post.equipmentServiceTimestamp
                            ).toLocaleString('ru-RU')
                          : '-'}
                      </TableCell>
                      <TableCell align="right">
                        {post.equipmentServiceEmployee
                          ? post.equipmentServiceEmployee
                          : '-'}
                      </TableCell>
                      <TableCell align="right">
                        {post.equipmentServiceId ? (
                          <Link
                            href={`/#/equipmentServiceList/${post.equipmentServiceId}/show`}
                            sx={{
                              color: '#000000',
                              textDecorationColor: '#000000',
                            }}
                          >
                            открыть
                          </Link>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {post.equipmentServiceIsConfirmed ? 'Да' : 'Нет'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ))}
        </>
      </div>
    )
  }

  return <Redirect to="/login" />
}
