import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

export const PostTableRow = ({ row }: any) => {
  const [open, setOpen] = useState(false)
  const classes = useRowStyles()

  const getCurrentProgramName = (row: any) => {
    const currentProgram = row.programs.filter(
      (item: any) => item.id === row.currentProgramId
    )
    if (currentProgram.length === 0) return 'нет'
    return currentProgram[0].title
  }

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.title}
        </TableCell>
        <TableCell align="right">{row.id}</TableCell>
        <TableCell align="right">{row.isOnline ? 'Да' : 'Нет'}</TableCell>
        <TableCell align="right">{row.moneyRemainder}</TableCell>
        <TableCell align="right">{getCurrentProgramName(row)}</TableCell>
        <TableCell align="right">
          {new Date(row.timeStamp).toLocaleString('ru-RU')}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Список доступных программ:
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Название программы</TableCell>
                    <TableCell align="right">Номер пистолета</TableCell>
                    <TableCell align="right">Цена за минуту</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.programs.map((program: any) => (
                    <TableRow key={program.id}>
                      <TableCell component="th" scope="row">
                        {program.id}
                      </TableCell>
                      <TableCell>{program.title}</TableCell>
                      <TableCell align="right">{program.pistolNum}</TableCell>
                      <TableCell align="right">
                        {program.pricePerMinute.toString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
