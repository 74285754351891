import { PlaceList } from "./PlaceList"
import { PlaceShow } from "./PlaceShow"
import { PlaceEdit } from "./PlaceEdit"
import { PlaceCreate } from "./PlaceCreate"

export default {
  list: PlaceList,
  show: PlaceShow,
  edit: PlaceEdit,
  create: PlaceCreate,
}
