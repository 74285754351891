import React from 'react'
import { Loading, Title, useAuthState } from 'react-admin'
import { Redirect } from 'react-router'
import { Card, CardContent } from '@mui/material'
import useProgramReportHook from './hooks/useProgramReportHook'
import { ProgramReportFilter } from './components/ProgramReportFilter/ProgramReportFilter'
import { ProgramReportTable } from './components/ProgramReportTable/ProgramReportTable'

export const ProgramReport = () => {
  const {
    places,
    isLoading,
    currentCarwashList,
    setCurrentCarwashList,
    setLastSeenGte,
    setLastSeenLte,
    lastSeenGte,
    lastSeenLte,
    financeReport,
    getReport,
    dateTimeError,
    toggleDateRangeValue,
    setToggleDateRangeValue,
  } = useProgramReportHook()
  /** This hook is secure access to the page */
  const { loading, authenticated } = useAuthState()

  if (loading || !places) {
    return <Loading />
  }
  if (authenticated) {
    return (
      <Card sx={{ marginTop: '20px' }}>
        <Title title="Отчет по программам" />
        <CardContent>
          <ProgramReportFilter
            currentCarwashList={currentCarwashList}
            setCurrentCarwashList={setCurrentCarwashList}
            places={places}
            lastSeenGte={lastSeenGte}
            setLastSeenGte={setLastSeenGte}
            lastSeenLte={lastSeenLte}
            setLastSeenLte={setLastSeenLte}
            onClickHandler={getReport}
            dateTimeError={dateTimeError}
            toggleDateRangeValue={toggleDateRangeValue}
            setToggleDateRangeValue={setToggleDateRangeValue}
          />
          {isLoading ? (
            <Loading />
          ) : financeReport.length > 0 ? (
            <ProgramReportTable report={financeReport} />
          ) : (
            <div>список пуст</div>
          )}
        </CardContent>
      </Card>
    )
  }

  return <Redirect to="/login" />
}
