import React from 'react'
import {
  EditProps,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  ToolbarProps,
  SelectArrayInput,
  ReferenceArrayInput,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../casl/Can'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const CustomToolbar = (props: ToolbarProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      {ability.can('delete', 'adminUsers') && <DeleteButton />}
    </Toolbar>
  )
}

export const AdminUsersEdit = (props: EditProps) => (
  <Edit {...props} title="Изменить администратора">
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput source="id" disabled={true} />
      <TextInput source="username" label="Имя пользователя" />
      <ReferenceInput source="role" reference="roles" label="Роль">
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <TextInput source="name" label="Имя" />
      <ReferenceArrayInput label="Мойки" source="carwash" reference="places">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceInput
        reference="corpOrganizations"
        source="organization"
        label="Организация"
      >
        <SelectInput optionText="name" optionValue="id" resettable={true} />
      </ReferenceInput>
      {/*<TextInput source="organization" label="Организация" />*/}
    </SimpleForm>
  </Edit>
)
