import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}))

export const CustomTextInput = ({ label, onChangeHandler, value }: any) => {
  const classes = useStyles()

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField
        label={label}
        value={value}
        onChange={(e) => onChangeHandler(e.target.value)}
      />
    </form>
  )
}
