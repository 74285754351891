import { useQueryWithStore } from 'react-admin'
import { useCallback, useEffect, useState } from 'react'
import {
  CarCounterInterface,
  CarwashOfflinePayment,
  CarwashOnlinePayment,
  EventInterface,
  PlacesInterface,
  ReportListItem,
} from '../interfaces'
import { parseDate } from '../../../reports/EncashmentReport/components/StepContent/functions/parseDate'
import {
  getCarCounterList,
  getEventsList,
  getOfflinePayments,
  getOnlinePayments,
} from '../functions/api'
import { getCarsPerDay } from '../functions/getCarsPerDay'
import { getCarsPerDayFormula } from '../functions/getCarsPerDayFormula'
import { getTotalCarsFormula } from '../functions/getTotalCarsFormula'
import { getAverageTime } from '../functions/getAverageTime'
import { getMaxTime } from '../functions/getMaxTime'
import { countExceedingLimit } from '../functions/countExceedingLimit'
import { getAverageBill } from '../functions/getAverageBill'
import { getTotalAmount } from '../functions/getTotalAmount'

const useCarCounterReportHook = () => {
  const placesReq = useQueryWithStore({
    type: 'getList',
    resource: 'places',
    payload: {
      pagination: {
        page: 1,
        perPage: 100000,
      },
      sort: {
        field: 'id',
        order: 'ASC',
      },
      filter: {},
    },
  })

  const [places, setPlaces] = useState<PlacesInterface[]>([])
  const [lastSeenLte, setLastSeenLte] = useState<string>(parseDate(Date.now()))
  const [lastSeenGte, setLastSeenGte] = useState<string>(
    parseDate(Date.now() - 30 * 24 * 60 * 60 * 1000)
  )
  const [currentCarwashList, setCurrentCarwashList] = useState<string[]>([])
  const [reportList, setReportList] = useState<ReportListItem[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [dateTimeError, setDateTimeError] = useState(false)

  useEffect(() => {
    if (!placesReq.data || places.length > 0) return
    setPlaces(placesReq.data)
  }, [places.length, placesReq.data])

  const makeReportList = useCallback(
    (
      offlinePaymentsData: CarwashOfflinePayment[],
      onlinePaymentsData: CarwashOnlinePayment[],
      carCounterListData: CarCounterInterface[],
      eventsListData: EventInterface[]
    ) => {
      const list =
        currentCarwashList.length > 0
          ? currentCarwashList
          : places.map((item) => item.id)

      const tmpReportList = list.map((currentCarwashId) => {
        const currentOfflinePayments = offlinePaymentsData.find(
          (item) => item.carwashId === currentCarwashId
        )
        const currentOnlinePayments = onlinePaymentsData.filter(
          (item) => item.washId === currentCarwashId
        )
        const currentCarCounterList = carCounterListData.filter(
          (item) => item.carwashId === currentCarwashId
        )
        const currentEvents = eventsListData.find(
          (item) => item.carwashId === currentCarwashId
        )
        const currentCarwashRecord = places.find(
          (item) => item.id === currentCarwashId
        )
        const timeLimit = currentCarwashRecord
          ? currentCarwashRecord.maxSpentTime
          : 0

        return {
          carsPerDay: getCarsPerDay({
            carCounterList: currentCarCounterList,
            lastSeenLte,
            lastSeenGte,
          }),
          totalCars: currentCarCounterList.length,
          carsPerDayFormula: getCarsPerDayFormula({
            events: currentEvents,
            lastSeenLte,
            lastSeenGte,
          }),
          totalCarsFormula: getTotalCarsFormula({ events: currentEvents }),
          averageTime: getAverageTime({
            carCounterList: currentCarCounterList,
          }),
          maxTime: getMaxTime({ carCounterList: currentCarCounterList }),
          exceedingLimit: countExceedingLimit({
            carCounterList: currentCarCounterList,
            timeLimit,
          }),
          averageBill: getAverageBill({
            carCounterList: currentCarCounterList,
            offlinePayments: currentOfflinePayments,
            onlinePayments: currentOnlinePayments,
          }),
          totalAmount: getTotalAmount({
            offlinePayments: currentOfflinePayments,
            onlinePayments: currentOnlinePayments,
          }),
          carwashId: currentCarwashId,
        }
      })
      setReportList(tmpReportList)
    },
    [currentCarwashList, lastSeenGte, lastSeenLte, places]
  )

  const getReport = useCallback(async () => {
    if (!placesReq.data || currentCarwashList.length === 0) return
    try {
      setIsLoading(true)

      const offlinePaymentsData = await getOfflinePayments({
        lastSeenLte,
        lastSeenGte,
        carwashId: currentCarwashList,
      })

      const onlinePaymentsData = await getOnlinePayments({
        lastSeenLte,
        lastSeenGte,
        carwashId: currentCarwashList,
      })

      const carCounterListData = await getCarCounterList({
        lastSeenLte,
        lastSeenGte,
        carwashId: currentCarwashList,
      })

      const eventsListData = await getEventsList({
        lastSeenLte,
        lastSeenGte,
        carwashId: currentCarwashList,
      })

      makeReportList(
        offlinePaymentsData,
        onlinePaymentsData,
        carCounterListData,
        eventsListData
      )
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }, [
    currentCarwashList,
    lastSeenGte,
    lastSeenLte,
    makeReportList,
    placesReq.data,
  ])

  useEffect(() => {
    const threshold = 2 * 30 * 24 * 60 * 60 * 1000
    const differenceTime =
      new Date(lastSeenLte).getTime() - new Date(lastSeenGte).getTime()
    differenceTime > threshold
      ? setDateTimeError(true)
      : setDateTimeError(false)
  }, [lastSeenLte, lastSeenGte])

  return {
    lastSeenLte,
    setLastSeenLte,
    lastSeenGte,
    setLastSeenGte,
    places,
    currentCarwashList,
    setCurrentCarwashList,
    reportList,
    isLoading,
    dateTimeError,
    getReport,
  }
}

export default useCarCounterReportHook
