import React from 'react'
import { CarwashStatusList } from '../interfaces'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

type Props = {
  postStatusList: CarwashStatusList
}

export const CarwashStatusItem = ({ postStatusList }: Props) => {
  return (
    <TableContainer component={Paper} style={{ marginBottom: '20px' }}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>{postStatusList.carwashName}</TableCell>
            <TableCell align="right">isOnline</TableCell>
            <TableCell align="right">dbName</TableCell>
            <TableCell align="right">dbSchemaDt</TableCell>
            <TableCell align="right">dbConnectionEstablished</TableCell>
            <TableCell align="right">selfCheckoutVersion</TableCell>
            <TableCell align="right">carWashInteropVersion</TableCell>
            <TableCell align="right">ipAddresses</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {postStatusList.data.map((row) => (
            <TableRow
              key={row.title}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.title}
              </TableCell>
              <TableCell align="right">
                {row.isOnline ? JSON.stringify(row.isOnline) : '-'}
              </TableCell>
              <TableCell align="right">
                {row.dbName ? row.dbName : '-'}
              </TableCell>
              <TableCell align="right">
                {row.dbSchemaDt
                  ? new Date(row.dbSchemaDt).toLocaleDateString('ru-RU')
                  : '-'}
              </TableCell>
              <TableCell align="right">
                {row.dbConnectionEstablished
                  ? JSON.stringify(row.dbConnectionEstablished)
                  : '-'}
              </TableCell>
              <TableCell align="right">
                {row.selfCheckoutVersion ? row.selfCheckoutVersion : '-'}
              </TableCell>
              <TableCell align="right">
                {row.carWashInteropVersion ? row.carWashInteropVersion : '-'}
              </TableCell>
              <TableCell align="right">
                {row.ipAddresses ? JSON.stringify(row.ipAddresses) : '-'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
