import { useCallback, useEffect, useRef, useState } from 'react'
import { Place } from '../../reports/EncashmentReport/interfaces'
import { useQueryWithStore } from 'react-admin'
import { CarwashStatus, CarwashStatusList } from '../interfaces'
import axios from 'axios'
import { apiUrl } from '../../../helpers/config'
import InMemoryJWT from '../../../Auth/inMemoryJWT'

const useCarwashStatusHook = () => {
  const [places, setPlaces] = useState<Place[]>()
  const [carwashStatus, setCarwashStatus] = useState<CarwashStatusList[]>()

  const usersCarwash = useRef<string[]>([])

  useEffect(() => {
    const userProfile = localStorage.getItem('profile')
    if (!userProfile) return
    const profileData = JSON.parse(userProfile)
    usersCarwash.current = profileData.carwash
  }, [])

  const placesList = useQueryWithStore({
    type: 'getList',
    resource: 'places',
    payload: {
      pagination: {
        page: 1,
        perPage: 1000,
      },
      sort: {
        field: 'id',
        order: 'ASC',
      },
      filter: {},
    },
  })

  useEffect(() => {
    if (places || usersCarwash.current.length === 0 || !placesList.data) return
    const filteredPlacesList = placesList.data.filter((item: any) =>
      usersCarwash.current.find((carwash) => carwash === item.id)
    )
    setPlaces(filteredPlacesList)
  }, [places, placesList])

  const getCarwashStatus = useCallback(async () => {
    if (!places) return
    const result = await Promise.all(
      places.map(async (place) => {
        try {
          const response = await axios.post<CarwashStatus[]>(
            `${apiUrl}/admin/places/checkAvailablePosts`,
            {
              washId: place.id,
              baseUrl: place.baseUrl,
            },
            {
              timeout: 5000,
              headers: {
                Authorization: `Bearer ${InMemoryJWT.getToken()}`,
              },
            }
          )
          return {
            carwashName: place.name,
            data: response.data.filter((item) => item),
          }
        } catch (error) {
          console.log('checkAvailablePosts error: ', error)
          return null
        }
      })
    )
    // console.log('result: ', result)
    /** TODO: тут ts все таки считает, что после фильтра в data может быть null */
    const rawFilteredResult = result.filter((item) => item)
    const sortByCarwashName = rawFilteredResult.sort((a: any, b: any) =>
      a.carwashName > b.carwashName ? 1 : -1
    )
    const filteredResult = sortByCarwashName.map((item) => {
      //@ts-ignore
      const sortedData = item.data.sort((a: any, b: any) => {
        if (!a || !b) return 1
        return a.title > b.title ? 1 : -1
      })
      return {
        //@ts-ignore
        carwashName: item.carwashName,
        data: sortedData,
      }
    })
    // console.log('filtered result: ', filteredResult)

    // @ts-ignore
    setCarwashStatus(filteredResult)
  }, [places])

  useEffect(() => {
    if (!places || carwashStatus) return
    getCarwashStatus().then()
  }, [carwashStatus, getCarwashStatus, places])

  return {
    places,
    carwashStatus,
  }
}

export default useCarwashStatusHook
