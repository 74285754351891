import React from 'react'

import Button from '@material-ui/core/Button'
import VisibilityIcon from '@material-ui/icons/Visibility'

export const UserShowButton = ({
  record,
  setIsOpen,
  setCurrentRecord,
}: any) => {
  const onClickHandler = () => {
    setCurrentRecord(record)
    setIsOpen(true)
  }
  return (
    <Button
      size="medium"
      color="primary"
      startIcon={<VisibilityIcon />}
      onClick={onClickHandler}
    >
      Подробнее
    </Button>
  )
}
