import React from 'react'
import classes from './FinanceReportFilter.module.sass'
import { PlacesInterface } from '../../interfaces'
import { FormControl, InputLabel } from '@mui/material'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { FinanceDateTimeRange } from '../financeDateTimeRange/FinanceDateTimeRange'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'

type Props = {
  currentCarwashList: string[]
  setCurrentCarwashList: (arg: string[]) => void
  places: PlacesInterface[]
  lastSeenGte: string
  setLastSeenGte: (arg: string) => void
  lastSeenLte: string
  setLastSeenLte: (arg: string) => void
  countCarsType: string
  setCountCarsType: (arg: string) => void
  countCarsAvg: number
  setCountCarsAvg: (arg: number) => void
  onClickHandler: () => void
  dateTimeError: boolean
  toggleDateRangeValue: string
  setToggleDateRangeValue: (arg: string) => void
}

export const FinanceReportFilter = ({
  currentCarwashList,
  setCurrentCarwashList,
  places,
  lastSeenGte,
  setLastSeenLte,
  lastSeenLte,
  setLastSeenGte,
  countCarsAvg,
  setCountCarsType,
  countCarsType,
  setCountCarsAvg,
  onClickHandler,
  dateTimeError,
  toggleDateRangeValue,
  setToggleDateRangeValue,
}: Props) => {
  const carCountListItems = [
    {
      name: 'Датчик',
      value: 'sensor',
    },
    {
      name: 'Средний чек',
      value: 'avg',
    },
  ]
  return (
    <div className={classes.CardContentWrapper}>
      <FormControl
        sx={{
          minWidth: '120px',
          marginRight: '10px',
          marginBottom: '20px',
        }}
        size="small"
      >
        <InputLabel id="outlined-select-organization">Мойка</InputLabel>
        <Select
          id="outlined-select-organization"
          label="Мойка"
          sx={{ width: '215px' }}
          value={currentCarwashList}
          multiple={true}
          renderValue={(selected) =>
            selected
              .map((item) => {
                const currentPlace = places.find((place) => place.id === item)
                return currentPlace ? currentPlace.name : ''
              })
              .join(', ')
          }
          // input={<OutlinedInput label="Tag" />}
          onChange={(e) =>
            setCurrentCarwashList(
              typeof e.target.value === 'string'
                ? [e.target.value]
                : e.target.value
            )
          }
        >
          {places.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              <Checkbox checked={currentCarwashList.indexOf(item.id) > -1} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="outlined"
        color="error"
        sx={{ marginRight: '10px', marginBottom: '20px' }}
        onClick={() => setCurrentCarwashList([])}
      >
        x
      </Button>
      <FinanceDateTimeRange
        startDate={lastSeenGte}
        setStartDate={setLastSeenGte}
        endDate={lastSeenLte}
        setEndDate={setLastSeenLte}
        dateTimeError={dateTimeError}
        toggleDateRangeValue={toggleDateRangeValue}
        setToggleDateRangeValue={setToggleDateRangeValue}
      />
      <FormControl
        sx={{
          minWidth: '120px',
          marginRight: '10px',
          marginBottom: '20px',
        }}
        size="small"
      >
        <InputLabel id="outlined-select-car-count-type">
          Кол-во машин
        </InputLabel>
        <Select
          id="outlined-select-car-count-type"
          label="Кол-во машин"
          sx={{ width: '215px' }}
          value={countCarsType}
          onChange={(e) => setCountCarsType(e.target.value)}
        >
          {carCountListItems.map((item) => (
            <MenuItem key={item.name} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        sx={{
          width: '100px',
          marginRight: '10px',
          marginBottom: '20px',
        }}
      >
        <TextField
          type="number"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', shrink: true }}
          label="Средний чек"
          size="small"
          disabled={countCarsType !== 'avg'}
          value={countCarsAvg}
          onChange={(e) => setCountCarsAvg(Number(e.target.value))}
        />
      </FormControl>
      <FormControl
        sx={{
          marginRight: '10px',
          marginBottom: '20px',
        }}
      >
        <Button
          variant="contained"
          onClick={onClickHandler}
          disabled={dateTimeError || currentCarwashList.length === 0}
        >
          Сформировать
        </Button>
      </FormControl>
    </div>
  )
}
