import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ListProps,
  EditButton,
} from 'react-admin'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../casl/Can'

export const RolesList = (props: ListProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <List
      {...props}
      title="Роли"
      bulkActionButtons={ability.can('delete', 'roles') ? undefined : false}
    >
      <Datagrid rowClick="show">
        <TextField source="name" label="Название" />
        <TextField source="description" label="Описание" />
        <DateField source="createDate" label="Дата создания" />
        <DateField source="updateDate" label="Дата измененния" />
        {ability.can('update', 'roles') && <EditButton />}
      </Datagrid>
    </List>
  )
}
