import React from 'react'
import classes from './WaterDispenserReport.module.sass'
import { Loading, Title, useAuthState } from 'react-admin'
import { Card, CardContent } from '@mui/material'
import { Redirect } from 'react-router'
import useWaterDispenserHook from './hooks/useWaterDispenserHook'
import { ReportFilter } from './components/ReportFilter/ReportFilter'
import { ReportCardGrid } from './components/reportCard/ReportCardGrid'

export const WaterDispenserReport = () => {
  const {
    lastSeenLte,
    setLastSeenLte,
    lastSeenGte,
    setLastSeenGte,
    places,
    currentCarwashList,
    setCurrentCarwashList,
    isLoading,
    dateTimeError,
    reportList,
    getReport,
  } = useWaterDispenserHook()

  /** This hook is secure access to the page */
  const { loading, authenticated } = useAuthState()

  if (loading || !places) {
    return <Loading />
  }
  if (authenticated) {
    return (
      <Card className={classes.Card}>
        <Title title="Отчет по рукомойникам" />
        <CardContent>
          <ReportFilter
            currentCarwashList={currentCarwashList}
            setCurrentCarwashList={setCurrentCarwashList}
            places={places}
            lastSeenGte={lastSeenGte}
            setLastSeenGte={setLastSeenGte}
            lastSeenLte={lastSeenLte}
            setLastSeenLte={setLastSeenLte}
            dateTimeError={dateTimeError}
            onClickHandler={getReport}
          />
          {isLoading ? (
            <Loading />
          ) : reportList.length > 0 ? (
            reportList.map((reportListItem, index) => (
              <ReportCardGrid
                places={places}
                reportListItem={reportListItem}
                key={index}
              />
            ))
          ) : (
            <span>список пуст</span>
          )}
        </CardContent>
      </Card>
    )
  }

  return <Redirect to="/login" />
}
