import React, { useState } from 'react'
import { Create, CreateProps, SimpleForm, TextInput } from 'react-admin'
import { AbilityCheckboxTable } from './components/AbilityCheckboxTable'
import { actionsList, subjectsList, subjectTitles } from './constants'

export const RolesCreate = (props: CreateProps) => {
  const [currentAbilities, setCurrentAbilities] = useState({
    manage: false,
    read: [],
    update: [],
    create: [],
    delete: [],
    see: [],
  })

  const transform = (data: any) => {
    return {
      ...data,
      abilities: actionsList.map((action) => ({
        action,
        // @ts-ignore
        subjects: currentAbilities[action],
      })),
    }
  }

  return (
    <Create {...props} transform={transform}>
      <SimpleForm>
        <TextInput source="name" label="Название роли" />
        <TextInput source="description" label="Описание" />
        <AbilityCheckboxTable
          actionsList={actionsList}
          subjectsList={subjectsList}
          subjectTitles={subjectTitles}
          currentAbilities={currentAbilities}
          setCurrentAbilities={setCurrentAbilities}
        />
      </SimpleForm>
    </Create>
  )
}
