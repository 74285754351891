import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { CustomCheckBox } from './CustomCheckBox'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

export const AbilityCheckboxTable = ({
  actionsList,
  subjectsList,
  subjectTitles,
  currentAbilities,
  setCurrentAbilities,
  record,
}: any) => {
  const classes = useStyles()

  const handleChange = (checked: any, action: any, subject: any) => {
    let ability = currentAbilities[action]
    if (checked) {
      ability.push(subject)
    } else {
      ability = ability.filter((item: any) => item !== subject)
    }
    setCurrentAbilities((prev: any) => ({
      ...prev,
      [action]: ability,
    }))
  }

  useEffect(() => {
    if (!record || !record.abilities) return

    const getAbility = (abilityName: string) => {
      const result = record.abilities.find(
        (item: any) => item.action === abilityName
      )
      return result ? result.subjects : []
    }

    const data = {
      read: getAbility('read'),
      create: getAbility('create'),
      update: getAbility('update'),
      delete: getAbility('delete'),
      see: getAbility('see'),
    }
    setCurrentAbilities({
      manage: false,
      ...data,
    })
  }, [setCurrentAbilities])

  // name="ability" in TableContainer
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center">Чтение</TableCell>
            <TableCell align="center">Создание</TableCell>
            <TableCell align="center">Изменение</TableCell>
            <TableCell align="center">Удаление</TableCell>
            <TableCell align="center">Просмотр</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subjectsList.map((subject: any) => (
            <TableRow key={subject}>
              <TableCell component="th" scope="row">
                {subjectTitles[subject]}
              </TableCell>
              {currentAbilities &&
                actionsList.map((item: any) => (
                  <TableCell align="center">
                    <CustomCheckBox
                      action={item}
                      subject={subject}
                      handleChange={handleChange}
                      initChecked={currentAbilities[item].find(
                        (ability: any) => ability === subject
                      )}
                      key={item}
                    />
                  </TableCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
