import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import CheckIcon from '@mui/icons-material/Check'
import React from 'react'

type GetSumResult = {
  deviceName: string
  sum: number
}

type Props = {
  title: string
  getSumByDeviceName: (carwashId: string) => GetSumResult[]
  carwashId: string
  getCarwashSum: (carwashId: string) => number
}

export const HeaderComponent = ({
  title,
  getSumByDeviceName,
  carwashId,
  getCarwashSum,
}: Props) => {
  const deviceSum = getSumByDeviceName(carwashId)
  return (
    <>
      <div>{title}</div>
      {deviceSum.map((item: any) => (
        <div
          style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}
        >
          <ArrowRightAltIcon fontSize="small" />
          <span>
            {item.deviceName} - {item.sum}₽
          </span>
        </div>
      ))}
      <div
        style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}
      >
        <CheckIcon fontSize="small" />
        <span style={{ fontWeight: 'bold' }}>
          итого - {getCarwashSum(carwashId)}₽
        </span>
      </div>
    </>
  )
}
