import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  BooleanField,
  NumberField,
} from 'react-admin'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../casl/Can'
import CustomNumberField from './components/CustomNumberField'

export const PlaceList = (props: any) => {
  const ability = useAbility(AbilityContext)
  return (
    <List
      {...props}
      title="Список моек"
      bulkActionButtons={ability.can('delete', 'places') ? undefined : false}
    >
      <Datagrid rowClick="show">
        <TextField source="name" label="Название" />
        <TextField source="address" label="Адрес" />
        <TextField source="phoneNumber" label="Номер телефона" />
        <TextField source="operatorPhoneNumber" label="Номер оператора" />
        <CustomNumberField source="maxSpentTime" label="Макс. время на посту" />
        <BooleanField source="showInApp" label="Отображать в приложении" />
        {ability.can('update', 'places') && <EditButton />}
      </Datagrid>
    </List>
  )
}
