import React from 'react'
import classes from './CustomCardSubHeader.module.sass'

import Typography from '@material-ui/core/Typography'

export const CustomCardSubHeader = ({ title, Icon }: any) => {
  return (
    <div className={classes.Container}>
      {Icon && <Icon className={classes.Icon} fontSize="small" />}
      <Typography variant="subtitle1">{title}</Typography>
    </div>
  )
}
