import React from 'react'
import {
  Create,
  CreateProps,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  ImageInput,
  ImageField,
} from 'react-admin'

export const ESCommonConfigCreate = (props: CreateProps) => (
  <Create {...props} title="Создать общую настройку моточасов">
    <SimpleForm>
      <ReferenceInput
        source="carwashId"
        reference="places"
        label="Мойка"
        validate={required()}
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <ImageInput
        source="photo"
        label="Пример фото"
        accept="image/*"
        validate={required()}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
)
