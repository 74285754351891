import React from 'react'
import {
  CreateProps,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  SelectArrayInput,
} from 'react-admin'

export const AdminUsersCreate = (props: CreateProps) => (
  <Create {...props} title="Добавить администратора">
    <SimpleForm>
      <TextInput source="username" label="Имя пользователя" />
      <TextInput source="password" type="password" label="Пароль" />
      <ReferenceInput source="role" reference="roles" label="Роль">
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <TextInput source="name" label="Имя" />
      <ReferenceArrayInput label="Мойки" source="carwash" reference="places">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceInput
        reference="corpOrganizations"
        source="organization"
        label="Организация"
      >
        <SelectInput optionText="name" optionValue="id" allowEmpty={true} />
      </ReferenceInput>
      {/*<TextInput source="organization" label="Организация" />*/}
    </SimpleForm>
  </Create>
)
