import React from 'react'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'

type Props = {
  startDate: string
  setStartDate: (value: string) => void
  endDate: string
  setEndDate: (value: string) => void
  dateTimeError?: boolean
}

export const DateTimeRange = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  dateTimeError = false,
}: Props) => {
  return (
    <Stack
      component="form"
      noValidate
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '20px',
      }}
    >
      <TextField
        id="startDate"
        label="Начиная с"
        type="datetime-local"
        size="small"
        value={startDate}
        onChange={(e) => {
          setStartDate(e.target.value)
        }}
        sx={{ marginRight: '10px' }}
        InputLabelProps={{
          shrink: true,
        }}
        error={dateTimeError}
      />
      <TextField
        id="endDate"
        label="Заканчивая по"
        type="datetime-local"
        size="small"
        value={endDate}
        onChange={(e) => {
          setEndDate(e.target.value)
        }}
        sx={{ marginRight: '10px' }}
        InputLabelProps={{
          shrink: true,
        }}
        error={dateTimeError}
      />
    </Stack>
  )
}
