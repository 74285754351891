import { useEffect, useState } from 'react'
import inMemoryJWT from '../../../Auth/inMemoryJWT'
import { apiUrl } from '../../../helpers/config'

const useChooseDashboardHook = () => {
  const [isCorpUser, setIsCorpUser] = useState<boolean | null>(null)

  /** обновляем токен после обновления страницы вручную,
   * т.к. не вызываем ни одного endpoint через dataProvider
   */
  useEffect(() => {
    const token = inMemoryJWT.getToken()
    if (token) return
    inMemoryJWT.setRefreshTokenEndpoint(`${apiUrl}/refresh/cookie`)
    inMemoryJWT.getRefreshedToken().then()
  }, [])

  const checkCorpUser = () => {
    const profile = localStorage.getItem('profile')
    if (!profile) return
    const { organization } = JSON.parse(profile)
    if (!organization) return setIsCorpUser(false)
    setIsCorpUser(true)
  }

  useEffect(() => {
    setTimeout(checkCorpUser, 100)
  }, [])

  return { isCorpUser }
}

export default useChooseDashboardHook
