import {
  EncashmentDeviceAmountItem,
  EncashmentDevices,
  SortedEncashmentAmount,
} from '../interfaces'
import {
  Data,
  ReportEncashmentAmount,
} from '../../../reports/EncashmentReport/interfaces'

export const getDeviceSum = (list: EncashmentDeviceAmountItem[]): number =>
  list.reduce((prev, item) => {
    return prev + (item.denomination / 100) * item.amount
  }, 0)

export const getPostSum = (list: EncashmentDevices[]): number =>
  list.reduce((prev, item) => {
    return prev + getDeviceSum(item.encashmentAmounts)
  }, 0)

export const getCarwashSum = (
  list: SortedEncashmentAmount[] | null
): number => {
  if (list === null) return 0
  return list.reduce((prev, item) => {
    return prev + getPostSum(item.devices)
  }, 0)
}

export const getCarwashSumForDate = (list: Data): number => {
  if (!list) return 0
  return list.encashments.reduce((prev, item) => {
    return prev + getPostSum(item.devices)
  }, 0)
}

export const getReportCarwashSum = (list: ReportEncashmentAmount): number => {
  if (!list) return 0
  return list.data.reduce((prev, item) => {
    return prev + getCarwashSumForDate(item)
  }, 0)
}

export const getCarwashDeviceSum = (list: Data, deviceName: string): number => {
  if (!list) return 0
  return list.encashments.reduce((prev, item) => {
    return (
      prev +
      item.devices.reduce((prevDevice, deviceItem) => {
        if (deviceItem.deviceName !== deviceName) return prevDevice
        return prevDevice + getDeviceSum(deviceItem.encashmentAmounts)
      }, 0)
    )
  }, 0)
}
