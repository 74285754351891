import {
  Create,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import React from 'react'

export const CorpPaymentsCreate = (props: any) => (
  <Create {...props} title="Добавить платеж">
    <SimpleForm redirect="list">
      <ReferenceInput
        reference="corpOrganizations"
        source="organizationId"
        label="Организация"
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <TextInput
        source="description"
        label="Описание"
        format={(value: string) => (value ? value.trim() : '')}
      />
      <NumberInput source="amount" label="Сумма" />
    </SimpleForm>
  </Create>
)
