import React from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { getTotalAmount } from '../../../functions/getTotalAmount'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import { ReportData } from '../../../interfaces'

type RowProps = {
  data: ReportData[]
  carwashName: string
  carwashId: string
  carwashTotal: number
  carwashCarCounterTotal: number
  countCarsType: string
  countCarsAvg: number
}

export const Row = ({
  data,
  carwashName,
  carwashTotal,
  carwashCarCounterTotal,
  countCarsAvg,
  countCarsType,
}: RowProps) => {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            disabled={data.length === 0}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="right">{carwashName}</TableCell>
        <TableCell align="right">{getTotalAmount(data, 'cash')}</TableCell>
        <TableCell align="right">{getTotalAmount(data, 'card')}</TableCell>
        <TableCell align="right">{getTotalAmount(data, 'app')}</TableCell>
        <TableCell align="right">
          {countCarsType === 'avg'
            ? countCarsAvg > 0
              ? new Intl.NumberFormat('ru-RU').format(
                  Math.trunc(carwashTotal / countCarsAvg)
                )
              : 0
            : new Intl.NumberFormat('ru-RU').format(carwashCarCounterTotal)}
        </TableCell>
        <TableCell align="right">
          {new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'RUB',
          }).format(Math.round(carwashTotal))}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">Номер поста</TableCell>
                    <TableCell align="right">Наличные</TableCell>
                    <TableCell align="right">Карта</TableCell>
                    <TableCell align="right">Приложение</TableCell>
                    <TableCell align="right">Количество машин</TableCell>
                    <TableCell align="right">Итого</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    .sort((a, b) => a.postNumber - b.postNumber)
                    .map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="right">{item.postNumber}</TableCell>
                        <TableCell align="right">
                          {new Intl.NumberFormat('ru-RU', {
                            style: 'currency',
                            currency: 'RUB',
                          }).format(item.amounts.cash)}
                        </TableCell>
                        <TableCell align="right">
                          {new Intl.NumberFormat('ru-RU', {
                            style: 'currency',
                            currency: 'RUB',
                          }).format(item.amounts.card)}
                        </TableCell>
                        <TableCell align="right">
                          {new Intl.NumberFormat('ru-RU', {
                            style: 'currency',
                            currency: 'RUB',
                          }).format(item.amounts.app)}
                        </TableCell>
                        <TableCell align="right">
                          {countCarsType === 'avg'
                            ? countCarsAvg > 0
                              ? new Intl.NumberFormat('ru-RU').format(
                                  Math.trunc(item.total / countCarsAvg)
                                )
                              : 0
                            : new Intl.NumberFormat('ru-RU').format(
                                item.carCounterTotal
                              )}
                        </TableCell>
                        <TableCell align="right">
                          {new Intl.NumberFormat('ru-RU', {
                            style: 'currency',
                            currency: 'RUB',
                          }).format(Math.round(item.total))}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
