import {
  SimpleForm,
  TextInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  CreateProps,
  Create,
  required,
  NumberInput,
  BooleanInput,
} from 'react-admin'
import React from 'react'

export const BotUserCreate = (props: CreateProps) => (
  <Create {...props} title="Создать пользователя">
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="description" validate={required()} />
      <ReferenceArrayInput
        label="Мойки"
        reference="places"
        source="carwash"
        validate={required()}
      >
        <AutocompleteArrayInput />
      </ReferenceArrayInput>
      <TextInput source="key" validate={required()} />
      <BooleanInput source="notifications" label="Уведомления" />
      <BooleanInput source="encashmentNotify" label="Инкассации" />
      <BooleanInput source="equipmentNotify" label="Замена маста" />
      <BooleanInput source="carwashApiNotify" label="Доступность api" />
      <BooleanInput source="carwashDataNotify" label="Выгрузка данных" />
      <NumberInput source="paymentLimit" label="Лимит платежей (день)" />
    </SimpleForm>
  </Create>
)
