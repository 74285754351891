import React from 'react'
import {
  BooleanInput,
  DateTimeInput,
  Edit,
  EditProps,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'

export const TransactionEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="address" label="Адрес" disabled />
      <ReferenceInput label="Клиент" source="userId" reference="users">
        <SelectInput optionText="name" disabled />
      </ReferenceInput>
      <DateTimeInput source="timestamp" label="Дата" disabled />
      <BooleanInput source="special" label="Особый" />
    </SimpleForm>
  </Edit>
)
