import { downloadCSV } from 'react-admin'
import jsonExport from 'jsonexport/dist'

export const transactionExporter = (transactions: any) => {
  const transactionsForExport = transactions.map((transaction: any) => {
    const details = JSON.parse(transaction.details)
    return {
      address: transaction.address,
      discount: transaction.discount,
      amount: details.amount.value,
      incomeAmount: details.income_amount.value,
      timestamp: new Date(transaction.timestamp).toLocaleString('ru-RU'),
    }
  })
  jsonExport(
    transactionsForExport,
    {
      headers: ['address', 'discount', 'amount', 'incomeAmount', 'timestamp'],
      rename: [
        'Адресс',
        'Скидка',
        'Сумма платежа',
        'Сумма платежа с учетом комиссии яндекса',
        'Дата',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, 'transactions')
    }
  )
}
