import {
  Edit,
  EditProps,
  SimpleForm,
  TextInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  required,
  NumberInput,
  BooleanInput,
  ToolbarProps,
  Toolbar,
  SaveButton,
  DeleteButton,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../casl/Can'
import React from 'react'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const CustomToolbar = (props: ToolbarProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      {ability.can('delete', 'botusers') && <DeleteButton />}
    </Toolbar>
  )
}

export const BotUserEdit = (props: EditProps) => {
  return (
    <Edit {...props} title="Редактироваине пользователя">
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput source="id" disabled={true} />
        <TextInput source="name" validate={required()} />
        <TextInput source="description" validate={required()} />
        <ReferenceArrayInput
          label="Мойки"
          reference="places"
          source="carwash"
          validate={required()}
        >
          <AutocompleteArrayInput />
        </ReferenceArrayInput>
        <TextInput source="key" validate={required()} />
        <BooleanInput source="notifications" label="Уведомления" />
        <BooleanInput source="encashmentNotify" label="Инкассации" />
        <BooleanInput source="equipmentNotify" label="Замена маста" />
        <BooleanInput source="carwashApiNotify" label="Доступность api" />
        <BooleanInput source="carwashDataNotify" label="Выгрузка данных" />
        <NumberInput source="paymentLimit" label="Лимит платежей (день)" />
      </SimpleForm>
    </Edit>
  )
}
