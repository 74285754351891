import React from 'react'
import { PostAmountListItem } from './PostAmountListItem'
import { CarwashEncashment } from '../interfaces'

type Props = {
  carwashId: string
  getEncashmentAmountsByCarwashId: (carwashId: string) => CarwashEncashment[]
}

export const CarwashPostAmount = ({
  carwashId,
  getEncashmentAmountsByCarwashId,
}: Props) => {
  const currentAmounts = getEncashmentAmountsByCarwashId(carwashId)

  return (
    <div style={{ padding: '5px 0' }}>
      {currentAmounts.length > 0 ? (
        currentAmounts.map((item) => (
          <PostAmountListItem
            postTitle={item.postTitle}
            postEncashment={item.postEncashment}
            postSum={item.postSum}
            postDeviceList={item.postDeviceList}
          />
        ))
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          нет данных
        </div>
      )}
    </div>
  )
}
