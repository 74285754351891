import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  ShowProps,
} from 'react-admin'
import { actionsTitle, subjectTitles } from './constants'
import Chip from '@material-ui/core/Chip'

export const RolesShow = (props: ShowProps) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" label="Название" />
      <ArrayField source="abilities" label="Возможности">
        <Datagrid>
          <FunctionField
            label="Тип"
            render={
              // @ts-ignore
              (record) => actionsTitle[record.action]
            }
          />
          <FunctionField
            label="Ресурс"
            render={
              // @ts-ignore
              (record) =>
                record.subjects.map((item: any) => (
                  <Chip
                    // @ts-ignore
                    label={subjectTitles[item]}
                    color="primary"
                    style={{ margin: '0.2em' }}
                  />
                ))
            }
          />
        </Datagrid>
      </ArrayField>
      <TextField source="description" label="Описание" />
      <DateField source="createDate" label="Дата создания" />
      <DateField source="updateDate" label="Дата изменения" />
    </SimpleShowLayout>
  </Show>
)
