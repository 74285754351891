import {
  BooleanField,
  NumberField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from 'react-admin'
import React from 'react'

export const CorpOrganizationsShow = (props: ShowProps) => (
  <Show {...props} title="Просмотр организации">
    <SimpleShowLayout>
      <TextField source="name" label="Название" />
      <BooleanField source="isEnabled" label="Активна" />
      <NumberField source="creditLimit" label="Кредитный лимит" />
      <NumberField source="balance" label="Баланс" />
    </SimpleShowLayout>
  </Show>
)
