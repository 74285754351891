export const parseDate = (date: number): string => {
  const currentDate = new Date(date)

  const addZero = (value: number): string => {
    return value < 10 ? '0' + value : String(value)
  }

  const day = currentDate.getDate()
  const month = currentDate.getMonth() + 1
  const fullYear = currentDate.getFullYear()
  const hours = currentDate.getHours()
  const minutes = currentDate.getMinutes()

  return (
    fullYear +
    '-' +
    addZero(month) +
    '-' +
    addZero(day) +
    'T' +
    addZero(hours) +
    ':' +
    addZero(minutes)
  )
}
