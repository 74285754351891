import {
  BooleanField,
  ChipField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
} from 'react-admin'
import React from 'react'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../../casl/Can'

export const CorpUsersShow = (props: ShowProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <Show {...props} title="Просмотр корп. пользователя">
      <SimpleShowLayout>
        <TextField source="firstName" label="Имя" />
        <TextField source="lastName" label="Фамилия" />
        <ReferenceArrayField label="Мойки" reference="places" source="carwash">
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <NumberField source="dayLimit" label="Лимит (день)" />
        <NumberField source="monthLimit" label="Лимит (месяц)" />
        {ability.can('read', 'corpOrganizations') && (
          <ReferenceField
            reference="corpOrganizations"
            source="organizationId"
            label="Организация"
            linkType="show"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        <BooleanField source="isPaymentEnabled" label="Платежи включены" />
        <TextField source="tgBotKey" label="Ключ для бота" />
      </SimpleShowLayout>
    </Show>
  )
}
