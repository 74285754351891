import React, { useState } from 'react'
import { InputProps, Loading, useNotify } from 'react-admin'
import axios from 'axios'
import { apiUrl } from '../../helpers/config'
import inMemoryJWT from '../../Auth/inMemoryJWT'

import Button from '@material-ui/core/Button'
import CloudQueueIcon from '@material-ui/icons/CloudQueue'

import { AvailablePostItem } from './components/AvailablePostItem'

export const AvailablePosts = ({ record }: InputProps) => {
  const [postStatus, setPostStatus] = useState(null)
  const [loading, setLoading] = useState(false)
  const notify = useNotify()

  const onClickHandler = async () => {
    setLoading(true)
    try {
      const response = await axios.post(
        `${apiUrl}/admin/places/checkAvailablePosts`,
        {
          washId: record.id,
          baseUrl: record.baseUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${inMemoryJWT.getToken()}`,
          },
        }
      )
      setLoading(false)
      const data = response.data.filter((item: any) => !!item)
      const sortedData = data.sort((a: any, b: any) => {
        const intA = parseInt(a.title.split('').splice(4, 3).join(''))
        const intB = parseInt(b.title.split('').splice(4, 3).join(''))
        return intA - intB
      })
      setPostStatus(sortedData)
    } catch (error) {
      setLoading(false)
      // @ts-ignore
      notify(error.response.data.msg)
    }
  }
  if (loading)
    return (
      <div style={{ padding: 50 }}>
        <Loading />
      </div>
    )
  return (
    <div style={{ width: '100%', padding: 30 }}>
      {postStatus && <AvailablePostItem postList={postStatus} />}
      <Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={<CloudQueueIcon />}
        onClick={onClickHandler}
      >
        {postStatus ? 'Обновить' : 'Проверить'}
      </Button>
    </div>
  )
}
