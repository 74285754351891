import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { ProgramReportInterface } from '../../interfaces'
import { Row } from './components/Row'

type Props = {
  report: ProgramReportInterface[]
}

export const ProgramReportTable = ({ report }: Props) => {
  return (
    <>
      {report
        .sort((a, b) => {
          const nameA = a.carwashName.toUpperCase()
          const nameB = b.carwashName.toUpperCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
        .map((item) => (
          <TableContainer component={Paper} style={{ marginBottom: '30px' }}>
            <Table size="small" aria-label="collapsible a dense table">
              <TableHead>
                <TableRow>
                  {/*<TableCell />*/}
                  <TableCell sx={{ width: '20%' }}>
                    {item.carwashName}
                  </TableCell>
                  <TableCell align="right" sx={{ width: '40%' }}>
                    Программа
                  </TableCell>
                  <TableCell align="right" sx={{ width: '40%' }}>
                    Итого
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item.data.map((dataItem) => (
                  <Row key={item.carwashId} data={dataItem} />
                ))}
                <TableRow>
                  <TableCell />
                  <TableCell align="right" style={{ fontWeight: 'bold' }}>
                    Итого:
                  </TableCell>
                  <TableCell align="right" style={{ fontWeight: 'bold' }}>
                    {new Intl.NumberFormat('ru-RU', {
                      style: 'currency',
                      currency: 'RUB',
                    }).format(Math.round(item.carwashTotal))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ))}
    </>
  )
}
