import React, { cloneElement } from 'react'
import {
  ReferenceField,
  TextField,
  NumberField,
  SingleFieldList,
  SelectField,
  ArrayField,
  EditButton,
  DateField,
  BooleanField,
  FunctionField,
  Show,
  ShowProps,
  SimpleShowLayout,
  ImageField,
} from 'react-admin'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../../casl/Can'
import classes from '../esCommonConfig/ESCommonConfigList.module.sass'

const StringToLabelObject = ({ record, children, ...rest }: any) =>
  cloneElement(children, {
    record: { label: record },
    ...rest,
  })

export const EquipmentServiceListShow = (props: ShowProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <Show {...props} title="История обслуживания">
      <SimpleShowLayout>
        <ReferenceField source="carwashId" reference="places" label="Мойка">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="postTitle" label="Пост" />
        <ArrayField source="deviceNames" label="Устройства">
          <SingleFieldList
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '10px 0',
            }}
            linkType={false}
          >
            <StringToLabelObject>
              <SelectField
                source="label"
                choices={[
                  { id: 'АВД1', name: 'АВД1' },
                  { id: 'АВД2', name: 'АВД2' },
                ]}
              />
            </StringToLabelObject>
          </SingleFieldList>
        </ArrayField>
        <FunctionField
          label="Наработка с последнего сброса (часы)"
          textAlign="right"
          render={(record: any) =>
            `${
              record.machineHours
                ? Math.round(record.machineHours / 60 / 60)
                : '-'
            }`
          }
        />
        <NumberField
          source="equipmentServiceInterval"
          label="Сервисный интервал на момент сброса (часы)"
        />
        <TextField source="employee" label="Сотрудник" />
        <ImageField source="photo" label="Фото" />
        <BooleanField source="isConfirmed" label="Подтвержден" />
        <DateField source="timestamp" label="Дата" showTime={true} />
        {/*{ability.can('update', 'whEvents') && <EditButton />}*/}
      </SimpleShowLayout>
    </Show>
  )
}
