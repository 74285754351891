import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@material-ui/core'
import { MenuItemLink, MenuProps, Menu } from 'react-admin'
import DashboardIcon from '@material-ui/icons/Dashboard'
import SubMenu from './SubMenu'

import UserIcon from '@material-ui/icons/Group'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import SignalCellularNullIcon from '@material-ui/icons/SignalCellularNull'
import RoomIcon from '@material-ui/icons/Room'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import AllOutIcon from '@material-ui/icons/AllOut'
import AssessmentIcon from '@material-ui/icons/Assessment'
import VisibilityIcon from '@material-ui/icons/Visibility'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber'
import HistoryIcon from '@material-ui/icons/History'
import BallotIcon from '@material-ui/icons/Ballot'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import BlurCircularIcon from '@material-ui/icons/BlurCircular'
import DeviceHubIcon from '@material-ui/icons/DeviceHub'
import CompareArrows from '@material-ui/icons/CompareArrows'
import TelegramIcon from '@mui/icons-material/Telegram'
import SubscriptionsIcon from '@mui/icons-material/Subscriptions'
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import HubIcon from '@mui/icons-material/Hub'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PaymentsIcon from '@mui/icons-material/Payments'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import EventIcon from '@mui/icons-material/Event'
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet'
import HandymanIcon from '@mui/icons-material/Handyman'
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList'
import GridViewIcon from '@mui/icons-material/GridView'
import SummarizeIcon from '@mui/icons-material/Summarize'
import WashIcon from '@mui/icons-material/Wash'

import { useAbility } from '@casl/react'
import { AbilityContext } from '../casl/Can'

const MyMenu = ({
  onMenuClick,
  logout,
  dense = false,
  ...props
}: MenuProps) => {
  const ability = useAbility(AbilityContext)

  // @ts-ignore
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  // @ts-ignore
  const open = useSelector((state) => state.admin.ui.sidebarOpen)
  const [toggleSubMenu, setToggleSubMenu] = useState({
    bonusMenu: false,
    paymentMenu: false,
    reports: false,
    // menuBonuses: false,
    techInfo: false,
    promo: false,
    carCounter: false,
    encashment: false,
    telegramBot: false,
    corpOffice: false,
    whEvents: false,
  })
  const handleToggle = (menuName: any) => {
    // @ts-ignore
    setToggleSubMenu((prev) => ({ ...prev, [menuName]: !prev[menuName] }))
  }
  let profile: any = localStorage.getItem('profile')
  if (profile) profile = JSON.parse(profile)
  //style={{ paddingTop: '1.5em' }}
  return (
    <Menu {...props}>
      {/*<DashboardMenuItem />*/}
      <MenuItemLink
        exact={true}
        to="/"
        primaryText="Главная"
        leftIcon={<DashboardIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />

      {(ability.can('see', 'corpOrganizations') ||
        ability.can('see', 'corpUsers')) && (
        <SubMenu
          handleToggle={() => handleToggle('corpOffice')}
          isOpen={toggleSubMenu.corpOffice}
          sidebarIsOpen={open}
          name="Корп. кабинет"
          icon={<CorporateFareIcon />}
          dense={dense}
        >
          {ability.can('see', 'corpOrganizations') && (
            <MenuItemLink
              to="/corpOrganizations"
              primaryText="Организации"
              leftIcon={<CorporateFareIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {ability.can('see', 'corpUsers') && (
            <MenuItemLink
              to="/corpUsers"
              primaryText="Корп. пользователи"
              leftIcon={<PeopleAltIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {ability.can('see', 'corpPayments') && (
            <MenuItemLink
              to="/corpReport"
              primaryText="Отчет"
              leftIcon={<SummarizeIcon />}
              onClick={onMenuClick}
            />
          )}
          {ability.can('see', 'corpPayments') && (
            <MenuItemLink
              to="/corpPayments"
              primaryText="Платежи"
              leftIcon={<PaymentsIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
        </SubMenu>
      )}
      {ability.can('see', 'adminUsers') && (
        <MenuItemLink
          to="/auth"
          primaryText="Администраторы"
          leftIcon={<AdminPanelSettingsIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {ability.can('see', 'users') && (
        <MenuItemLink
          to="/users"
          primaryText="Пользователи"
          leftIcon={<UserIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {ability.can('see', 'bonus') && (
        <SubMenu
          handleToggle={() => handleToggle('bonusMenu')}
          isOpen={toggleSubMenu.bonusMenu}
          sidebarIsOpen={open}
          name="Бонусы"
          icon={<ShoppingBasketIcon />}
          dense={dense}
        >
          <MenuItemLink
            to="/bonus"
            primaryText="Бонусы"
            leftIcon={<ShoppingBasketIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        </SubMenu>
      )}
      {ability.can('see', 'promoCode') && (
        <SubMenu
          handleToggle={() => handleToggle('promo')}
          isOpen={toggleSubMenu.promo}
          sidebarIsOpen={open}
          name="Промокоды"
          icon={<ConfirmationNumberIcon />}
          dense={dense}
        >
          <MenuItemLink
            to="/promoCode"
            primaryText="Промокоды"
            leftIcon={<ConfirmationNumberIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/promoCodeHistory"
            primaryText="История"
            leftIcon={<HistoryIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        </SubMenu>
      )}
      {ability.can('see', 'finance') && (
        <SubMenu
          handleToggle={() => handleToggle('paymentMenu')}
          isOpen={toggleSubMenu.paymentMenu}
          sidebarIsOpen={open}
          name="Финансы"
          icon={<AttachMoneyIcon />}
          dense={dense}
        >
          <MenuItemLink
            to="/transactions"
            primaryText="Моб. приложение"
            leftIcon={<PhoneIphoneIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/carwash-stat/payment"
            primaryText="Оффлайн платежи"
            leftIcon={<AttachMoneyIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        </SubMenu>
      )}
      {ability.can('see', 'events') && (
        <MenuItemLink
          to="/carwash-stat/event"
          primaryText="События"
          leftIcon={<AllOutIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {ability.can('see', 'encashment') && (
        <MenuItemLink
          to="/encashment"
          primaryText="Инкассации"
          leftIcon={<CompareArrows />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {ability.can('see', 'reports') && (
        <SubMenu
          handleToggle={() => handleToggle('reports')}
          isOpen={toggleSubMenu.reports}
          sidebarIsOpen={open}
          name="Отчеты"
          icon={<AssessmentIcon />}
          dense={dense}
        >
          {ability.can('read', 'finance') && (
            <MenuItemLink
              to="/financeReport"
              primaryText="Продажи"
              leftIcon={<AssessmentIcon />}
              onClick={onMenuClick}
            />
          )}
          {ability.can('read', 'finance') && (
            <MenuItemLink
              to="/programReport"
              primaryText="Программы"
              leftIcon={<AssessmentIcon />}
              onClick={onMenuClick}
            />
          )}
          {ability.can('read', 'encashment') && (
            <MenuItemLink
              to="/encashmentReport"
              primaryText="Инкассации"
              leftIcon={<CompareArrows />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {ability.can('see', 'carCounter') && (
            <MenuItemLink
              to="/carCounterReport"
              primaryText="Кол-во машин"
              leftIcon={<SummarizeIcon />}
              onClick={onMenuClick}
            />
          )}
          {ability.can('see', 'carCounter') && (
            <MenuItemLink
              to="/waterDispenserReport"
              primaryText="Рукомойник"
              leftIcon={<WashIcon />}
              onClick={onMenuClick}
            />
          )}
        </SubMenu>
      )}
      {ability.can('see', 'discountLevels') && (
        <MenuItemLink
          to="/discountLevels"
          primaryText="Уровни"
          leftIcon={<SignalCellularNullIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {ability.can('see', 'places') && (
        <MenuItemLink
          to="/places"
          primaryText="Мойки"
          leftIcon={<RoomIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {/*{ability.can('see', 'owen') && (*/}
      {/*  <SubMenu*/}
      {/*    handleToggle={() => handleToggle('techInfo')}*/}
      {/*    isOpen={toggleSubMenu.techInfo}*/}
      {/*    sidebarIsOpen={open}*/}
      {/*    name="Тех. мониторинг"*/}
      {/*    icon={<VisibilityIcon />}*/}
      {/*    dense={dense}*/}
      {/*  >*/}
      {/*    <MenuItemLink*/}
      {/*      to="/techInfo"*/}
      {/*      primaryText="Просмотр"*/}
      {/*      leftIcon={<VisibilityIcon />}*/}
      {/*      onClick={onMenuClick}*/}
      {/*      sidebarIsOpen={open}*/}
      {/*    />*/}
      {/*    <MenuItemLink*/}
      {/*      to="/owen/accounts"*/}
      {/*      primaryText="Настройки"*/}
      {/*      leftIcon={<AccountTreeIcon />}*/}
      {/*    />*/}
      {/*  </SubMenu>*/}
      {/*)}*/}
      {ability.can('read', 'roles') && (
        <MenuItemLink
          to="/roles"
          primaryText="Роли"
          leftIcon={<DeviceHubIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {ability.can('see', 'botusers') && (
        <SubMenu
          handleToggle={() => handleToggle('telegramBot')}
          isOpen={toggleSubMenu.telegramBot}
          sidebarIsOpen={open}
          name="Telegram bot"
          icon={<TelegramIcon />}
          dense={dense}
        >
          <MenuItemLink
            to="/botUsers"
            primaryText="Пользователи"
            leftIcon={<TelegramIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/botActiveUsers"
            primaryText="Подписки"
            leftIcon={<SubscriptionsIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/botHistory"
            primaryText="История"
            leftIcon={<HistoryToggleOffIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        </SubMenu>
      )}
      {ability.can('see', 'logs') && (
        <MenuItemLink
          to="/carwashLogs"
          primaryText="Логи"
          leftIcon={<BallotIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {ability.can('see', 'carCounter') && (
        <SubMenu
          handleToggle={() => handleToggle('carCounter')}
          isOpen={toggleSubMenu.carCounter}
          sidebarIsOpen={open}
          name="Счетчик машин"
          icon={<BlurCircularIcon />}
          dense={dense}
        >
          <MenuItemLink
            to="/carCounterDashboard"
            primaryText="Dashboard"
            leftIcon={<GridViewIcon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to="/carCounterDevice"
            primaryText="Устройства"
            leftIcon={<BlurCircularIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/carCounterList"
            primaryText="История (м)"
            leftIcon={<FormatListBulletedIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/waterDispenserList"
            primaryText="История (р)"
            leftIcon={<FormatListBulletedIcon />}
            onClick={onMenuClick}
          />
          {/*<MenuItemLink*/}
          {/*  to="/owen/accounts"*/}
          {/*  primaryText="Настройки"*/}
          {/*  leftIcon={<AccountTreeIcon />}*/}
          {/*  sidebarIsOpen={open}*/}
          {/*/>*/}
        </SubMenu>
      )}
      {ability.can('see', 'carwashStatus') && (
        <MenuItemLink
          to="/carwashStatus"
          primaryText="Статус постов"
          leftIcon={<HubIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {ability.can('see', 'whEvents') && (
        <SubMenu
          handleToggle={() => handleToggle('whEvents')}
          isOpen={toggleSubMenu.whEvents}
          sidebarIsOpen={open}
          name="Моточасы"
          icon={<HourglassEmptyIcon />}
          dense={dense}
        >
          <MenuItemLink
            to="/machineHoursSummary"
            primaryText="Моточасы"
            leftIcon={<HourglassEmptyIcon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to="/machineHours"
            primaryText="Список по дням"
            leftIcon={<FeaturedPlayListIcon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to="/whEventsAdmin"
            primaryText="События"
            leftIcon={<EventIcon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to="/equipmentServiceList"
            primaryText="История обслуживания"
            leftIcon={<HandymanIcon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to="/es-common-config"
            primaryText="Общие настройки"
            leftIcon={<SettingsEthernetIcon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to="/equipmentServiceConfig"
            primaryText="Настройки постов"
            leftIcon={<SettingsEthernetIcon />}
            onClick={onMenuClick}
          />
        </SubMenu>
      )}
      {ability.can('see', 'specialPayment') && (
        <MenuItemLink
          to="/specialPaymentsConfig"
          primaryText="Особые платежи"
          leftIcon={<AddShoppingCartIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {isXSmall && logout}
    </Menu>
  )
}

export default MyMenu
