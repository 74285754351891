import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import React from 'react'

type Props = {
  title: string
  value: string | number
  subtitle?: string
  description?: string
  isRed?: boolean
}

export const ReportCardItem = ({
  title,
  value,
  subtitle,
  description,
  isRed = false,
}: Props) => (
  <Card
    sx={{
      width: 200,
      marginBottom: '20px',
      marginRight: '20px',
      backgroundColor: isRed ? 'rgba(255, 0, 0, 0.4)' : 'transparent',
    }}
  >
    <CardContent>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h5" component="div">
        {value}
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        {subtitle}
      </Typography>
      <Typography variant="body2">{description}</Typography>
    </CardContent>
  </Card>
)
