import React from 'react'
import {
  DeleteButton,
  Edit,
  EditProps,
  NumberInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  Toolbar,
  ToolbarProps,
} from 'react-admin'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../../casl/Can'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const CustomToolbar = (props: ToolbarProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      {ability.can('delete', 'whEvents') && <DeleteButton />}
    </Toolbar>
  )
}

export const EquipmentServiceConfigEdit = (props: EditProps) => (
  <Edit {...props} title="Изменить настройки моточасов">
    <SimpleForm toolbar={<CustomToolbar />}>
      <ReferenceInput
        source="carwashId"
        reference="places"
        label="Мойка"
        validate={required()}
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <SelectInput
        source="postTitle"
        label="Пост"
        choices={[
          { id: 'Пост 0', name: 'Пост 0' },
          { id: 'Пост 1', name: 'Пост 1' },
          { id: 'Пост 2', name: 'Пост 2' },
          { id: 'Пост 3', name: 'Пост 3' },
          { id: 'Пост 4', name: 'Пост 4' },
          { id: 'Пост 5', name: 'Пост 5' },
          { id: 'Пост 6', name: 'Пост 6' },
          { id: 'Пост 7', name: 'Пост 7' },
          { id: 'Пост 8', name: 'Пост 8' },
        ]}
        validate={required()}
      />
      <SelectArrayInput
        source="deviceNames"
        label="Устройства"
        choices={[
          { id: 'АВД1', name: 'АВД1' },
          { id: 'АВД2', name: 'АВД2' },
        ]}
        validate={required()}
      />
      <NumberInput
        source="serviceInterval"
        label="Сервисный интервал (часы)"
        validate={required()}
      />
    </SimpleForm>
  </Edit>
)
