import React from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'

import InvertColorsIcon from '@material-ui/icons/InvertColors'
import FormatColorResetIcon from '@material-ui/icons/FormatColorReset'
import { InputProps } from 'react-admin'

export const PostField = ({ record }: InputProps) => (
  <List>
    {record.posts.map((item: any, index: number) => (
      <ListItem key={item + index}>
        <ListItemAvatar>
          <Avatar
            style={
              item && item.length > 0 ? { backgroundColor: '#2196f3' } : {}
            }
          >
            {item && item.length > 0 ? (
              <InvertColorsIcon />
            ) : (
              <FormatColorResetIcon />
            )}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={`Пост № ${index + 1}`} secondary={item} />
      </ListItem>
    ))}
  </List>
)
