import { CarCounterInterface } from '../interfaces'

type Props = {
  carCounterList: CarCounterInterface[]
  timeLimit: number
}

export const countExceedingLimit = ({
  carCounterList,
  timeLimit,
}: Props): number => {
  if (timeLimit === 0) return 0
  const exceedingLimitList = carCounterList.filter((item) => {
    // console.log('spent time: ', item.spentTime)
    // console.log('time limit: ', timeLimit)
    return item.spentTime > timeLimit / 1000
  })
  return exceedingLimitList.length
}
