import { CarCounterInterface } from '../interfaces'

type Props = {
  carCounterList: CarCounterInterface[]
}

export const getMaxTime = ({ carCounterList }: Props): number => {
  if (carCounterList.length === 0) return 0
  const spentTimeList = carCounterList.map((item) => item.spentTime)
  return Math.max(...spentTimeList)
}
