import React, { cloneElement } from 'react'
import {
  ListProps,
  List,
  Datagrid,
  ReferenceField,
  TextField,
  NumberField,
  SingleFieldList,
  SelectField,
  ArrayField,
  EditButton,
} from 'react-admin'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../../casl/Can'

const StringToLabelObject = ({ record, children, ...rest }: any) =>
  cloneElement(children, {
    record: { label: record },
    ...rest,
  })

export const EquipmentServiceConfigList = (props: ListProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <List
      {...props}
      bulkActionButtons={ability.can('delete', 'whEvents') ? undefined : false}
      title="Настройка моточасов"
    >
      <Datagrid
        rowClick={ability.can('update', 'whEvents') ? 'edit' : undefined}
      >
        <ReferenceField source="carwashId" reference="places" label="Мойка">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="postTitle" label="Пост" />
        <ArrayField source="deviceNames" label="Устройства">
          <SingleFieldList
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '10px 0',
            }}
            linkType={false}
          >
            <StringToLabelObject>
              <SelectField
                source="label"
                choices={[
                  { id: 'АВД1', name: 'АВД1' },
                  { id: 'АВД2', name: 'АВД2' },
                ]}
              />
            </StringToLabelObject>
          </SingleFieldList>
        </ArrayField>
        <NumberField
          source="serviceInterval"
          label="Cервисный интервал (часы)"
        />
        {ability.can('update', 'whEvents') && <EditButton />}
      </Datagrid>
    </List>
  )
}
