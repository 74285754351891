import React from 'react'
import { Loading, Title, useAuthState } from 'react-admin'
import { Redirect } from 'react-router'
import { Card, CardContent } from '@mui/material'
import useFinanceReportHook from './hooks/useFinanceReportHook'
import { FinanceReportFilter } from './components/FinanceReportFilter/FinanceReportFilter'
import { FinanceReportTable } from './components/FinanceReportTable/FinanceReportTable'

export const FinanceReport = () => {
  const {
    places,
    isLoading,
    currentCarwashList,
    setCurrentCarwashList,
    setLastSeenGte,
    setLastSeenLte,
    lastSeenGte,
    lastSeenLte,
    countCarsType,
    setCountCarsType,
    countCarsAvg,
    setCountCarsAvg,
    financeReport,
    getReport,
    dateTimeError,
    toggleDateRangeValue,
    setToggleDateRangeValue,
  } = useFinanceReportHook()
  /** This hook is secure access to the page */
  const { loading, authenticated } = useAuthState()

  if (loading || !places) {
    return <Loading />
  }
  if (authenticated) {
    return (
      <Card sx={{ marginTop: '20px' }}>
        <Title title="Отчет по продажам" />
        <CardContent>
          <FinanceReportFilter
            currentCarwashList={currentCarwashList}
            setCurrentCarwashList={setCurrentCarwashList}
            places={places}
            lastSeenGte={lastSeenGte}
            setLastSeenGte={setLastSeenGte}
            lastSeenLte={lastSeenLte}
            setLastSeenLte={setLastSeenLte}
            countCarsType={countCarsType}
            setCountCarsType={setCountCarsType}
            countCarsAvg={countCarsAvg}
            setCountCarsAvg={setCountCarsAvg}
            onClickHandler={getReport}
            dateTimeError={dateTimeError}
            toggleDateRangeValue={toggleDateRangeValue}
            setToggleDateRangeValue={setToggleDateRangeValue}
          />
          {isLoading ? (
            <Loading />
          ) : financeReport.length > 0 ? (
            <FinanceReportTable
              report={financeReport}
              countCarsAvg={countCarsAvg}
              countCarsType={countCarsType}
            />
          ) : (
            <div>список пуст</div>
          )}
        </CardContent>
      </Card>
    )
  }

  return <Redirect to="/login" />
}
