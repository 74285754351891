import React from 'react'
import {
  Datagrid,
  DateField,
  DateTimeInput,
  List,
  NumberField,
  ReferenceField,
  TextField,
} from 'react-admin'
import { EventDescription } from './components/EventDescription'
import { EventAmountField } from './components/EventAmountField'
import { CustomPagination } from '../../components/CustomPagination/CustomPagination'
import { CarwashDatePostFilter } from '../../components/CarwashDatePostFilter/CarwashDatePostFilter'

const EventFilters = [
  <DateTimeInput label="начиная с" source="last_seen_gte" alwaysOn />,
  <DateTimeInput label="заканчивая по" source="last_seen_lte" alwaysOn />,
]

export const EventList = (props: any) => (
  <List
    {...props}
    title="События"
    sort={{ field: 'dateTime', order: 'DESC' }}
    bulkActionButtons={false}
    aside={<CarwashDatePostFilter />}
    pagination={<CustomPagination />}
    filters={EventFilters}
  >
    <Datagrid>
      <ReferenceField
        source="carwashId"
        reference="places"
        label="Мойка"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="eventType.description" label="Событие" />
      <EventDescription label={'Описание'} source="" />
      <EventAmountField label="Сумма" source="" />
      <TextField source="errorCodeDescription" label="Описание ошибки" />
      <NumberField source="postNumber" label="Номер поста" />
      <DateField source="dateTime" label="Дата" showTime={true} />
    </Datagrid>
  </List>
)
