import * as React from 'react'
import { Route } from 'react-router-dom'
import { TechInfo } from './containers/TechInfo/TechInfo'
import { EncashmentReport } from './containers/reports/EncashmentReport/EncashmentReport'
import { CarwashStatus } from './containers/carwashStatus/CarwashStatus'
import { MachineHoursSummary } from './containers/MachineHoursSummary/MachineHoursSummary'
import { CarCounterDashboard } from './containers/carCounter/carCounterDashboard/CarCounterDashboard'
import { CorpReport } from './containers/corporateOffice/corpReport/CorpReport'
import { CarCounterReport } from './containers/carCounter/carCounterReport/CarCounterReport'
import { FinanceReport } from './containers/reports/FinanceReport/FinanceReport'
import { WaterDispenserReport } from './containers/carCounter/waterDispenserReport/WaterDispenserReport'
import { ProgramReport } from './containers/reports/ProgramReport/ProgramReport'

export default [
  <Route path="/encashmentReport" component={EncashmentReport} />,
  <Route path="/financeReport" component={FinanceReport} />,
  <Route path="/techInfo" component={TechInfo} />,
  <Route path="/carwashStatus" component={CarwashStatus} />,
  <Route path="/machineHoursSummary" component={MachineHoursSummary} />,
  <Route path="/carCounterDashboard" component={CarCounterDashboard} />,
  <Route path="/corpReport" component={CorpReport} />,
  <Route path="/carCounterReport" component={CarCounterReport} />,
  <Route path="/waterDispenserReport" component={WaterDispenserReport} />,
  <Route path="/programReport" component={ProgramReport} />,
]
