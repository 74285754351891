import { CarCounterInterface } from '../interfaces'

type Props = {
  carCounterList: CarCounterInterface[]
}

export const getAverageTime = ({ carCounterList }: Props): number => {
  if (carCounterList.length === 0) return 0
  const totalSpentTime = carCounterList.reduce(
    (prev, item) => prev + item.spentTime,
    0
  )
  return Math.round(totalSpentTime / carCounterList.length)
}
