import React, { useState } from 'react'
import Checkbox from '@material-ui/core/Checkbox'

export const CustomCheckBox = ({
  action,
  subject,
  handleChange,
  initChecked = false,
}: any) => {
  const [checked, setChecked] = useState(initChecked)

  const onPressHandler = () => {
    handleChange(!checked, action, subject)
    setChecked((prev: any) => !prev)
  }
  return <Checkbox name={action} checked={checked} onChange={onPressHandler} />
}
