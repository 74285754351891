import React from 'react'
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  TextField,
} from 'react-admin'
import { SpentTimeField } from './components/SpentTimeField'
import { CarwashDatePostFilter } from '../../../components/CarwashDatePostFilter/CarwashDatePostFilter'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../../casl/Can'
import { CustomPagination } from '../../../components/CustomPagination/CustomPagination'

export const CarCounterList = (props: any) => {
  const ability = useAbility(AbilityContext)
  return (
    <List
      {...props}
      title="Счетчик машин - список"
      bulkActionButtons={
        ability.can('delete', 'carCounter') ? undefined : false
      }
      sort={{ field: 'checkoutTime', order: 'DESC' }}
      pagination={<CustomPagination />}
      aside={<CarwashDatePostFilter />}
    >
      <Datagrid>
        <ReferenceField source="carwashId" reference="places" label="Мойка ">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="postNumber" label="Номер поста" />
        <NumberField source="currentDistance" label="Текущие показания (см)" />
        <NumberField
          source="distanceThreshold"
          label="Порог срабатывания (см)"
        />
        <DateField
          source="checkinTime"
          label="Время заезда"
          showTime={true}
          textAlign="right"
        />
        <DateField
          source="checkoutTime"
          label="Время выезда"
          showTime={true}
          textAlign="right"
        />
        <SpentTimeField label="Затраченное время" textAlign="right" source="" />
      </Datagrid>
    </List>
  )
}
