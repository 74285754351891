import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  EditProps,
  ToolbarProps,
  Toolbar,
  SaveButton,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const CustomToolbar = (props: ToolbarProps) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
  </Toolbar>
)

export const DiscountlevelEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput source="id" />
      <ArrayInput source="levels" label="Уровни">
        <SimpleFormIterator>
          <TextInput source="name" label="Название уровня" />
          <NumberInput source="visits" label="Посещений для достижения" />
          <NumberInput source="discount" label="Значение скидки" />
          <TextInput source="description" label="Описание" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
)
