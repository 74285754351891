import { WaterDispenserInterface } from '../interfaces'

type Props = {
  waterDispenserList: WaterDispenserInterface[]
}

export const getAverageTime = ({ waterDispenserList }: Props): number => {
  if (waterDispenserList.length === 0) return 0
  const totalSpentTime = waterDispenserList.reduce(
    (prev, item) => prev + item.spentTime,
    0
  )
  return Math.round(totalSpentTime / waterDispenserList.length)
}
