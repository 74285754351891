export const actionsList = ['read', 'create', 'update', 'delete', 'see']
export const actionsTitle = {
  read: 'чтение',
  create: 'создание',
  update: 'изменение',
  delete: 'удаление',
  see: 'просмотр',
}
export const subjectsList = [
  'adminUsers',
  'users',
  'bonus',
  'promoCode',
  'finance',
  'events',
  'reports',
  'discountLevels',
  'places',
  'owen',
  'logs',
  'carCounter',
  'roles',
  'encashment',
  'botusers',
  'carwashStatus',
  'corpOrganizations',
  'corpUsers',
  'corpPayments',
  'whEvents',
  'specialPayment',
]
export const subjectTitles = {
  adminUsers: 'администраторы',
  users: 'пользователи',
  bonus: 'бонусы',
  promoCode: 'промо коды',
  finance: 'финансы',
  events: 'события',
  reports: 'отчеты',
  discountLevels: 'уровни',
  places: 'мойки',
  owen: 'тех. мониторинг',
  logs: 'логи',
  carCounter: 'счетчик машин',
  roles: 'роли',
  encashment: 'инкассации',
  botusers: 'telegramBot',
  carwashStatus: 'статус постов',
  corpOrganizations: 'организации',
  corpUsers: 'корп. пользователи',
  corpPayments: 'копр. платежи',
  whEvents: 'моточасы',
  specialPayment: 'особый платеж',
}
