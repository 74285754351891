import React from 'react'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { parseDate } from '../../../EncashmentReport/components/StepContent/functions/parseDate'
import {
  getEndLast30Days,
  getEndOfCurrentMonth,
  getEndOfPrevDay,
  getEndOfPrevMonth,
  getStartLast30Days,
  getStartOfCurrentMonth,
  getStartOfPrevDay,
  getStartOfPrevMonth,
} from '../../functions/dateFormatter'
import classes from './FinanceDateTimeRenge.module.sass'

type Props = {
  startDate: string
  setStartDate: (value: string) => void
  endDate: string
  setEndDate: (value: string) => void
  dateTimeError?: boolean
  toggleDateRangeValue: string
  setToggleDateRangeValue: (arg: string) => void
}

export const FinanceDateTimeRange = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  toggleDateRangeValue,
  dateTimeError = false,
  setToggleDateRangeValue,
}: Props) => {
  const setDateRange = (value: string) => {
    switch (value) {
      case '60min':
        setStartDate(parseDate(Date.now() - 60 * 60 * 1000))
        setEndDate(parseDate(new Date().getTime()))
        setToggleDateRangeValue('60min')
        return
      case 'today':
        setStartDate(parseDate(new Date().setHours(0, 0, 0, 0)))
        setEndDate(parseDate(new Date().getTime()))
        setToggleDateRangeValue('today')
        return
      case 'yesterday':
        setStartDate(parseDate(getStartOfPrevDay()))
        setEndDate(parseDate(getEndOfPrevDay()))
        setToggleDateRangeValue('yesterday')
        return
      case 'currentMonth':
        setStartDate(parseDate(getStartOfCurrentMonth()))
        setEndDate(parseDate(getEndOfCurrentMonth()))
        setToggleDateRangeValue('currentMonth')
        return
      case 'prevMonth':
        setStartDate(parseDate(getStartOfPrevMonth()))
        setEndDate(parseDate(getEndOfPrevMonth()))
        setToggleDateRangeValue('prevMonth')
        return
      case 'last30Days':
        setStartDate(parseDate(getStartLast30Days()))
        setEndDate(parseDate(getEndLast30Days()))
        setToggleDateRangeValue('last30Days')
        return
    }
  }

  return (
    <Stack
      component="form"
      noValidate
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginBottom: '20px',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className={classes.DateTimeInput}>
          <TextField
            id="startDate"
            label="Начиная с"
            type="datetime-local"
            size="small"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value)
            }}
            sx={{
              marginRight: '10px',
            }}
            className={classes.DateTimeInput}
            InputLabelProps={{
              shrink: true,
            }}
            error={dateTimeError}
          />
        </div>

        <div className={classes.DateTimeInput}>
          <TextField
            id="endDate"
            label="Заканчивая по"
            type="datetime-local"
            size="small"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value)
            }}
            sx={{
              marginRight: '10px',
            }}
            InputLabelProps={{
              shrink: true,
            }}
            error={dateTimeError}
          />
        </div>
      </div>
      <ToggleButtonGroup
        color="primary"
        value={toggleDateRangeValue}
        exclusive
        onChange={(e, value) => setDateRange(value)}
        size="small"
        sx={{ marginRight: '10px' }}
      >
        <ToggleButton value="60min" sx={{ minWidth: '60px' }}>
          60 мин
        </ToggleButton>
        <ToggleButton value="today" sx={{ minWidth: '78px' }}>
          сегодня
        </ToggleButton>
        <ToggleButton value="yesterday" sx={{ minWidth: '60px' }}>
          вчера
        </ToggleButton>
        <ToggleButton value="currentMonth" sx={{ minWidth: '133px' }}>
          текущий месяц
        </ToggleButton>
        <ToggleButton value="prevMonth" sx={{ minWidth: '138px' }}>
          прошлый месяц
        </ToggleButton>
        <ToggleButton value="last30Days" sx={{ minWidth: '80px' }}>
          30 дней
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  )
}
