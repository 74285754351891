import React from 'react'
import { PlacesInterface } from '../../../../corporateOffice/corpReport/interfaces'
import Typography from '@mui/material/Typography'
import { ReportCardItem } from './ReportCardItem'
import { ReportListItem } from '../../interfaces'

type Props = {
  places: PlacesInterface[]
  reportListItem: ReportListItem
}

export const ReportCardGrid = ({ places, reportListItem }: Props) => {
  const getCarwashName = (carwashId: string): string => {
    const currentCarwash = places.find((item) => item.id === carwashId)
    return currentCarwash ? currentCarwash.name : ''
  }
  const {
    carwashId,
    maxTime,
    averageTime,
    totalTime,
    cleanlinessOfHands,
    averageNumbersOfUses,
    totalNumbersOfUses,
  } = reportListItem

  return (
    <>
      <Typography
        variant="body1"
        gutterBottom
        component="div"
        style={{ fontWeight: 500 }}
      >
        Мойка: {getCarwashName(carwashId)}
      </Typography>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginBottom: '20px',
        }}
      >
        <ReportCardItem
          title="Среднее время (сек)"
          value={Math.trunc(averageTime)}
          description="использования"
        />
        <ReportCardItem
          title="Макс. время (сек)"
          value={Math.trunc(maxTime)}
          description="использования"
        />
        <ReportCardItem
          title="Общее время (мин)"
          value={Math.trunc(totalTime / 60)}
          description="использования"
        />
        <ReportCardItem
          title="Средняя чистота рук"
          value={cleanlinessOfHands.toFixed(2)}
          description="в % исходя из норматива 40 сек"
        />
        <ReportCardItem
          title="Среднее (1 день)"
          value={averageNumbersOfUses}
          description="кол-во использований"
        />
        <ReportCardItem
          title="Общее"
          value={totalNumbersOfUses}
          description="кол-во использований"
        />
      </div>
    </>
  )
}
