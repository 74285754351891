import React from 'react'
import { InputProps } from 'react-admin'

export const AmountField = ({ record }: InputProps) => {
  const { amount } = record
    ? JSON.parse(record.details)
    : { amount: { value: '', currency: '' } }
  return (
    <span>
      {amount.value} {amount.currency}
    </span>
  )
}
