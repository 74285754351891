import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { AbilityContext } from './casl/Can'
import ability from './casl/ability'

ReactDOM.render(
  <React.StrictMode>
    <AbilityContext.Provider value={ability}>
      <App />
    </AbilityContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
