import React, { useEffect, useState } from 'react'
import { FilterList, FilterListItem } from 'react-admin'
import LocalCarWashIcon from '@material-ui/icons/LocalCarWash'

export const CarWashFilter = ({ carWashList }: any) => {
  const [filteredCarwashList, setFilteredCarwashList] = useState([])

  useEffect(() => {
    const rawUsersCarwashList = localStorage.getItem('profile')
    if (!carWashList || !rawUsersCarwashList) return
    const usersCarwashList = JSON.parse(rawUsersCarwashList)

    const filteredPlacesList = carWashList.filter((item: any) =>
      usersCarwashList.carwash.find((carwash: any) => carwash === item.id)
    )

    setFilteredCarwashList(filteredPlacesList)
  }, [carWashList])

  if (filteredCarwashList.length === 0) return null
  return (
    <FilterList label="Мойка" icon={<LocalCarWashIcon />}>
      {filteredCarwashList &&
        filteredCarwashList.map(
          (item: {
            name:
              | string
              | React.ReactElement<
                  any,
                  string | React.JSXElementConstructor<any>
                >
            id: React.Key | null | undefined
          }) => (
            <FilterListItem
              label={item.name}
              value={{
                carwashId: item.id,
              }}
              key={item.id}
            />
          )
        )}
    </FilterList>
  )
}
