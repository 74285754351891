import { ChangeEvent, useCallback, useState } from 'react'
import { SessionItem } from '../../interfaces'
import inMemoryJWT from '../../../../../Auth/inMemoryJWT'
import { apiUrl } from '../../../../../helpers/config'
import axios from 'axios'
import useInterval from '@use-it/interval'

type Props = {
  deviceItem: SessionItem
}

type ApiResponse = {
  status: string
}

type SetConfigData = {
  sessionId: string
  apiUrl: string
  threshold: number
  distTimerDelay: number
  defaultPinValue: number
  defaultSendStateDelay: number
  trafficlightsDelay: number
}

const transformValue = (
  fieldName: string,
  fieldValue: string
): string | number => (fieldName === 'apiUrl' ? fieldValue : Number(fieldValue))

const validate = (fieldName: string, fieldValue: string): string => {
  let value
  switch (fieldName) {
    case 'apiUrl':
      return fieldValue.length > 0 ? '' : 'поле не может быть пустым'
    case 'threshold':
      value = Number(fieldValue)
      if (value > 7500 || value < 0)
        return 'допустимый диапазон значений 0 - 7500'
      return ''
    case 'distTimerDelay':
      value = Number(fieldValue)
      if (value < 0) return 'значение должно быть > 0'
      return ''
    case 'defaultPinValue':
      value = Number(fieldValue)
      if (value < 0 || value > 1) return 'допустимый диапазон значений 0 - 1'
      return ''
    case 'defaultSendStateDelay':
      value = Number(fieldValue)
      if (value < 0) return 'значение должно быть > 0'
      return ''
    case 'trafficlightsDelay':
      value = Number(fieldValue)
      if (value < 0) return 'значение должно быть > 0'
      return ''
    default:
      return ''
  }
}

const useDeviceListItemHook = ({ deviceItem }: Props) => {
  const [uptime, setUptime] = useState('')
  const [expanded, setExpanded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formConfigData, setFormConfigData] = useState<SetConfigData>({
    sessionId: deviceItem.sessionId,
    apiUrl: deviceItem.apiUrl,
    threshold: deviceItem.threshold,
    distTimerDelay: deviceItem.distTimerDelay,
    defaultPinValue: deviceItem.defaultPinValue,
    defaultSendStateDelay: deviceItem.defaultSendStateDelay,
    trafficlightsDelay: deviceItem.trafficlightsDelay,
  })
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [validateErrors, setValidateErrors] = useState({
    apiUrl: '',
    threshold: '',
    distTimerDelay: '',
    defaultPinValue: '',
    defaultSendStateDelay: '',
    trafficlightsDelay: '',
  })

  const setFormData = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const formConfig = {
      ...formConfigData,
      [e.target.name]: transformValue(e.target.name, e.target.value),
    }
    const currentValidateErrors = {
      ...validateErrors,
      [e.target.name]: validate(e.target.name, e.target.value),
    }
    setValidateErrors(currentValidateErrors)
    setFormConfigData(formConfig)
  }

  const switchMode = async () => {
    try {
      let token = inMemoryJWT.getToken()
      if (!token) {
        inMemoryJWT.setRefreshTokenEndpoint(`${apiUrl}/refresh/cookie`)
        const gotRefreshedToken = await inMemoryJWT.getRefreshedToken()
        if (!gotRefreshedToken) return
        token = inMemoryJWT.getToken()
      }

      setLoading(true)
      await new Promise<void>((resolve) => setTimeout(() => resolve(), 1000))
      await axios.post<ApiResponse>(
        `${apiUrl}/admin/carCounterWsGateway/setMode`,
        {
          sessionId: deviceItem.sessionId,
          isAuto: deviceItem.isAuto === 1 ? 0 : 1,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
          timeout: 5000,
        }
      )
      setLoading(false)
      setOpenSnackbar(true)
    } catch (error) {
      console.log('switch mode error: ', error)
      setLoading(false)
    }
  }

  const switchPin = async () => {
    try {
      let token = inMemoryJWT.getToken()
      if (!token) {
        inMemoryJWT.setRefreshTokenEndpoint(`${apiUrl}/refresh/cookie`)
        const gotRefreshedToken = await inMemoryJWT.getRefreshedToken()
        if (!gotRefreshedToken) return
        token = inMemoryJWT.getToken()
      }

      setLoading(true)
      await new Promise<void>((resolve) => setTimeout(() => resolve(), 1000))
      await axios.post<ApiResponse>(
        `${apiUrl}/admin/carCounterWsGateway/setState`,
        {
          sessionId: deviceItem.sessionId,
          newState: deviceItem.currentPinValue === 1 ? 0 : 1,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
          timeout: 5000,
        }
      )
      setLoading(false)
      setOpenSnackbar(true)
    } catch (error) {
      console.log('switchPin error: ', error)
      setLoading(false)
    }
  }

  const reboot = async () => {
    try {
      let token = inMemoryJWT.getToken()
      if (!token) {
        inMemoryJWT.setRefreshTokenEndpoint(`${apiUrl}/refresh/cookie`)
        const gotRefreshedToken = await inMemoryJWT.getRefreshedToken()
        if (!gotRefreshedToken) return
        token = inMemoryJWT.getToken()
      }

      setLoading(true)
      await new Promise<void>((resolve) => setTimeout(() => resolve(), 1000))
      await axios.post<ApiResponse>(
        `${apiUrl}/admin/carCounterWsGateway/reboot`,
        {
          sessionId: deviceItem.sessionId,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
          timeout: 5000,
        }
      )
      setLoading(false)
      setOpenSnackbar(true)
    } catch (error) {
      console.log('reboot error: ', error)
      setLoading(false)
    }
  }

  const setConfig = async () => {
    try {
      if (isSaveBtnDisable()) return
      let token = inMemoryJWT.getToken()
      if (!token) {
        inMemoryJWT.setRefreshTokenEndpoint(`${apiUrl}/refresh/cookie`)
        const gotRefreshedToken = await inMemoryJWT.getRefreshedToken()
        if (!gotRefreshedToken) return
        token = inMemoryJWT.getToken()
      }

      setLoading(true)
      await new Promise<void>((resolve) => setTimeout(() => resolve(), 1000))
      await axios.post<ApiResponse>(
        `${apiUrl}/admin/carCounterWsGateway/setConfig`,
        { ...formConfigData },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
          timeout: 5000,
        }
      )
      setLoading(false)
      setOpenSnackbar(true)
    } catch (error) {
      console.log('set config error: ', error)
      setLoading(false)
    }
  }

  const resetToDefault = async () => {
    try {
      let token = inMemoryJWT.getToken()
      if (!token) {
        inMemoryJWT.setRefreshTokenEndpoint(`${apiUrl}/refresh/cookie`)
        const gotRefreshedToken = await inMemoryJWT.getRefreshedToken()
        if (!gotRefreshedToken) return
        token = inMemoryJWT.getToken()
      }

      setLoading(true)
      await new Promise<void>((resolve) => setTimeout(() => resolve(), 1000))
      await axios.post<ApiResponse>(
        `${apiUrl}/admin/carCounterWsGateway/resetToDefault`,
        { sessionId: deviceItem.sessionId },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
          timeout: 5000,
        }
      )
      setLoading(false)
      setOpenSnackbar(true)
    } catch (error) {
      console.log('reset to default error: ', error)
      setLoading(false)
    }
  }

  const handleCloseSnackbar = () => setOpenSnackbar(false)

  const isSaveBtnDisable = () => {
    const errors = Object.values(validateErrors)
    const sum = errors.reduce(
      (prev, currentValue) => (prev = prev + currentValue.length),
      0
    )
    return !!sum
  }

  const getUptime = useCallback(() => {
    const duration = Date.now() - deviceItem.timestamp
    let minutes: number | string = parseInt(
      String((duration / (1000 * 60)) % 60)
    )
    let hours: number | string = parseInt(
      String((duration / (1000 * 60 * 60)) % 24)
    )
    let days = Math.trunc(parseInt(String(duration / (1000 * 60 * 60))) / 24)
    hours = hours < 10 ? '0' + hours : hours
    minutes = minutes < 10 ? '0' + minutes : minutes
    return `${days}д ${hours}ч ${minutes}м`
  }, [deviceItem.timestamp])

  useInterval(() => {
    const currentUptime = getUptime()
    setUptime(currentUptime)
  }, 1000)

  return {
    expanded,
    setExpanded,
    loading,
    switchMode,
    switchPin,
    reboot,
    formConfigData,
    setFormData,
    setConfig,
    resetToDefault,
    openSnackbar,
    handleCloseSnackbar,
    validateErrors,
    uptime,
  }
}

export default useDeviceListItemHook
