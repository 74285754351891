import React from 'react'
import './App.css'
import { Admin, Resource } from 'react-admin'
import polyglotI18nProvider from 'ra-i18n-polyglot'
// @ts-ignore
import russianMessages from 'ra-language-russian'

import bonus from './containers/bonus'
import discountLevel from './containers/discountLevel'
import place from './containers/place'
import transaction from './containers/transaction'
import users from './containers/users'
import owen from './containers/owen'
import payments from './containers/carwashStatPayment'
import events from './containers/carwashStatEvent'
import adminUsers from './containers/adminUsers'
import MyLayout from './Layout/MyLayout'
import customRoutes from './customRoutes'
import promo from './containers/promo'
import { PromoHistoryList } from './containers/promoHistory/PromoHistoryList'
import { CarwashLogList } from './containers/carwashLogs/CarwashLogList'
import carCounterDevice from './containers/carCounter/carCounterDevice'
import carCounterList from './containers/carCounter/carCounterList'
import roles from './containers/roles'
import authProvider from './Auth/authProvider'
import encashment from './containers/Encashment'
import botUsers from './containers/botUsers/index'
import botActiveUsers from './containers/botActiveUsers'
import botHistory from './containers/botHistory'
import { useAbility } from '@casl/react'
import { AbilityContext } from './casl/Can'
import corpOrganizations from './containers/corporateOffice/corpOrganizations'
import corpUsers from './containers/corporateOffice/corpUsers'
import corpPayments from './containers/corporateOffice/corpPayments'
import ChooseDashboard from './containers/dashboard/ChooseDashboard'
import whEvents from './containers/Wh/whEventsAdmin'
import specialPaymentsConfig from './containers/SpecialPaymentsConfig'
import machineHours from './containers/Wh/MachineHours'
import equipmentServiceConfig from './containers/Wh/EquipmentServiceConfig'
import equipmentServiceList from './containers/Wh/EquipmentServiceList'
import waterDispenser from './containers/carCounter/waterDispenserList'
import esCommonConfig from './containers/Wh/esCommonConfig'
import myDataProvider from './dataProvider/myDataProvider'

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru')
const dataProvider = myDataProvider()

const App = () => {
  const ability = useAbility(AbilityContext)
  return (
    <Admin
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      authProvider={authProvider}
      customRoutes={customRoutes}
      layout={MyLayout}
      title="Акварель"
      dashboard={ChooseDashboard}
      disableTelemetry
    >
      <Resource
        name="auth"
        list={adminUsers.list}
        edit={ability.can('update', 'adminUsers') ? adminUsers.edit : undefined}
        create={
          ability.can('create', 'adminUsers') ? adminUsers.create : undefined
        }
      />
      <Resource
        name="users"
        list={users.list}
        show={ability.can('read', 'users') ? users.show : undefined}
        edit={ability.can('update', 'users') ? users.edit : undefined}
        create={ability.can('create', 'users') ? users.create : undefined}
      />
      <Resource
        name="bonus"
        list={bonus.list}
        create={ability.can('create', 'bonus') ? bonus.create : undefined}
      />
      <Resource
        name="promoCode"
        list={promo.list}
        edit={ability.can('update', 'promoCode') ? promo.edit : undefined}
        create={ability.can('create', 'promoCode') ? promo.create : undefined}
      />
      <Resource name="promoCodeHistory" list={PromoHistoryList} />
      <Resource
        name="transactions"
        list={transaction.list}
        edit={
          ability.can('update', 'specialPayment') ? transaction.edit : undefined
        }
      />
      <Resource
        name="carwash-stat/payment"
        list={payments.list}
        edit={
          ability.can('update', 'specialPayment') ? payments.edit : undefined
        }
      />
      <Resource name="carwash-stat/event" list={events.list} />
      <Resource name="encashment" list={encashment.list} />
      <Resource
        name="discountLevels"
        list={discountLevel.list}
        show={
          ability.can('read', 'discountLevels') ? discountLevel.show : undefined
        }
        edit={
          ability.can('update', 'discountLevels')
            ? discountLevel.edit
            : undefined
        }
      />
      <Resource
        name="places"
        list={place.list}
        show={ability.can('read', 'places') ? place.show : undefined}
        edit={ability.can('update', 'places') ? place.edit : undefined}
        create={ability.can('create', 'places') ? place.create : undefined}
      />
      <Resource
        name="roles"
        list={roles.list}
        show={ability.can('read', 'roles') ? roles.show : undefined}
        edit={ability.can('update', 'roles') ? roles.edit : undefined}
        create={ability.can('create', 'roles') ? roles.create : undefined}
      />
      {/*<Resource name="owen/accounts" {...owen} />*/}
      {/*<Resource name="owen/deviceList" />*/}
      <Resource
        name="botUsers"
        list={botUsers.list}
        show={ability.can('read', 'botusers') ? botUsers.show : undefined}
        edit={ability.can('update', 'botusers') ? botUsers.edit : undefined}
        create={ability.can('create', 'botusers') ? botUsers.create : undefined}
      />
      <Resource name="botActiveUsers" list={botActiveUsers.list} />
      <Resource name="botHistory" list={botHistory.list} />
      <Resource name="carwashLogs" list={CarwashLogList} />
      <Resource
        name="carCounterDevice"
        list={carCounterDevice.list}
        edit={
          ability.can('update', 'carCounter')
            ? carCounterDevice.edit
            : undefined
        }
        create={
          ability.can('create', 'carCounter')
            ? carCounterDevice.create
            : undefined
        }
      />
      <Resource name="carCounterList" list={carCounterList.list} />
      <Resource name="waterDispenserList" list={waterDispenser.list} />
      <Resource
        name="corpOrganizations"
        list={corpOrganizations.list}
        show={
          ability.can('read', 'corpOrganizations')
            ? corpOrganizations.show
            : undefined
        }
        edit={
          ability.can('update', 'corpOrganizations')
            ? corpOrganizations.edit
            : undefined
        }
        create={
          ability.can('create', 'corpOrganizations')
            ? corpOrganizations.create
            : undefined
        }
      />
      <Resource
        name="corpUsers"
        list={corpUsers.list}
        show={ability.can('read', 'corpUsers') ? corpUsers.show : undefined}
        edit={ability.can('update', 'corpUsers') ? corpUsers.edit : undefined}
        create={
          ability.can('create', 'corpUsers') ? corpUsers.create : undefined
        }
      />
      <Resource
        name="corpPayments"
        list={corpPayments.list}
        create={
          ability.can('create', 'corpPayments')
            ? corpPayments.create
            : undefined
        }
      />
      <Resource name="whEventsAdmin" list={whEvents.list} />
      <Resource name="machineHours" list={machineHours.list} />
      <Resource
        name="equipmentServiceConfig"
        list={equipmentServiceConfig.list}
        create={
          ability.can('create', 'whEvents')
            ? equipmentServiceConfig.creat
            : undefined
        }
        edit={
          ability.can('update', 'whEvents')
            ? equipmentServiceConfig.edit
            : undefined
        }
      />
      <Resource
        name="es-common-config"
        list={esCommonConfig.list}
        create={
          ability.can('create', 'whEvents') ? esCommonConfig.create : undefined
        }
        edit={
          ability.can('update', 'whEvents') ? esCommonConfig.edit : undefined
        }
      />
      <Resource
        name="equipmentServiceList"
        list={equipmentServiceList.list}
        show={equipmentServiceList.show}
        edit={
          ability.can('update', 'whEvents')
            ? equipmentServiceList.edit
            : undefined
        }
      />
      <Resource
        name="specialPaymentsConfig"
        list={specialPaymentsConfig.list}
        create={
          ability.can('create', 'specialPayment')
            ? specialPaymentsConfig.creat
            : undefined
        }
        edit={
          ability.can('update', 'specialPayment')
            ? specialPaymentsConfig.edit
            : undefined
        }
      />
      <Resource name="machineHoursSummary" />
    </Admin>
  )
}

export default App
