import React from 'react'
import { Error, useGetList } from 'react-admin'

import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import { DateRangeFilter } from './filters/DateRangeFilter'
import { CarWashFilter } from './filters/CarWashFilter'
import { PostFilter } from './filters/PostFilter'
import inMemoryJWT from '../../Auth/inMemoryJWT'

const Card = withStyles((theme) => ({
  root: {
    // [theme.breakpoints.up('sm')]: {
    order: -1, // display on the left rather than on the right of the list
    width: '15em',
    marginRight: '1em',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   display: 'none',
    // },
  },
}))(MuiCard)

export const CarwashDatePostFilter = () => {
  const { loaded, error, data } = useGetList(
    'places',
    {
      page: 1,
      perPage: 1000,
    },
    {
      field: 'name',
      order: 'ASC',
    },
    {},
    { enabled: !!inMemoryJWT.getToken() }
  )
  if (!loaded) {
    return null
  }
  if (error) {
    return <Error error={error} />
  }
  if (data) {
    return (
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Фильтры
          </Typography>
          <CarWashFilter carWashList={Object.values(data)} />
          <DateRangeFilter />
          <PostFilter />
        </CardContent>
      </Card>
    )
  }
  return null
}
