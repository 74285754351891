import { WaterDispenserInterface } from '../interfaces'

type Props = {
  waterDispenserList: WaterDispenserInterface[]
}

export const getTotalTime = ({ waterDispenserList }: Props): number => {
  if (waterDispenserList.length === 0) return 0

  return waterDispenserList.reduce((prev, item) => prev + item.spentTime, 0)
}
