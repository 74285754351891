import React from 'react'
import {
  DeleteButton,
  Edit,
  EditProps,
  ReferenceInput,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  TextInput,
  BooleanInput,
} from 'react-admin'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../../casl/Can'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const CustomToolbar = (props: ToolbarProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      {ability.can('delete', 'whEvents') && <DeleteButton />}
    </Toolbar>
  )
}

export const EquipmentServiceListEdit = (props: EditProps) => (
  <Edit {...props} title="Редактирование записи">
    <SimpleForm toolbar={<CustomToolbar />}>
      <ReferenceInput source="carwashId" reference="places" label="Мойка">
        <SelectInput optionText="name" optionValue="id" disabled={true} />
      </ReferenceInput>
      <SelectInput
        source="postTitle"
        label="Пост"
        choices={[
          { id: 'Пост 1', name: 'Пост 1' },
          { id: 'Пост 2', name: 'Пост 2' },
          { id: 'Пост 3', name: 'Пост 3' },
          { id: 'Пост 4', name: 'Пост 4' },
          { id: 'Пост 5', name: 'Пост 5' },
          { id: 'Пост 6', name: 'Пост 6' },
          { id: 'Пост 7', name: 'Пост 7' },
        ]}
        disabled={true}
      />
      <SelectArrayInput
        source="deviceNames"
        label="Устройства"
        choices={[
          { id: 'АВД1', name: 'АВД1' },
          { id: 'АВД2', name: 'АВД2' },
        ]}
        disabled={true}
      />
      {/*<NumberInput*/}
      {/*  source="machineHours"*/}
      {/*  label="Наработка с последнего сброса (минуты)"*/}
      {/*  validate={required()}*/}
      {/*  disabled={true}*/}
      {/*/>*/}
      {/*<DateInput source="timestamp" label="Дата" disabled={true} />*/}
      {/*<NumberInput*/}
      {/*  source="equipmentServiceInterval"*/}
      {/*  label="Сервисный интервал на момент сброса (часы)"*/}
      {/*  disabled={true}*/}
      {/*/>*/}
      <TextInput source="employee" label="Сотрудник" disabled={true} />
      <BooleanInput source="isConfirmed" label="Подтвержден" />
    </SimpleForm>
  </Edit>
)
