import React from 'react'
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  DateInput,
  NumberInput,
  EditProps,
  BooleanInput,
  SelectInput,
  DateField,
  DateTimeInput,
} from 'react-admin'

export const PaymentEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput source="carwashId" reference="places" label="Мойка">
        <SelectInput optionText="name" disabled />
      </ReferenceInput>
      <DateTimeInput source="dateTime" label="Дата" disabled />
      <NumberInput source="postNumber" label="Пост" disabled />
      <NumberInput
        source="paymentCash"
        label="Сумма платежа наличными"
        disabled
      />
      <NumberInput source="paymentCard" label="Сумма платежа картой" disabled />
      <NumberInput source="price" label="Общая стоимость" disabled />
      <BooleanInput source="special" label="Особый" />
    </SimpleForm>
  </Edit>
)
