import { RolesCreate } from './RolesCreate'
import { RolesEdit } from './RolesEdit'
import { RolesShow } from './RolesShow'
import { RolesList } from './RolesList'

export default {
  list: RolesList,
  create: RolesCreate,
  edit: RolesEdit,
  show: RolesShow,
}
