import React from 'react'
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'

export const CarCounterDeviceCreate = (props: any) => (
  <Create {...props} title="Добавление нового устройства" redirect="list">
    <SimpleForm>
      <TextInput source="deviceId" label="Id устройства" />
      <ReferenceInput reference="places" source="carwashId" label="мойка">
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <TextInput source="postNumber" label="Номер поста" />
      <SelectInput
        source="type"
        label="Тип устройства"
        choices={[
          { id: 'carCounter', name: 'Счетчик машин' },
          { id: 'waterDispenser', name: 'Рукомойник' },
        ]}
      />
      <TextInput source="deviceName" label="Название" />
    </SimpleForm>
  </Create>
)
