import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  ListProps,
  SelectField,
  NumberField,
} from 'react-admin'
import { BotHistoryFilter } from './Filters/BotHistoryFilter'
import { CustomPagination } from '../../components/CustomPagination/CustomPagination'
import React from 'react'

export const BotHistoryList = (props: ListProps) => (
  <List
    {...props}
    sort={{ field: 'timestamp', order: 'DESC' }}
    title="История"
    bulkActionButtons={false}
    aside={<BotHistoryFilter />}
    pagination={<CustomPagination />}
  >
    <Datagrid>
      <ReferenceField
        source="botUserId"
        reference="botUsers"
        label="Имя пользователя"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="carwashId"
        reference="places"
        label="Мойка"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <SelectField
        source="type"
        label="Тип"
        choices={[
          { id: 'message', name: 'сообщение' },
          { id: 'onMyWay', name: 'уже иду' },
          { id: 'payment', name: 'платеж' },
          { id: 'noAnswer', name: 'без ответа' },
          { id: 'subscribe', name: 'подписка' },
          { id: 'unsubscribe', name: 'отписка' },
          { id: 'reboot', name: 'перезагрузка' },
        ]}
      />
      <NumberField source="amount" label="Сумма платежа" />
      <TextField source="description" label="Описание" />
      <DateField source="timestamp" showTime={true} label="Дата" />
    </Datagrid>
  </List>
)
