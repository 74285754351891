import { PlacesInterface } from '../interfaces'

type Props = {
  places: PlacesInterface[]
  carwashId: string
}

export const getCarwashName = ({ places, carwashId }: Props): string => {
  const currentPlace = places.find((place) => place.id === carwashId)
  return currentPlace ? currentPlace.name : ''
}
