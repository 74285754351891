import React from 'react'
import {
  List,
  Datagrid,
  DateField,
  NumberField,
  ReferenceField,
  TextField,
} from 'react-admin'
import { CarwashDatePostFilter } from '../../components/CarwashDatePostFilter/CarwashDatePostFilter'
import { CustomPagination } from '../../components/CustomPagination/CustomPagination'
import { TopDateTimeFilter } from '../../components/TopDateTimeFilter/TopDateTimeFilter'
import classes from './CarwashLogList.module.sass'

const rowStyle = (record: any) => {
  if (!record)
    return {
      borderLeft: '5px solid rgb(244, 67, 54)',
    }
  switch (record.logLevel) {
    case 'INFO':
      return {
        borderLeft: '5px solid rgb(76, 175, 80)',
      }
    case 'WARN':
      return {
        borderLeft: '5px solid rgb(255, 152, 0)',
      }
    case 'ERROR':
      return {
        borderLeft: '5px solid rgb(244, 67, 54)',
      }
    case 'FATAL':
      return {
        borderLeft: '5px solid rgb(153, 0, 0)',
      }
    case 'TRACE':
      return {
        borderLeft: '5px solid rgb(51, 51, 255)',
      }
    default:
      return {
        borderLeft: 'none',
      }
  }
}

export const CarwashLogList = (props: any) => (
  <List
    {...props}
    title="Логи"
    bulkActionButtons={false}
    sort={{ field: 'timestamp', order: 'DESC' }}
    aside={<CarwashDatePostFilter />}
    pagination={<CustomPagination />}
    filters={TopDateTimeFilter}
  >
    <Datagrid rowStyle={rowStyle}>
      <ReferenceField source="carwashId" reference="places" label="Мойка">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="logLevel" label="Тип" />
      <NumberField source="postNumber" label="Номер поста" />
      <TextField
        source="data"
        label="Сообщение"
        cellClassName={classes.DataFieldContainer}
      />
      <DateField source="timestamp" label="Дата" showTime={true} />
    </Datagrid>
  </List>
)
