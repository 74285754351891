import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import TableBody from '@mui/material/TableBody'
import { ReportTableRow } from './ReportTableRow'
import {
  getCarwashDeviceSum,
  getCarwashSumForDate,
} from '../../../../Encashment/components/functions/getSum'
import TableContainer from '@material-ui/core/TableContainer'
import React from 'react'
import { Data } from '../../interfaces'
import { Collapse } from '@mui/material'

type Props = {
  recordItem: Data
}

export const ReportTable = ({ recordItem }: Props) => {
  const [open, setOpen] = React.useState(false)

  return (
    <TableContainer component={Paper} style={{ marginBottom: 30 }}>
      <Table size="small" aria-label="collapsible table a dense table">
        <TableHead>
          <TableRow>
            <TableCell width="5%">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell>
              {new Date(recordItem.dateTime).toLocaleDateString()}
            </TableCell>
            {recordItem.deviceNameList.map((deviceName, index) => (
              <TableCell key={deviceName + index * 8976}>
                {!open ? deviceName : ''}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              style={{
                // paddingBottom: 0,
                border: 'unset',
              }}
              colSpan={5}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                <TableContainer
                  component={Paper}
                  style={{ marginBottom: 30, marginTop: 30 }}
                >
                  <Table
                    size="small"
                    aria-label="collapsible table a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Пост</TableCell>
                        {recordItem.deviceNameList.map((deviceName, index) => (
                          <TableCell key={deviceName + index * 462}>
                            {deviceName}
                          </TableCell>
                        ))}
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {recordItem.encashments.map((encashmentItem, index) => (
                        <ReportTableRow
                          encashmentItem={encashmentItem}
                          key={encashmentItem.dateTime.toString() + index * 964}
                        />
                      ))}
                      <TableRow>
                        <TableCell />
                        <TableCell style={{ fontWeight: 'bold' }}>
                          Итого:
                        </TableCell>
                        {recordItem.deviceNameList.map((deviceName, index) => (
                          <TableCell
                            style={{ fontWeight: 'bold' }}
                            key={deviceName + index * 9531}
                          >
                            {getCarwashDeviceSum(recordItem, deviceName)}
                          </TableCell>
                        ))}
                        <TableCell style={{ fontWeight: 'bold' }}>
                          {getCarwashSumForDate(recordItem)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Collapse>
            </TableCell>
          </TableRow>

          {!open && (
            <TableRow>
              <TableCell />
              <TableCell style={{ fontWeight: 'bold' }}>Итого:</TableCell>
              {recordItem.deviceNameList.map((deviceName, index) => (
                <TableCell
                  style={{ fontWeight: 'bold' }}
                  key={(index * 103).toString() + deviceName}
                >
                  {getCarwashDeviceSum(recordItem, deviceName)}
                </TableCell>
              ))}
              <TableCell style={{ fontWeight: 'bold' }}>
                {getCarwashSumForDate(recordItem)}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
