import React from 'react'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'

type Props = {
  startDate: string
  setStartDate: (value: string) => void
  endDate: string
  setEndDate: (value: string) => void
}

export const CarwashChooseDateRange = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: Props) => {
  return (
    <Stack component="form" noValidate spacing={3} style={{ marginTop: 30 }}>
      <TextField
        id="startDate"
        label="Начиная с"
        type="datetime-local"
        value={startDate}
        onChange={(e) => {
          setStartDate(e.target.value)
        }}
        sx={{ width: 250 }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        id="endDate"
        label="Заканчивая по"
        type="datetime-local"
        value={endDate}
        onChange={(e) => {
          setEndDate(e.target.value)
        }}
        sx={{ width: 250 }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Stack>
  )
}
