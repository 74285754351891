import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Place } from '../../reports/EncashmentReport/interfaces'
import { useQueryWithStore } from 'react-admin'
import axios from 'axios'
import InMemoryJWT from '../../../Auth/inMemoryJWT'
import useInterval from '@use-it/interval'
import { apiUrl } from '../../../helpers/config'
import { CarwashEncashment, RawEncashmentAmounts } from '../interfaces'

const useDashboardHook = () => {
  const [places, setPlaces] = useState<Place[]>()
  const [checkedPlaces, setCheckedPlaces] = useState<string[]>([])
  const [rawEncashmentAmounts, setRawEncashmentAmounts] = useState<
    RawEncashmentAmounts[]
  >([])
  const usersCarwash = useRef<string[]>([])

  useEffect(() => {
    const userProfile = localStorage.getItem('profile')
    if (!userProfile) return
    const profileData = JSON.parse(userProfile)
    usersCarwash.current = profileData.carwash
  }, [])

  const placesList = useQueryWithStore({
    type: 'getList',
    resource: 'places',
    payload: {
      pagination: {
        page: 1,
        perPage: 1000,
      },
      sort: {
        field: 'id',
        order: 'ASC',
      },
      filter: {},
    },
  })

  useEffect(() => {
    if (places || usersCarwash.current.length === 0 || !placesList.data) return
    const filteredPlacesList = placesList.data
      .filter((item: any) =>
        usersCarwash.current.find((carwash) => carwash === item.id)
      )
      .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))

    setPlaces(filteredPlacesList)
    if (filteredPlacesList.length > 0)
      setCheckedPlaces(filteredPlacesList.map((item: any) => item.id))
  }, [places, placesList])

  const checkedPlaceHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const placeIndex = checkedPlaces.findIndex((item) => item === e.target.name)
    if (e.target.checked) {
      if (placeIndex >= 0) return
      setCheckedPlaces((prev) => [...prev, e.target.name])
    } else {
      if (placeIndex === -1) return
      setCheckedPlaces((prev) => {
        const places = [...prev]
        places.splice(placeIndex, 1)
        return places
      })
    }
  }

  const makeCarwashIdAsUrlParams = (ids: string[]): string | null => {
    if (!ids || ids.length === 0) return null
    let str = '?'
    ids.forEach((item, index) => {
      if (index === 0) {
        str += `carwashId=${item}`
        return
      }
      str += `&carwashId=${item}`
    })
    return str
  }

  const getEncashmentAmount = async () => {
    try {
      if (!checkedPlaces || checkedPlaces.length === 0) return
      const token = InMemoryJWT.getToken()
      if (!token) await InMemoryJWT.getRefreshedToken()

      const result = await axios(
        `${apiUrl}/admin/encashment/getCurrentEncashmentAmounts${makeCarwashIdAsUrlParams(
          checkedPlaces
        )}`,
        {
          timeout: 5000,
          headers: {
            Authorization: `Bearer ${InMemoryJWT.getToken()}`,
          },
        }
      )
      setRawEncashmentAmounts(result.data)
    } catch (error) {
      console.log('getEncashmentAmount error: ', error)
    }
  }

  useInterval(getEncashmentAmount, 60000)

  useEffect(() => {
    if (checkedPlaces.length > 0) getEncashmentAmount().then()
  }, [checkedPlaces])

  const getEncashmentAmountsByCarwashId = (
    carwashId: string
  ): CarwashEncashment[] => {
    const currentCarwash = rawEncashmentAmounts.filter(
      (carwashItem) => carwashItem.carwashId === carwashId
    )
    const postNames = [
      ...new Set(currentCarwash.map((record) => record.item.post_title)),
    ]
    return postNames.map((postName) => {
      const data = currentCarwash.filter(
        (carwashItem) => carwashItem.item.post_title === postName
      )
      const encashments = data.map((encashmentAmount) => ({
        device_name: encashmentAmount.item.device_name,
        denomination: encashmentAmount.item.denomination,
        amount: encashmentAmount.item.amount,
      }))
      const deviceList = [
        ...new Set(encashments.map((item) => item.device_name)),
      ]
      const postEncashments = deviceList.map((deviceName) => {
        const rawDeviceAmount = encashments.filter(
          (encashmentItem) => encashmentItem.device_name === deviceName
        )
        const sum = rawDeviceAmount.reduce((prev, item) => {
          return prev + (item.denomination / 100) * item.amount
        }, 0)
        return {
          device_name: deviceName,
          amount: sum,
        }
      })
      const postSum = postEncashments.reduce((prev, item) => {
        return prev + item.amount
      }, 0)
      return {
        postTitle: postName,
        postEncashment: postEncashments,
        postSum: postSum,
        postDeviceList: deviceList,
      }
    })
  }

  const getCarwashSum = (carwashId: string): number => {
    const carwashItem = getEncashmentAmountsByCarwashId(carwashId)
    return carwashItem.reduce((prev, item) => {
      return (
        prev +
        item.postEncashment.reduce((prevEncashment, encashmentItem) => {
          return prevEncashment + encashmentItem.amount
        }, 0)
      )
    }, 0)
  }

  const getSumByDeviceName = (carwashId: string) => {
    const carwashItem = getEncashmentAmountsByCarwashId(carwashId)
    const rawDevices: string[] = []
    carwashItem.forEach((carwashEncashment) =>
      rawDevices.push(...carwashEncashment.postDeviceList)
    )
    const deviceList = [...new Set(rawDevices)]
    return deviceList.map((deviceListItem) => {
      const sum = carwashItem.reduce((prev, item) => {
        return (
          prev +
          item.postEncashment.reduce((prevPostEncashment, encashmentItem) => {
            if (encashmentItem.device_name !== deviceListItem)
              return prevPostEncashment
            return prevPostEncashment + encashmentItem.amount
          }, 0)
        )
      }, 0)
      return {
        deviceName: deviceListItem,
        sum: sum,
      }
    })
  }

  return {
    places,
    checkedPlaces,
    checkedPlaceHandler,
    getEncashmentAmountsByCarwashId,
    getCarwashSum,
    getSumByDeviceName,
  }
}

export default useDashboardHook
