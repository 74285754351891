import { EventInterface } from '../interfaces'

type Props = {
  events: EventInterface | undefined
}

export const getTotalCarsFormula = ({ events }: Props): number => {
  if (!events) return 0
  const thresholdTime = 11 * 60 * 1000
  let totalCars = 0
  let lastTimestamp = 0

  events.dateTime.forEach((item) => {
    try {
      if (!lastTimestamp) {
        lastTimestamp = new Date(item).getTime()
        totalCars++
        return
      }
      if (new Date(item).getTime() - lastTimestamp < thresholdTime) {
        // lastTimestamp = new Date(item).getTime()
        return
      }
      lastTimestamp = new Date(item).getTime()
      totalCars++
    } catch (error) {
      return
    }
  })
  return totalCars
}
