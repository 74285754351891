import { EventInterface } from '../interfaces'

type Props = {
  events: EventInterface | undefined
  lastSeenGte: string
  lastSeenLte: string
}

const getNumberOfDays = (start: string, end: string): number => {
  const date1 = new Date(start)
  const date2 = new Date(end)
  const oneDay = 1000 * 60 * 60 * 24
  const diffInTime = date2.getTime() - date1.getTime()
  return Math.round(diffInTime / oneDay)
}

export const getCarsPerDayFormula = ({
  events,
  lastSeenLte,
  lastSeenGte,
}: Props): number => {
  if (!events) return 0
  const thresholdTime = 11 * 60 * 1000
  const diffDays = getNumberOfDays(lastSeenGte, lastSeenLte)

  let totalCars = 0
  let lastTimestamp = 0

  events.dateTime.forEach((item) => {
    try {
      if (!lastTimestamp) {
        lastTimestamp = new Date(item).getTime()
        totalCars++
        return
      }
      if (new Date(item).getTime() - lastTimestamp < thresholdTime) {
        // lastTimestamp = new Date(item).getTime()
        return
      }
      lastTimestamp = new Date(item).getTime()
      totalCars++
    } catch (error) {
      return
    }
  })
  return Math.round(totalCars / diffDays)
}
