import {
  AutocompleteArrayInput,
  BooleanInput,
  Create,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import React from 'react'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../../casl/Can'

export const CorpUsersCreate = (props: any) => {
  const ability = useAbility(AbilityContext)
  return (
    <Create {...props} title="Добавить корп. пользователя">
      <SimpleForm redirect="list">
        <TextInput
          source="firstName"
          label="Имя"
          format={(value: string) => (value ? value.trim() : '')}
          validate={required()}
        />
        <TextInput
          source="lastName"
          label="Фамилия"
          format={(value: string) => (value ? value.trim() : '')}
          validate={required()}
        />
        <ReferenceArrayInput
          label="Мойки"
          reference="places"
          source="carwash"
          validate={required()}
        >
          <AutocompleteArrayInput />
        </ReferenceArrayInput>
        <NumberInput source="dayLimit" label="Лимит (день)" />
        <NumberInput source="monthLimit" label="Лимит (месяц)" />
        {ability.can('read', 'corpOrganizations') && (
          <ReferenceInput
            reference="corpOrganizations"
            source="organizationId"
            label="Организация"
          >
            <SelectInput optionText="name" optionValue="id" />
          </ReferenceInput>
        )}
        <BooleanInput source="isPaymentEnabled" label="Платежи включены" />
      </SimpleForm>
    </Create>
  )
}
