import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { FinanceReportInterface } from '../../interfaces'
import { getSum } from '../../functions/getTotalAmount'
import { Row } from './components/Row'
import { getTotalCars } from '../../functions/getTotalCars'

type Props = {
  report: FinanceReportInterface[]
  countCarsType: string
  countCarsAvg: number
}

export const FinanceReportTable = ({
  report,
  countCarsAvg,
  countCarsType,
}: Props) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="collapsible a dense table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="right">Мойка</TableCell>
            <TableCell align="right">Наличные</TableCell>
            <TableCell align="right">Карта</TableCell>
            <TableCell align="right">Приложение</TableCell>
            <TableCell align="right">Количество машин</TableCell>
            <TableCell align="right">Итого</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {report
            .sort((a, b) => {
              const nameA = a.carwashName.toUpperCase()
              const nameB = b.carwashName.toUpperCase()
              if (nameA < nameB) return -1
              if (nameA > nameB) return 1
              return 0
            })
            .map((item) => (
              <Row
                key={item.carwashId}
                carwashId={item.carwashId}
                carwashName={item.carwashName}
                carwashTotal={item.carwashTotal}
                carwashCarCounterTotal={item.carwashCarCounterTotal}
                data={item.data}
                countCarsAvg={countCarsAvg}
                countCarsType={countCarsType}
              />
            ))}
          <TableRow>
            <TableCell />
            <TableCell align="right" style={{ fontWeight: 'bold' }}>
              Итого:
            </TableCell>
            <TableCell align="right" style={{ fontWeight: 'bold' }}>
              {new Intl.NumberFormat('ru-RU', {
                style: 'currency',
                currency: 'RUB',
              }).format(getSum(report, 'cash'))}
            </TableCell>
            <TableCell align="right" style={{ fontWeight: 'bold' }}>
              {new Intl.NumberFormat('ru-RU', {
                style: 'currency',
                currency: 'RUB',
              }).format(getSum(report, 'card'))}
            </TableCell>
            <TableCell align="right" style={{ fontWeight: 'bold' }}>
              {new Intl.NumberFormat('ru-RU', {
                style: 'currency',
                currency: 'RUB',
              }).format(getSum(report, 'app'))}
            </TableCell>
            <TableCell align="right" style={{ fontWeight: 'bold' }}>
              {countCarsType === 'avg'
                ? countCarsAvg > 0
                  ? new Intl.NumberFormat('ru-RU').format(
                      Math.trunc(getSum(report, 'total') / countCarsAvg)
                    )
                  : 0
                : new Intl.NumberFormat('ru-RU').format(getTotalCars(report))}
            </TableCell>
            <TableCell align="right" style={{ fontWeight: 'bold' }}>
              {new Intl.NumberFormat('ru-RU', {
                style: 'currency',
                currency: 'RUB',
              }).format(getSum(report, 'total'))}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
