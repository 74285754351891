import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  required,
} from 'react-admin'

import { validateTextField, validateBaseUrl } from './components/placeValidator'

export const PlaceCreate = (props: any) => (
  <Create {...props} title="Добавить мойку">
    <SimpleForm>
      <TextInput source="id" label="Id мойки" validate={validateTextField} />
      <TextInput
        source="baseUrl"
        label="URL адрес"
        type="url"
        validate={validateBaseUrl}
        parse={(value: string) => (value ? value.trim() : '')}
      />
      <TextInput
        source="name"
        label="Название мойки"
        validate={validateTextField}
      />
      <TextInput source="address" label="Адрес" validate={validateTextField} />
      <TextInput
        source="phoneNumber"
        label="Номер телефона"
        validate={validateTextField}
        parse={(value: string) => (value ? value.trim() : '')}
      />
      <NumberInput
        source="latitude"
        label="Координаты - широта"
        validate={validateTextField}
      />
      <NumberInput
        source="longitude"
        label="Координаты - долгота"
        validate={validateTextField}
      />
      <TextInput
        source="operatorPhoneNumber"
        label="Номер оператора"
        parse={(value: string) => (value ? value.trim() : '')}
      />
      <NumberInput
        source="maxSpentTime"
        label="Макс. время на посту (мин)"
        format={(value: any) => (value ? value / 1000 / 60 : 0)}
        parse={(value: number) => value * 1000 * 60}
      />
      <BooleanInput source="showInApp" label="Отображать в приложении" />
      <ArrayInput source="posts" label="Посты">
        <SimpleFormIterator>
          <TextInput
            source=""
            label="Id поста"
            parse={(value: string) => (value ? value.trim() : '')}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
)
