import { FunctionField, InputProps, Loading, useAuthState } from 'react-admin'
import { getCarwashSum } from './functions/getSum'
import useDetailsHook from './hooks/useDetailsHook'
import React from 'react'
import { Redirect } from 'react-router'

export const CarwashSumTextField = ({ record }: InputProps) => {
  const { encashmentAmount } = useDetailsHook(record)

  /**
   * This hook is secure access to the page
   */
  const { loading, authenticated } = useAuthState()

  if (loading) {
    return <Loading />
  }

  if (authenticated) {
    return (
      <FunctionField
        label="Сумма"
        render={() => getCarwashSum(encashmentAmount)}
      />
    )
  }

  return <Redirect to="/login" />
}
