import {
  CarwashAdminEncashment,
  EncashmentAmounts,
  ReportEncashmentAmount,
} from '../../../interfaces'
import { useEffect, useState } from 'react'
import InMemoryJWT from '../../../../../../Auth/inMemoryJWT'
import axios from 'axios'
import { apiUrl } from '../../../../../../helpers/config'

type Props = {
  checkedPlaces: string[]
  startDate: string
  endDate: string
}

const useReportHook = ({ checkedPlaces, startDate, endDate }: Props) => {
  const [reportEncashmentAmount, setReportEncashmentAmount] = useState<
    ReportEncashmentAmount[] | null
  >(null)
  const [encashmentData, setEncashmentData] = useState<
    CarwashAdminEncashment[] | null
  >(null)

  // const { data } = useGetList(
  //   'encashment',
  //   { page: 1, perPage: 1000000 },
  //   { field: 'carwashId', order: 'DESC' },
  //   {
  //     carwashId: checkedPlaces,
  //     last_seen_gte: new Date(startDate).toISOString(),
  //     last_seen_lte: new Date(endDate).toISOString(),
  //   },
  //   { enabled: !!InMemoryJWT.getToken() }
  // )

  useEffect(() => {
    ;(async () => {
      if (encashmentData) return
      let url = `${apiUrl}/admin/encashment?_start=0&_end=1000000&_sort=id&_order=DESC&last_seen_gte=${new Date(
        startDate
      ).toISOString()}&last_seen_lte=${new Date(endDate).toISOString()}`
      checkedPlaces.forEach((carwashId) => {
        url += `&carwashId=${carwashId}`
      })
      const result = await axios.get<CarwashAdminEncashment[]>(url, {
        headers: { Authorization: `Bearer ${InMemoryJWT.getToken()}` },
        timeout: 30000,
      })
      setEncashmentData(result.data)
    })()
  }, [checkedPlaces, encashmentData, endDate, startDate])

  useEffect(() => {
    if (encashmentData) {
      ;(async () => {
        try {
          const result = await Promise.all(
            encashmentData.map(async (item) => {
              const token = InMemoryJWT.getToken()
              if (!token) await InMemoryJWT.getRefreshedToken()

              const data = await axios.get<EncashmentAmounts[]>(
                `${apiUrl}/admin/encashment/getEncashmentAmounts?encashmentId=${item.item.id}`,
                {
                  headers: {
                    Authorization: `Bearer ${InMemoryJWT.getToken()}`,
                  },
                  timeout: 30000,
                }
              )
              return {
                carwashId: item.carwashId,
                dateTime: item.item.datetime,
                data: data.data,
              }
            })
          )
          /** убираем пустые инкассации */
          const filteredResult = result.filter((item) => item.data.length > 0)

          const carwashNames = [
            ...new Set(filteredResult.map((name) => name.carwashId)),
          ]

          const carwashEncashmentAmounts = carwashNames.map((carwashId) => {
            /** список инкассаций по выбранной мойке */
            const sortedCarwashRecords = filteredResult.filter(
              (carwash) => carwash.carwashId === carwashId
            )

            /** список уникальных дат */
            const datesList = [
              ...new Set(
                sortedCarwashRecords.map((record) =>
                  new Date(record.dateTime).toLocaleDateString()
                )
              ),
            ]

            const data = datesList.map((date) => {
              const filteredCarwashRecords = sortedCarwashRecords.filter(
                (record) =>
                  date === new Date(record.dateTime).toLocaleDateString()
              )
              /** список уникальных устройств */
              const deviceNameList = [
                ...new Set(
                  ...filteredCarwashRecords.map((record) =>
                    record.data.map((recordData) => recordData.item.device_name)
                  )
                ),
              ].sort()

              const encashments = filteredCarwashRecords.map(
                (carwashRecord) => {
                  const devices = deviceNameList.map((deviceName) => {
                    const sortedRecordItem = carwashRecord.data.map(
                      (carwashRecordItem) => carwashRecordItem.item
                    )
                    const filteredDeviceList = sortedRecordItem.filter(
                      (item) => deviceName === item.device_name
                    )
                    const encashmentAmounts = filteredDeviceList.map(
                      (encashmentItem) => ({
                        denomination: encashmentItem.denomination,
                        amount: encashmentItem.amount,
                      })
                    )
                    /** суммируем дубликаты */
                    const denominationList = [
                      ...new Set(
                        encashmentAmounts.map((item) => item.denomination)
                      ),
                    ]
                    const encashmentAmountsResult = denominationList.map(
                      (denomination) => {
                        const encashmentList = encashmentAmounts.filter(
                          (encashmentItem) =>
                            encashmentItem.denomination === denomination
                        )
                        return {
                          denomination,
                          amount: encashmentList.reduce(
                            (prev, currentValue) => prev + currentValue.amount,
                            0
                          ),
                        }
                      }
                    )

                    return {
                      deviceName,
                      encashmentAmounts: encashmentAmountsResult,
                    }
                  })
                  return {
                    postTitle: carwashRecord.data[0].item.post_title,
                    dateTime: carwashRecord.dateTime,
                    devices,
                  }
                }
              )

              return {
                dateTime: filteredCarwashRecords[0].dateTime,
                deviceNameList,
                encashments,
              }
            })

            return { carwashId, data }
          })
          setReportEncashmentAmount(carwashEncashmentAmounts)
        } catch (error) {
          // show error
          console.log('get encashment amounts error: ', error)
          setReportEncashmentAmount([])
        }
      })()
    }
  }, [encashmentData])

  return { reportEncashmentAmount }
}

export default useReportHook
