import { CorpOrganizationsList } from './CorpOrganizationsList'
import { CorpOrganizationsCreate } from './CorpOrganizationsCreate'
import { CorpOrganizationsEdit } from './CorpOrganizationsEdit'
import { CorpOrganizationsShow } from './CorpOrganizationsShow'

export default {
  list: CorpOrganizationsList,
  create: CorpOrganizationsCreate,
  edit: CorpOrganizationsEdit,
  show: CorpOrganizationsShow,
}
