import React from 'react'
import {
  DeleteButton,
  Edit,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../../casl/Can'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const CustomToolbar = (props: ToolbarProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      {ability.can('delete', 'carCounter') && <DeleteButton />}
    </Toolbar>
  )
}

export const CarCounterDeviceEdit = (props: any) => (
  <Edit {...props} title="Редактирование списка устройств">
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput source="deviceId" label="Id устройства" />
      <ReferenceInput reference="places" source="carwashId" label="мойка">
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <TextInput source="postNumber" label="Номер поста" />
      <SelectInput
        source="type"
        label="Тип устройства"
        choices={[
          { id: 'carCounter', name: 'Счетчик машин' },
          { id: 'waterDispenser', name: 'Рукомойник' },
        ]}
      />
      <TextInput source="deviceName" label="Название" />
    </SimpleForm>
  </Edit>
)
