import { makeStyles } from '@material-ui/core/styles'
import {
  BooleanInput,
  DeleteButton,
  Edit,
  NumberInput,
  SaveButton,
  TextInput,
  Toolbar,
  ToolbarProps,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
} from 'react-admin'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../../casl/Can'
import React from 'react'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const CustomToolbar = (props: ToolbarProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      {ability.can('delete', 'corpUsers') && <DeleteButton />}
    </Toolbar>
  )
}

export const CorpUsersEdit = (props: any) => {
  const ability = useAbility(AbilityContext)
  return (
    <Edit {...props} title="Редактирование корп. пользователя">
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput
          source="firstName"
          label="Имя"
          format={(value: string) => (value ? value.trim() : '')}
        />
        <TextInput
          source="lastName"
          label="Фамилия"
          format={(value: string) => (value ? value.trim() : '')}
        />
        <ReferenceArrayInput
          label="Мойки"
          reference="places"
          source="carwash"
          isRequired={true}
        >
          <AutocompleteArrayInput />
        </ReferenceArrayInput>
        <NumberInput source="dayLimit" label="Лимит (день)" />
        <NumberInput source="monthLimit" label="Лимит (месяц)" />
        {ability.can('read', 'corpOrganizations') && (
          <ReferenceInput
            reference="corpOrganizations"
            source="organizationId"
            label="Организация"
          >
            <SelectInput optionText="name" optionValue="id" />
          </ReferenceInput>
        )}
        <BooleanInput source="isPaymentEnabled" label="Платежи включены" />
      </SimpleForm>
    </Edit>
  )
}
