import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'

export const BonusCreate = (props: any) => {
  return (
    <Create {...props} title="Добавить бонусы">
      <SimpleForm>
        <ReferenceInput
          label="Клиент (номер телефона)"
          source="userId"
          reference="users"
          filterToQuery={(phoneNumber) => ({ phoneNumber: [phoneNumber] })}
          validate={required()}
        >
          <AutocompleteInput
            translateChoice={false}
            optionText={(choice) =>
              `${choice ? choice.name : ''} - ${
                choice ? choice.phoneNumber : ''
              }`
            }
          />
        </ReferenceInput>
        <NumberInput
          source="amount"
          label="Количество бонусов"
          validate={required()}
        />
        <TextInput
          source="description"
          label="Описание"
          inputProps={{ maxlength: '25' }}
          validate={required()}
        />
      </SimpleForm>
    </Create>
  )
}
