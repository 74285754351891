import React, { useEffect, useState } from 'react'
import {
  useQuery,
  Loading,
  Error,
  useMutation,
  useRedirect,
  EditProps,
} from 'react-admin'
import { actionsList, subjectsList, subjectTitles } from './constants'
import { AbilityCheckboxTable } from './components/AbilityCheckboxTable'
import { CustomTextInput } from './components/CustomTextInput'
import { SaveButton } from './components/SaveButton'

export const RolesEdit = (props: EditProps) => {
  const redirect = useRedirect()
  const [currentAbilities, setCurrentAbilities] = useState(null)
  const [textInputValue, setTextInputValue] = useState('')
  const [descriptionField, setDescriptionField] = useState('')

  const { data, loading, error } = useQuery({
    type: 'getOne',
    resource: 'roles',
    payload: { id: props.id },
  })

  const getMutationData = () => {
    if (!currentAbilities) return null
    const { createDate, updateDate, ...rest } = data
    return {
      ...rest,
      abilities: actionsList.map((action) => ({
        action,
        subjects: currentAbilities[action],
      })),
      name: textInputValue,
      description: descriptionField,
    }
  }

  useEffect(() => {
    if (data && !textInputValue) setTextInputValue(data.name)
  }, [data])

  const [approve, mutationStatus] = useMutation(
    {
      type: 'update',
      resource: 'roles',
      payload: {
        id: props.id,
        data: getMutationData(),
      },
    },
    {
      onSuccess: () => redirect('/roles'),
    }
  )

  if (loading) return <Loading />
  if (error) return <Error error={error} />
  if (mutationStatus.error) return <Error error={mutationStatus.error} />
  return (
    <div>
      <CustomTextInput
        label="Название"
        value={textInputValue}
        onChangeHandler={setTextInputValue}
      />
      <CustomTextInput
        label="Описание"
        value={descriptionField}
        onChangeHandler={setDescriptionField}
      />
      <AbilityCheckboxTable
        actionsList={actionsList}
        subjectsList={subjectsList}
        subjectTitles={subjectTitles}
        currentAbilities={currentAbilities}
        setCurrentAbilities={setCurrentAbilities}
        record={data}
      />
      <SaveButton onPressHandler={approve} />
    </div>
  )
}
