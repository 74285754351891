import React from 'react'

import Container from '@material-ui/core/Container'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import ListMUI from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import TollIcon from '@material-ui/icons/Toll'
import PaymentIcon from '@material-ui/icons/Payment'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import { InputProps } from 'react-admin'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
})

export const DetailsField = ({ record }: InputProps) => {
  const classes = useStyles()
  const data = JSON.parse(record.details)
  return (
    <Container maxWidth="sm">
      <Card className={classes.root}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Статус платежа -{' '}
            <span
              style={
                data.status === 'succeeded'
                  ? { color: 'green' }
                  : { color: 'red' }
              }
            >
              {data.status}
            </span>
          </Typography>
          <Typography variant="h5" component="h2">
            Сумма - {data.amount.value} {data.amount.currency}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            с учетом комиссии {data.income_amount.value}{' '}
            {data.income_amount.currency}
          </Typography>
          <Divider style={{ marginBottom: 10 }} />
          <ListMUI dense={true}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AccountBalanceIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Банк"
                secondary={data.payment_method.card.issuer_name}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <TollIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Платежная система"
                secondary={data.payment_method.card.card_type}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <PaymentIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Номер карты"
                secondary={`${data.payment_method.card.first6}........${data.payment_method.card.last4}`}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <QueryBuilderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Срок действия"
                secondary={`${data.payment_method.card.expiry_month} / ${data.payment_method.card.expiry_year}`}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <SaveAltIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Метод оплаты сохранен"
                secondary={
                  <span
                    style={
                      data.payment_method.saved
                        ? { color: 'green' }
                        : { color: 'red' }
                    }
                  >
                    {data.payment_method.saved ? 'да' : 'нет'}
                  </span>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </ListMUI>
        </CardContent>
      </Card>
    </Container>
  )
}
