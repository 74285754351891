import React, { ChangeEvent, useState } from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Place } from '../interfaces'
import { CarwashCheckboxFormGroup } from './StepContent/CarwashCheckboxFormGroup'
import { CarwashChooseDateRange } from './StepContent/CarwashChooseDateRange'
import { EncashmentReportResult } from './StepContent/EncashmentReportResult'
import { parseDate } from './StepContent/functions/parseDate'

const steps = ['Выберите мойку', 'Выберите период']

type Props = {
  places: Place[]
}

export const EncashmentStepper = ({ places }: Props) => {
  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = useState(new Set<number>())

  const [checkedPlaces, setCheckedPlaces] = useState<string[]>([])
  const [startDate, setStartDate] = useState(
    parseDate(Date.now() - 30 * 24 * 60 * 60 * 1000)
  )
  const [endDate, setEndDate] = useState(parseDate(Date.now()))

  const checkedPlaceHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const placeIndex = checkedPlaces.findIndex((item) => item === e.target.name)
    if (e.target.checked) {
      if (placeIndex >= 0) return
      setCheckedPlaces((prev) => [...prev, e.target.name])
    } else {
      if (placeIndex === -1) return
      setCheckedPlaces((prev) => {
        const places = [...prev]
        places.splice(placeIndex, 1)
        return places
      })
    }
  }

  const getStepContent = (activeStep: number) => {
    switch (activeStep) {
      case 0:
        return (
          <CarwashCheckboxFormGroup
            places={places}
            checkedPlaces={checkedPlaces}
            onChangeHandler={checkedPlaceHandler}
          />
        )
      case 1:
        return (
          <CarwashChooseDateRange
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        )
    }
  }

  const isStepOptional = (step: number) => {
    return false
  }

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.")
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const isDisabled = (): boolean => {
    if (activeStep === 0) return checkedPlaces.length === 0
    if (activeStep === 1) return !startDate || !endDate
    return true
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} style={{ maxWidth: 600 }}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            )
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <EncashmentReportResult
            checkedPlaces={checkedPlaces}
            startDate={startDate}
            endDate={endDate}
            places={places}
          />
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box />
            <Button onClick={handleReset}>Новый отчет</Button>
          </Box>
        </>
      ) : (
        <React.Fragment>
          {getStepContent(activeStep)}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Назад
            </Button>
            <Box />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}
            <Button onClick={handleNext} disabled={isDisabled()}>
              {activeStep === steps.length - 1 ? 'сформировать отчет' : 'Далее'}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  )
}
