import React, { useEffect, useRef, useState } from 'react'
import classes from './EncashmentReport.module.sass'
import Paper from '@mui/material/Paper'
import { Loading, Title, useAuthState, useQueryWithStore } from 'react-admin'
import { Redirect } from 'react-router'
import { EncashmentStepper } from './components/EncashmentStepper'
import { Place } from './interfaces'

export const EncashmentReport = () => {
  const [places, setPlaces] = useState<Place[]>()
  const usersCarwash = useRef<string[]>([])

  useEffect(() => {
    const userProfile = localStorage.getItem('profile')
    if (!userProfile) return
    const profileData = JSON.parse(userProfile)
    usersCarwash.current = profileData.carwash
  }, [])

  const placesList = useQueryWithStore({
    type: 'getList',
    resource: 'places',
    payload: {
      pagination: {
        page: 1,
        perPage: 1000,
      },
      sort: {
        field: 'id',
        order: 'ASC',
      },
      filter: {},
    },
  })

  useEffect(() => {
    if (places || !placesList || !placesList.data) return
    const filteredPlacesList = placesList.data
      .filter((item: any) =>
        usersCarwash.current.find((carwash) => carwash === item.id)
      )
      .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
    setPlaces(filteredPlacesList)
  }, [places, placesList])
  /**
   * This hook is secure access to the page
   */
  const { loading, authenticated } = useAuthState()

  if (loading || !places || !usersCarwash.current) {
    return <Loading />
  }
  if (authenticated) {
    return (
      <div className={classes.Container}>
        <Title title="Отчет по инкассациям" />
        <Paper elevation={2} className={classes.Paper}>
          <EncashmentStepper places={places} />
        </Paper>
      </div>
    )
  }

  return <Redirect to="/login" />
}
