import React from 'react'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import StarBorder from '@mui/icons-material/StarBorder'
import ListItem from '@mui/material/ListItem'
import { CarwashEncashment } from '../interfaces'

export const PostAmountListItem = ({
  postTitle,
  postEncashment,
  postSum,
}: CarwashEncashment) => {
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', pl: 1 }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      dense={true}
    >
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary={`${postTitle} - ${postSum}₽`} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense={true}>
          {postEncashment.map((item) => (
            <ListItem sx={{ pl: 3 }}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary={`${item.device_name} - ${item.amount}₽`} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </List>
  )
}
