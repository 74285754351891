import React from 'react'
import CardContent from '@mui/material/CardContent'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Collapse } from '@mui/material'
import { CarwashPostAmount } from './CarwashPostAmount'
import Card from '@mui/material/Card'
import { Place } from '../../reports/EncashmentReport/interfaces'
import { CarwashEncashment } from '../interfaces'
import { HeaderComponent } from './HeaderComponent'

type Props = {
  currentPlace: Place
  getCarwashSum: (carwashId: string) => number
  getEncashmentAmountsByCarwashId: (carwashId: string) => CarwashEncashment[]
  getSumByDeviceName: (carwashId: string) => any
}

export const CarwashListItem = ({
  currentPlace,
  getCarwashSum,
  getEncashmentAmountsByCarwashId,
  getSumByDeviceName,
}: Props) => {
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <Card
      sx={{
        minWidth: 275,
        marginRight: 3,
        marginBottom: 3,
        // backgroundColor: 'orange', // change color when record is too old
      }}
    >
      <CardContent>
        <List
          sx={{
            width: '100%',
            maxWidth: 360,
            bgcolor: 'background.paper',
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          dense={true}
        >
          <ListItemButton
            onClick={handleClick}
            style={{ borderBottom: open ? '1px solid #000000' : 'none' }}
          >
            <ListItemText
              primary={
                <HeaderComponent
                  title={currentPlace.name}
                  getSumByDeviceName={getSumByDeviceName}
                  carwashId={currentPlace.id}
                  getCarwashSum={getCarwashSum}
                />
              }
            />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <CarwashPostAmount
              carwashId={currentPlace.id}
              getEncashmentAmountsByCarwashId={getEncashmentAmountsByCarwashId}
            />
          </Collapse>
        </List>
      </CardContent>
    </Card>
  )
}
