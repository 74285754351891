import React from 'react'
import classes from './ThermometerGauge.module.sass'
// @ts-ignore
import Thermometer from 'react-thermometer-component'

export const ThermometerGauge = ({ value, title }: any) => {
  return (
    <div className={classes.Container}>
      <Thermometer
        theme="light"
        value={value}
        max={50}
        // steps="2"
        format="°C"
        size="small"
        height="100"
      />
      <div className={classes.Title}>{title}</div>
    </div>
  )
}
