import { useCallback, useEffect, useState } from 'react'
import { useQueryWithStore } from 'react-admin'
import { FinanceReportInterface, PlacesInterface } from '../interfaces'
import { parseDate } from '../../EncashmentReport/components/StepContent/functions/parseDate'
import { getCommonFinanceReport } from '../functions/api'
import { getCarwashName } from '../functions/getCarwashName'

const useFinanceReportHook = () => {
  const placesReq = useQueryWithStore({
    type: 'getList',
    resource: 'places',
    payload: {
      pagination: {
        page: 1,
        perPage: 100000,
      },
      sort: {
        field: 'id',
        order: 'ASC',
      },
      filter: {},
    },
  })

  const [isLoading, setIsLoading] = useState(false)
  const [places, setPlaces] = useState<PlacesInterface[]>([])
  const [lastSeenLte, setLastSeenLte] = useState<string>(parseDate(Date.now()))
  const [lastSeenGte, setLastSeenGte] = useState<string>(
    parseDate(new Date().setHours(0, 0, 0, 0))
  )
  const [currentCarwashList, setCurrentCarwashList] = useState<string[]>([])
  const [countCarsType, setCountCarsType] = useState<string>('sensor')
  const [countCarsAvg, setCountCarsAvg] = useState(0)
  const [financeReport, setFinanceReport] = useState<FinanceReportInterface[]>(
    []
  )
  const [dateTimeError, setDateTimeError] = useState(false)
  const [toggleDateRangeValue, setToggleDateRangeValue] = useState('today')
  const [flag, setFlag] = useState(false)

  useEffect(() => {
    if (!placesReq.data || places.length > 0) return
    setPlaces(placesReq.data)
    const carwashList: string[] = [
      ...new Set<string>(placesReq.data.map((item: any) => item.id)),
    ]
    setCurrentCarwashList(carwashList)
  }, [places.length, placesReq.data])

  const getReport = useCallback(() => {
    if (currentCarwashList.length === 0 || !placesReq.data) return
    setIsLoading(true)
    getCommonFinanceReport({
      carwashId: currentCarwashList,
      lastSeenLte: lastSeenLte,
      lastSeenGte: lastSeenGte,
    })
      .then((result) => {
        const missingCarwashList = currentCarwashList.filter((carwashId) => {
          const carwashItem = result.find(
            (item) => item.carwashId === carwashId
          )
          return !carwashItem
        })
        if (missingCarwashList.length > 0)
          missingCarwashList.forEach((item) =>
            result.push({
              carwashId: item,
              data: [],
              carwashTotal: 0,
              carwashCarCounterTotal: 0,
            })
          )

        const docs = result.map((item) => {
          const carwashName = getCarwashName({
            places,
            carwashId: item.carwashId,
          })
          return { ...item, carwashName }
        })
        setIsLoading(false)
        setFinanceReport(docs)
      })
      .catch(() => setIsLoading(false))
  }, [currentCarwashList, lastSeenGte, lastSeenLte, places, placesReq.data])

  useEffect(() => {
    if (
      currentCarwashList.length === 0 ||
      !placesReq.data ||
      financeReport.length > 0 ||
      flag
    )
      return
    setFlag(true)
    getReport()
  }, [
    currentCarwashList.length,
    financeReport.length,
    flag,
    getReport,
    placesReq.data,
  ])

  useEffect(() => {
    const threshold = 2 * 30 * 24 * 60 * 60 * 1000
    const differenceTime =
      new Date(lastSeenLte).getTime() - new Date(lastSeenGte).getTime()
    differenceTime > threshold
      ? setDateTimeError(true)
      : setDateTimeError(false)
  }, [lastSeenLte, lastSeenGte])

  return {
    isLoading,
    places,
    lastSeenLte,
    setLastSeenLte,
    lastSeenGte,
    setLastSeenGte,
    currentCarwashList,
    setCurrentCarwashList,
    countCarsType,
    setCountCarsType,
    countCarsAvg,
    setCountCarsAvg,
    financeReport,
    getReport,
    dateTimeError,
    toggleDateRangeValue,
    setToggleDateRangeValue,
  }
}

export default useFinanceReportHook
