import React from 'react'
import { InputProps } from 'react-admin'

const msToTime = (duration: any) => {
  const msDuration = duration * 1000
  let seconds: number | string = parseInt(String((msDuration / 1000) % 60))
  let minutes: number | string = parseInt(
    String((msDuration / (1000 * 60)) % 60)
  )
  let hours: number | string = parseInt(
    String((msDuration / (1000 * 60 * 60)) % 24)
  )

  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds

  return `${hours}:${minutes}:${seconds}`
}

export const SpentTimeField = ({ record }: InputProps) => {
  return <div>{msToTime(record.spentTime)}</div>
}
