import {
  BooleanInput,
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import React from 'react'

export const CorpOrganizationsCreate = (props: any) => (
  <Create {...props} title="Добавить мойку">
    <SimpleForm>
      <TextInput
        source="name"
        label="Название"
        format={(value: string) => (value ? value.trim() : '')}
      />
      <BooleanInput source="isEnabled" label="Активна" />
      <NumberInput source="creditLimit" label="Кредитный лимит" />
      <NumberInput source="balance" label="Баланс" />
    </SimpleForm>
  </Create>
)
