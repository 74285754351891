import React from 'react'
import {
  ListProps,
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  FunctionField,
} from 'react-admin'
import { CarwashDateFilterSidebar } from '../../../components/CarwashDateFilter/CarwashDateFilterSidebar'
import { CustomPagination } from '../../../components/CustomPagination/CustomPagination'
import { TopDateTimeFilter } from '../../../components/TopDateTimeFilter/TopDateTimeFilter'

export const MachineHoursList = (props: ListProps) => (
  <List
    {...props}
    title="Моточасы - список"
    bulkActionButtons={false}
    aside={<CarwashDateFilterSidebar />}
    pagination={<CustomPagination />}
    sort={{ field: 'timestamp', order: 'DESC' }}
    filters={TopDateTimeFilter}
  >
    <Datagrid>
      <ReferenceField source="carwashId" reference="places" label="Мойка">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="postTitle" label="Пост" />
      <TextField source="deviceName" label="Имя устройства" />
      <FunctionField
        label="Время работы (часы)"
        render={(record: any) =>
          `${record.amount ? Math.round(record.amount / 60 / 60) : '-'}`
        }
      />
      <DateField source="timestamp" label="Дата" />
    </Datagrid>
  </List>
)
