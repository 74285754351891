import { CorpUsersList } from './CorpUsersList'
import { CorpUsersCreate } from './CorpUsersCreate'
import { CorpUsersEdit } from './CorpUsersEdit'
import { CorpUsersShow } from './CorpUsersShow'

export default {
  list: CorpUsersList,
  create: CorpUsersCreate,
  edit: CorpUsersEdit,
  show: CorpUsersShow,
}
