import React, { useState } from 'react'
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  ReferenceManyField,
  Datagrid,
  ReferenceField,
  NumberField,
  ShowButton,
  Pagination,
  ShowProps,
} from 'react-admin'

import { AmountField } from '../transaction/components/AmountField'
import { UserShowTitle } from './components/UserShowTitle'
import { UserShowButton } from './components/UserShowButton'
import { TemporaryDrawer } from './components/TemporaryDrawer'

export const UserShow = (props: ShowProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentRecord, setCurrentRecord] = useState()
  return (
    <Show {...props} title={<UserShowTitle source="" />}>
      <TabbedShowLayout>
        <Tab label="Информация">
          <TextField source="name" label="Имя" />
          <TextField source="lastName" label="Фамилия" />
          <TextField source="thirdName" label="Отчество" />
          <TextField source="birthDate" label="Дата рождения" />
          <TextField source="phoneNumber" label="Номер телефона" />
          <DateField source="date" label="Дата регистрации" />
        </Tab>
        <Tab label="Обратная связь">
          <div />
        </Tab>
        <Tab label="Бонусы">
          <ReferenceManyField
            sort={{ field: 'timestamp', order: 'DESC' }}
            reference="bonus"
            target="userId"
            addLabel={false}
            pagination={<Pagination />}
            perPage={10}
          >
            <Datagrid>
              <ReferenceField
                label="Начислил бонус"
                source="adminId"
                reference="users"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="amount" label="Сумма" />
              <NumberField source="description" label="Описание" />
              <DateField source="timestamp" showTime={true} label="Дата" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="История моек">
          <ReferenceManyField
            sort={{ field: 'timestamp', order: 'DESC' }}
            reference="transactions"
            target="userId"
            addLabel={false}
            pagination={<Pagination />}
            perPage={10}
          >
            <Datagrid>
              <TextField source="address" label="Адрес" />
              <AmountField label="Сумма" source="" />
              <DateField source="timestamp" showTime={true} label="Дата" />
              <UserShowButton
                setIsOpen={setIsOpen}
                setCurrentRecord={setCurrentRecord}
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <TemporaryDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          record={currentRecord}
        />
      </TabbedShowLayout>
    </Show>
  )
}
