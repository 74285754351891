import useChooseDashboardHook from './hooks/useChooseDashboardHook'
import Dashboard from './Dashboard'
import { Loading } from 'react-admin'
import * as React from 'react'

const ChooseDashboard = () => {
  const { isCorpUser } = useChooseDashboardHook()

  if (isCorpUser === null) return <Loading />

  if (isCorpUser) return <div>пока здесь пусто</div>
  return <Dashboard />
}

export default ChooseDashboard
