import { useEffect, useState } from 'react'
import axios from 'axios'
import { apiUrl } from '../../../helpers/config'
import InMemoryJWT from '../../../Auth/inMemoryJWT'
import useInterval from '@use-it/interval'

const useAppBarHook = () => {
  const [balance, setBalance] = useState<number | null>(null)
  const [delay, setDelay] = useState<number | null>(null)

  const getCurrentBalance = async () => {
    try {
      const profile = localStorage.getItem('profile')
      if (!profile) return

      const { organization } = JSON.parse(profile)
      if (!organization) return
      setDelay(60000)

      const token = InMemoryJWT.getToken()
      if (!token) return

      const result = await axios(
        `${apiUrl}/admin/corpOrganizations/${organization}`,
        {
          timeout: 5000,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      setBalance(result.data.balance)
    } catch (error) {
      console.log('get current balance error: ', error)
    }
  }

  useEffect(() => {
    setTimeout(getCurrentBalance, 100)
    // getCurrentBalance().then()
  }, [])

  useInterval(getCurrentBalance, delay)

  return { balance }
}

export default useAppBarHook
