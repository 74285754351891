import React from 'react'
import { FilterList, FilterListItem } from 'react-admin'
import Filter1Icon from '@material-ui/icons/Filter1'

const postList = [
  {
    name: 'Пост 0',
    postNumber: 0,
  },
  {
    name: 'Пост 1',
    postNumber: 1,
  },
  {
    name: 'Пост 2',
    postNumber: 2,
  },
  {
    name: 'Пост 3',
    postNumber: 3,
  },
  {
    name: 'Пост 4',
    postNumber: 4,
  },
  {
    name: 'Пост 5',
    postNumber: 5,
  },
  {
    name: 'Пост 6',
    postNumber: 6,
  },
  {
    name: 'Пост 7',
    postNumber: 7,
  },
]

export const PostFilter = () => {
  return (
    <FilterList label="Номер поста" icon={<Filter1Icon />}>
      {postList.map((item, index) => (
        <FilterListItem
          label={item.name}
          value={{
            postNumber: item.postNumber,
          }}
          key={index}
        />
      ))}
    </FilterList>
  )
}
