import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  CreateProps,
} from 'react-admin'

export const UserCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" label="Имя" />
      <TextInput source="lastName" label="Фамилия" />
      <TextInput source="thirdName" label="Отчество" />
      <DateInput source="birthDate" label="Дата рождения" />
      <TextInput source="phoneNumber" label="Номер телефона" />
      <DateInput source="date" disabled={true} label="Дата регистрации" />
    </SimpleForm>
  </Create>
)
