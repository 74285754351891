import React from 'react'
import { InputProps } from 'react-admin'

export const ServiceList = ({ record }: InputProps) => {
  if (record && record.serviceInfo.length === 0) return null
  return (
    <ul style={{ paddingLeft: 0 }}>
      {record.serviceInfo.map((item: any) => (
        <li key={item._id}>
          {item.title} - {Math.round(parseFloat(String(item.cost / 100)))}
          {/*{item.title} - {parseFloat(String(item.cost / 100)).toFixed(2)}*/}
        </li>
      ))}
    </ul>
  )
}
