import { useQueryWithStore } from 'react-admin'
import { useCallback, useEffect, useState } from 'react'
import { parseDate } from '../../../reports/EncashmentReport/components/StepContent/functions/parseDate'
import {
  PlacesInterface,
  ReportListItem,
  WaterDispenserInterface,
} from '../interfaces'
import { getWaterDispenserReport } from '../functions/api'
import { getAverageTime } from '../functions/getAverageTime'
import { getMaxTime } from '../functions/getMaxTime'
import { getTotalTime } from '../functions/getTotalTime'
import { getCleanlinessOfHands } from '../functions/getCleanlinessOfHands'
import { getAverageNumbersOfUses } from '../functions/getAverageNumbersOfUses'

const useWaterDispenserHook = () => {
  const placesReq = useQueryWithStore({
    type: 'getList',
    resource: 'places',
    payload: {
      pagination: {
        page: 1,
        perPage: 100000,
      },
      sort: {
        field: 'id',
        order: 'ASC',
      },
      filter: {},
    },
  })

  const [places, setPlaces] = useState<PlacesInterface[]>([])
  const [lastSeenLte, setLastSeenLte] = useState<string>(parseDate(Date.now()))
  const [lastSeenGte, setLastSeenGte] = useState<string>(
    parseDate(Date.now() - 30 * 24 * 60 * 60 * 1000)
  )
  const [currentCarwashList, setCurrentCarwashList] = useState<string[]>([])
  const [reportList, setReportList] = useState<ReportListItem[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [dateTimeError, setDateTimeError] = useState(false)

  useEffect(() => {
    if (!placesReq.data || places.length > 0) return
    const carwashList = placesReq.data.map((item: any) => item.id)
    setCurrentCarwashList(carwashList)
    setPlaces(placesReq.data)
  }, [places.length, placesReq.data])

  useEffect(() => {
    const threshold = 2 * 30 * 24 * 60 * 60 * 1000
    const differenceTime =
      new Date(lastSeenLte).getTime() - new Date(lastSeenGte).getTime()
    differenceTime > threshold
      ? setDateTimeError(true)
      : setDateTimeError(false)
  }, [lastSeenLte, lastSeenGte])

  const makeReportList = useCallback(
    (waterDispenserList: WaterDispenserInterface[]) => {
      const list =
        currentCarwashList.length > 0
          ? currentCarwashList
          : places.map((item) => item.id)

      const reportList = list.map((currentCarwashId) => {
        const currentWaterDispenserList = waterDispenserList.filter(
          (item) => item.carwashId === currentCarwashId
        )

        const averageTime = getAverageTime({
          waterDispenserList: currentWaterDispenserList,
        })
        const maxTime = getMaxTime({
          waterDispenserList: currentWaterDispenserList,
        })
        const totalTime = getTotalTime({
          waterDispenserList: currentWaterDispenserList,
        })
        const cleanlinessOfHands = getCleanlinessOfHands({ averageTime })
        const averageNumbersOfUses = getAverageNumbersOfUses({
          waterDispenserList: currentWaterDispenserList,
          lastSeenGte,
          lastSeenLte,
        })

        return {
          carwashId: currentCarwashId,
          averageTime,
          maxTime,
          totalTime,
          cleanlinessOfHands,
          averageNumbersOfUses,
          totalNumbersOfUses: currentWaterDispenserList.length,
        }
      })
      setReportList(reportList)
    },
    [currentCarwashList, lastSeenGte, lastSeenLte, places]
  )

  const getReport = useCallback(async () => {
    if (!placesReq.data || currentCarwashList.length === 0) return
    try {
      setIsLoading(true)
      const result = await getWaterDispenserReport({
        lastSeenLte,
        lastSeenGte,
        carwashId: currentCarwashList,
      })
      makeReportList(result)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }, [
    currentCarwashList,
    lastSeenGte,
    lastSeenLte,
    makeReportList,
    placesReq.data,
  ])

  return {
    lastSeenLte,
    setLastSeenLte,
    lastSeenGte,
    setLastSeenGte,
    places,
    currentCarwashList,
    setCurrentCarwashList,
    isLoading,
    dateTimeError,
    reportList,
    getReport,
  }
}

export default useWaterDispenserHook
