import { WaterDispenserInterface } from '../interfaces'

type Props = {
  waterDispenserList: WaterDispenserInterface[]
  lastSeenGte: string
  lastSeenLte: string
}

const getNumberOfDays = (start: string, end: string): number => {
  const date1 = new Date(start)
  const date2 = new Date(end)
  const oneDay = 1000 * 60 * 60 * 24
  const diffInTime = date2.getTime() - date1.getTime()
  return Math.round(diffInTime / oneDay)
}

export const getAverageNumbersOfUses = ({
  waterDispenserList,
  lastSeenGte,
  lastSeenLte,
}: Props): number => {
  const diffDays = getNumberOfDays(lastSeenGte, lastSeenLte)
  return Math.round(waterDispenserList.length / diffDays)
}
