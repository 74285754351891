import inMemoryJWT from '../Auth/inMemoryJWT'
import {
  CreateParams,
  fetchUtils,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { apiUrl } from '../helpers/config'
import jsonServerProvider from 'ra-data-json-server'
import { CreateResult, Record } from 'ra-core'

const fetchJson = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  const token = inMemoryJWT.getToken()
  if (token) {
    options.headers.set('Authorization', `Bearer ${token}`)
    return fetchUtils.fetchJson(url, options)
  } else {
    inMemoryJWT.setRefreshTokenEndpoint(`${apiUrl}/refresh/cookie`)
    return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
      if (gotFreshToken) {
        options.headers.set('Authorization', `Bearer ${inMemoryJWT.getToken()}`)
      }
      return fetchUtils.fetchJson(url, options)
    })
  }
}
const dataProvider = jsonServerProvider(`${apiUrl}/admin`, fetchJson)

const myDataProvider = () => {
  return {
    ...dataProvider,
    create: <RecordType extends Record = Record>(
      resource: string,
      params: CreateParams
    ): Promise<CreateResult<RecordType>> => {
      if (resource !== 'es-common-config') {
        return dataProvider.create(resource, params)
      }
      const photo = params.data.photo.rawFile
      let formData = new FormData()
      formData.append('carwashId', params.data.carwashId)
      formData.append('photo', photo, photo.name)
      return fetchJson(`${apiUrl}/admin/${resource}`, {
        method: 'POST',
        body: formData,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }))
    },
    update: <RecordType extends Record = Record>(
      resource: string,
      params: UpdateParams
    ): Promise<UpdateResult<RecordType>> => {
      try {
        if (resource !== 'es-common-config') {
          return dataProvider.update(resource, params)
        }
        const photo = params.data.photo.rawFile
        let formData = new FormData()
        formData.append('id', params.id.toString())
        formData.append('carwashId', params.data.carwashId)
        if (photo) formData.append('photo', photo, photo.name)
        return fetchJson(`${apiUrl}/admin/${resource}/${params.id}`, {
          method: 'PUT',
          body: formData,
        }).then(({ json }) => ({
          data: { ...params.data, id: json.id },
        }))
      } catch (error) {
        console.log('update error: ', error)
        return Promise.reject(error)
      }
    },
  }
}

export default myDataProvider
