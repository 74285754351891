import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  ListProps,
  BooleanField,
} from 'react-admin'

import { DetailsField } from './components/DetailsField'
import { AmountField } from './components/AmountField'
import { DiscountField } from './components/DiscountField'

import { transactionExporter } from './components/transactionExporter'
import { CarwashDateFilterSidebar } from '../../components/CarwashDateFilter/CarwashDateFilterSidebar'
import { CustomPagination } from '../../components/CustomPagination/CustomPagination'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../casl/Can'

export const TransactionList = (props: ListProps) => {
  const ability = useAbility(AbilityContext)
  return (
    <List
      {...props}
      title="Платежи"
      sort={{ field: 'timestamp', order: 'DESC' }}
      // filterDefaultValues={{ carwashId: usersCarwash }}
      bulkActionButtons={false}
      exporter={transactionExporter}
      aside={<CarwashDateFilterSidebar />}
      pagination={<CustomPagination />}
    >
      <Datagrid
        expand={<DetailsField source="" />}
        rowClick={ability.can('update', 'specialPayment') ? 'edit' : undefined}
      >
        <TextField source="address" label="Адрес" />
        <ReferenceField
          label="Клиент"
          source="userId"
          reference="users"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <AmountField label="Сумма" source="" />
        <DiscountField label="Скидка" source="" />
        <DateField source="timestamp" showTime={true} label="Дата" />
        {ability.can('read', 'specialPayment') && (
          <BooleanField source="special" label="Особый" />
        )}
      </Datagrid>
    </List>
  )
}
