import { WaterDispenserInterface } from '../interfaces'

type Props = {
  waterDispenserList: WaterDispenserInterface[]
}

export const getMaxTime = ({ waterDispenserList }: Props): number => {
  if (waterDispenserList.length === 0) return 0
  const spentTimeList = waterDispenserList.map((item) => item.spentTime)
  return Math.max(...spentTimeList)
}
