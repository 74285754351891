import { CarCounterInterface } from '../interfaces'

type Props = {
  carCounterList: CarCounterInterface[]
  lastSeenGte: string
  lastSeenLte: string
}

const getNumberOfDays = (start: string, end: string): number => {
  const date1 = new Date(start)
  const date2 = new Date(end)
  const oneDay = 1000 * 60 * 60 * 24
  const diffInTime = date2.getTime() - date1.getTime()
  return Math.round(diffInTime / oneDay)
}

export const getCarsPerDay = ({
  carCounterList,
  lastSeenGte,
  lastSeenLte,
}: Props): number => {
  const diffDays = getNumberOfDays(lastSeenGte, lastSeenLte)
  return Math.round(carCounterList.length / diffDays)
}
