import { BotUserList } from './BotUserList'
import { BotUserEdit } from './BotUserEdit'
import { BotUserCreate } from './BotUserCreate'
import { BotUserShow } from './BotUserShow'

export default {
  list: BotUserList,
  edit: BotUserEdit,
  show: BotUserShow,
  create: BotUserCreate,
}
