import InMemoryJWT from '../../../../Auth/inMemoryJWT'
import axios from 'axios'
import { apiUrl } from '../../../../helpers/config'
import { ProgramReportAggregate } from '../interfaces'

const makeUrlParams = (
  lte: string,
  gte: string,
  carwashId?: string[]
): string => {
  let str = `?last_seen_lte=${lte}`
  str += `&last_seen_gte=${gte}`
  if (!carwashId || carwashId.length === 0) return str
  carwashId.forEach((item) => {
    str += `&carwashId=${item}`
  })
  return str
}

type Props = {
  carwashId?: string[]
  lastSeenLte: string
  lastSeenGte: string
}

export const getProgramReport = ({
  carwashId,
  lastSeenLte,
  lastSeenGte,
}: Props): Promise<ProgramReportAggregate[]> =>
  new Promise(async (resolve, reject) => {
    try {
      const token = InMemoryJWT.getToken()
      if (!token) await InMemoryJWT.getRefreshedToken()
      const result = await axios.get<ProgramReportAggregate[]>(
        `${apiUrl}/admin/reports/financeReports/programReport${makeUrlParams(
          new Date(lastSeenLte).toISOString(),
          new Date(lastSeenGte).toISOString(),
          carwashId
        )}`,
        {
          timeout: 60000,
          headers: {
            Authorization: `Bearer ${InMemoryJWT.getToken()}`,
          },
        }
      )
      resolve(result.data)
    } catch (error) {
      console.log('get common report error: ', error)
      reject(error)
    }
  })
