import { useQueryWithStore } from 'react-admin'
import { useCallback, useEffect, useState } from 'react'
import {
  CorpPaymentsInterface,
  CorpUsersInterfaces,
  CorpUsersResponse,
  PlacesInterface,
  PlacesResponse,
  SplitByDateInterface,
} from '../interfaces'
import InMemoryJWT from '../../../../Auth/inMemoryJWT'
import axios from 'axios'
import { apiUrl } from '../../../../helpers/config'
import { parseDate } from '../../../reports/EncashmentReport/components/StepContent/functions/parseDate'
import {
  CorpOrganizationInterface,
  CorpOrganizationResponse,
} from '../../../reports/EncashmentReport/interfaces'

const useCorpReportHook = () => {
  const [corpUsers, setCorpUsers] = useState<CorpUsersInterfaces[]>([])
  const [currentCorpUser, setCurrentCorpUser] = useState<string[]>([])
  const [corpPayments, setCorpPayments] = useState<SplitByDateInterface>({})
  const [lastSeenLte, setLastSeenLte] = useState<string>(parseDate(Date.now()))
  const [lastSeenGte, setLastSeenGte] = useState<string>(
    parseDate(Date.now() - 30 * 24 * 60 * 60 * 1000)
  )
  const [paymentType, setPaymentType] = useState<'income' | 'debt'>('debt')
  const [places, setPlaces] = useState<PlacesInterface[]>([])
  const [corpOrganizationList, setCorpOrganizationList] = useState<
    CorpOrganizationInterface[]
  >([])
  const [currentOrganization, setCurrentOrganization] = useState<string>('')

  const corpUsersReq = useQueryWithStore({
    type: 'getList',
    resource: 'corpUsers',
    payload: {
      pagination: {
        page: 1,
        perPage: 100000,
      },
      sort: {
        field: 'id',
        order: 'ASC',
      },
      filter: {},
    },
  })

  const makeUrlParams = (
    lte: string,
    gte: string,
    workers?: string[],
    organization?: string
  ): string => {
    let str = '?'
    str += `lte=${lte}`
    str += `&gte=${gte}`
    if (organization) str += `&organizationId=${organization}`
    if (!workers || workers.length === 0) return str
    workers.forEach((item) => {
      str += `&workers=${item}`
    })
    return str
  }

  const getCorpPayments = useCallback(async () => {
    try {
      const token = InMemoryJWT.getToken()
      if (!token) await InMemoryJWT.getRefreshedToken()

      const result = await axios.get<CorpPaymentsInterface[]>(
        `${apiUrl}/admin/corpPayments/report${makeUrlParams(
          new Date(lastSeenLte).toISOString(),
          new Date(lastSeenGte).toISOString(),
          currentCorpUser,
          currentOrganization
        )}`,
        {
          timeout: 5000,
          headers: {
            Authorization: `Bearer ${InMemoryJWT.getToken()}`,
          },
        }
      )
      const filteredByPaymentType = result.data.filter((item) => {
        return paymentType === 'income' ? item.amount > 0 : item.amount < 0
      })

      const splitByDate: SplitByDateInterface = {}
      filteredByPaymentType.forEach((item) => {
        const date = new Date(item.timestamp).toLocaleDateString('ru-RU')
        if (!splitByDate[date]) {
          splitByDate[date] = []
        }
        splitByDate[date].push(item)
      })
      setCorpPayments(splitByDate)
    } catch (error) {
      console.log('get corp payments error: ', error)
    }
  }, [
    currentCorpUser,
    currentOrganization,
    lastSeenGte,
    lastSeenLte,
    paymentType,
  ])

  useEffect(() => {
    if (!corpUsersReq.data) return
    getCorpPayments().then()
  }, [
    currentCorpUser,
    lastSeenLte,
    lastSeenGte,
    getCorpPayments,
    corpUsersReq.data,
  ])

  const getCorpUsers = useCallback(async () => {
    try {
      if (!currentOrganization) return
      const token = InMemoryJWT.getToken()
      if (!token) await InMemoryJWT.getRefreshedToken()
      let requestUrl = `${apiUrl}/admin/corpUsers?_end=10000000&_order=ASC&_sort=id&_start=0`

      if (isShowOrganizationField())
        requestUrl += `&organizationId=${currentOrganization}`

      const result = await axios.get<CorpUsersResponse[]>(requestUrl, {
        timeout: 5000,
        headers: {
          Authorization: `Bearer ${InMemoryJWT.getToken()}`,
        },
      })
      const users = result.data.map((item) => {
        return {
          id: item.id,
          name: `${item.firstName} ${item.lastName}`,
        }
      })
      setCorpUsers(users)
    } catch (error) {
      console.log('get corp users error: ', error)
    }
  }, [currentOrganization])

  const isShowOrganizationField = (): boolean => {
    try {
      const profile = localStorage.getItem('profile')
      if (!profile) return false
      const { organization } = JSON.parse(profile)
      return !organization
    } catch (error) {
      console.log('parse profile error: ', error)
      return false
    }
  }

  const getOrganizationList = useCallback(async () => {
    try {
      if (!isShowOrganizationField()) return
      const token = InMemoryJWT.getToken()
      if (!token) await InMemoryJWT.getRefreshedToken()
      const result = await axios.get<CorpOrganizationResponse[]>(
        `${apiUrl}/admin/corpOrganizations?_end=10000000&_order=ASC&_sort=id&_start=0`,
        {
          timeout: 5000,
          headers: {
            Authorization: `Bearer ${InMemoryJWT.getToken()}`,
          },
        }
      )
      const corpOrganizationListResult = result.data.map((item) => ({
        id: item.id,
        name: item.name,
      }))
      setCorpOrganizationList(corpOrganizationListResult)
    } catch (error) {
      console.log('get organization list error: ', error)
    }
  }, [])

  const getPlaces = useCallback(async () => {
    try {
      const token = InMemoryJWT.getToken()
      if (!token) await InMemoryJWT.getRefreshedToken()
      const result = await axios.get<PlacesResponse[]>(
        `${apiUrl}/admin/places?_end=10000000&_order=ASC&_sort=id&_start=0`,
        {
          timeout: 5000,
          headers: {
            Authorization: `Bearer ${InMemoryJWT.getToken()}`,
          },
        }
      )
      const users = result.data.map((item) => {
        return {
          id: item.id,
          name: item.name,
        }
      })
      setPlaces(users)
    } catch (error) {
      console.log('get places error: ', error)
    }
  }, [])

  useEffect(() => {
    if (!corpUsersReq.data) return
    getCorpUsers().then()
  }, [getCorpUsers, currentOrganization, corpUsersReq.data])

  useEffect(() => {
    if (!corpUsersReq.data || corpUsers.length > 0) return
    const users = corpUsersReq.data.map((item: any) => {
      return {
        id: item.id,
        name: `${item.firstName} ${item.lastName}`,
      }
    })
    setCorpUsers(users)
    getOrganizationList().then()
    getPlaces().then()
  }, [
    corpUsers.length,
    corpUsersReq.data,
    getCorpPayments,
    getCorpUsers,
    getOrganizationList,
    getPlaces,
  ])

  return {
    corpPayments,
    lastSeenLte,
    setLastSeenLte,
    lastSeenGte,
    setLastSeenGte,
    corpUsers,
    currentCorpUser,
    setCurrentCorpUser,
    paymentType,
    setPaymentType,
    places,
    isShowOrganizationField,
    currentOrganization,
    setCurrentOrganization,
    corpOrganizationList,
  }
}

export default useCorpReportHook
