import * as React from 'react'
import { AppBar, AppBarProps } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import classes from './MyAppBar.module.sass'
import useAppBarHook from './hooks/useAppBarHook'

const MyAppBar = (props: AppBarProps) => {
  const { balance } = useAppBarHook()
  const price = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0,
  })
  return (
    <AppBar {...props}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.Title}
        id="react-admin-title"
      />
      <span className={classes.Spacer} />
      {balance && <span>Баланс: {price.format(balance)}</span>}
    </AppBar>
  )
}

export default MyAppBar
