import {
  BooleanField,
  ChipField,
  NumberField,
  ReferenceArrayField,
  Show,
  ShowProps,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
} from 'react-admin'
import React from 'react'

export const BotUserShow = (props: ShowProps) => (
  <Show {...props} title="Просмотр пользователя">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" label="Имя" />
      <TextField source="description" label="Примечания" />
      <ReferenceArrayField label="Мойки" reference="places" source="carwash">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="key" label="Ключ" />{' '}
      <BooleanField source="notifications" label="Уведомления" />
      <BooleanField source="encashmentNotify" label="Инкассации" />
      <BooleanField source="equipmentNotify" label="Замена маста" />
      <BooleanField source="carwashApiNotify" label="Доступность api" />
      <BooleanField source="carwashDataNotify" label="Выгрузка данных" />
      <NumberField source="paymentLimit" label="Лимит платежей (день)" />
    </SimpleShowLayout>
  </Show>
)
