import { CarwashOfflinePayment, CarwashOnlinePayment } from '../interfaces'

type Props = {
  offlinePayments: CarwashOfflinePayment | undefined
  onlinePayments: CarwashOnlinePayment[]
}

export const getTotalAmount = ({
  offlinePayments,
  onlinePayments,
}: Props): number => {
  const totalOnlineAmount = onlinePayments.reduce((prev, item) => {
    try {
      const paymentDetails = JSON.parse(item.details)
      return prev + Number(paymentDetails.amount.value)
    } catch (error) {
      return prev
    }
  }, 0)
  return Math.round(
    offlinePayments
      ? offlinePayments.totalAmount + totalOnlineAmount
      : totalOnlineAmount
  )
}
