import React from 'react'
import { TextFieldProps, useRecordContext } from 'react-admin'
import PropTypes from 'prop-types'

const CustomNumberField = (props: TextFieldProps) => {
  const { source } = props
  const record = useRecordContext(props)
  const data = source ? (record[source] ? record[source] : 0) : 0
  return source ? <span>{data / 1000 / 60} мин</span> : null
}

CustomNumberField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

CustomNumberField.defaultProps = {
  addLabel: true,
}

export default CustomNumberField
