import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import { PostTableRow } from './PostTableRow'

const useStyles = makeStyles({
  table: {
    maxWidth: '90%',
    marginBottom: 30,
  },
})

export const AvailablePostItem = ({ postList }: any) => {
  const classes = useStyles()
  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table size="small" aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Название</TableCell>
            <TableCell align="right">ID</TableCell>
            <TableCell align="right">Online</TableCell>
            <TableCell align="right">Средства на счету</TableCell>
            <TableCell align="right">Текущая программа</TableCell>
            <TableCell align="right">Обновлено</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {postList.map((item: any) => (
            <PostTableRow row={item} key={item.id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
