export const getStartOfPrevDay = (): number => {
  const date = new Date().setHours(0, 0, 0, 0)
  const previous = new Date(date)
  previous.setDate(new Date(date).getDate() - 1)
  return previous.getTime()
}

export const getEndOfPrevDay = (): number => {
  const date = new Date().setHours(23, 59, 59)
  const previous = new Date(date)
  previous.setDate(new Date(date).getDate() - 1)
  return previous.getTime()
}

export const getStartOfCurrentMonth = (): number => {
  const date = new Date().setHours(0, 0, 0, 0)
  const startOfMonth = new Date(date)
  startOfMonth.setDate(1)
  return startOfMonth.getTime()
}

export const getEndOfCurrentMonth = () => {
  const date = new Date().setHours(23, 59, 59)
  const endOfMonth = new Date(date)
  endOfMonth.setDate(1)
  endOfMonth.setMonth(endOfMonth.getMonth() + 1)
  endOfMonth.setDate(0)
  return endOfMonth.getTime()
}

export const getStartOfPrevMonth = (): number => {
  const date = new Date().setHours(0, 0, 0, 0)
  const startOfMonth = new Date(date)
  startOfMonth.setDate(1)
  startOfMonth.setMonth(startOfMonth.getMonth() - 1)
  return startOfMonth.getTime()
}

export const getEndOfPrevMonth = (): number => {
  const date = new Date().setHours(23, 59, 59)
  const endOfMonth = new Date(date)
  endOfMonth.setDate(0)
  return endOfMonth.getTime()
}

export const getStartLast30Days = (): number => {
  const date = new Date().setHours(0, 0, 0)
  return date - 30 * 24 * 60 * 60 * 1000
}

export const getEndLast30Days = (): number => {
  return new Date().setHours(23, 59, 59)
}
