import React from 'react'
import {
  ReferenceField,
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
} from 'react-admin'
import { useAbility } from '@casl/react'
import { AbilityContext } from '../../casl/Can'

export const BonusList = (props: any) => {
  const ability = useAbility(AbilityContext)
  return (
    <List
      {...props}
      title="Бонусные баллы"
      sort={{ field: 'timestamp', order: 'DESC' }}
      bulkActionButtons={ability.can('delete', 'bonus') ? undefined : false}
    >
      <Datagrid rowClick="edit">
        <ReferenceField
          source="userId"
          reference="users"
          link="show"
          label="Клиент"
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="amount" label="Количество" />
        <TextField source="description" label="Описание" />
        <ReferenceField
          source="adminId"
          reference="auth"
          link="show"
          label="Выдал бонус"
        >
          <TextField source="username" />
        </ReferenceField>
        <DateField source="timestamp" label="Дата" showTime={true} />
      </Datagrid>
    </List>
  )
}
